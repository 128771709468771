:root {
  // #922583 10%,
  // #ea1c24 50%,
  // #f1692a 100% 
  --talaneo: linear-gradient(
    90deg,   
    rgba(146, 37, 131, 1) 10%,
    rgba(234, 28, 36, 1) 50%,
    rgba(241, 105, 42, 1) 100%
  );
  --talaneo-one-color: rgba(234, 28, 36, 1);
  --talaneo-button: linear-gradient(
    90deg,
    rgba(146, 37, 131, 1) 10%,
    rgba(234, 28, 36, 1) 50%,
    rgba(241, 105, 42, 1) 100%
  );


  --talaneo-bg-light-card: rgba(241, 105, 42, 0.07);
  // --talaneo-bg-light-card-gradient: linear-gradient(
  //       90deg,
  //       rgba(241, 105, 42, 0.2) 0%,
  //       rgba(234, 28, 36, 0.2) 50%,
  //       rgba(146, 37, 131, 0.2) 100%
  //     );

  --talaneo-bg-light-card-gradient: linear-gradient(
    90deg,
    rgb(206, 98, 71, 0.1) 10%,
    rgb(206, 98, 71, 0.1) 50%,
    rgb(206, 98, 71, 0.1) 100%
  );


  --talaneo-bg-light: rgba(241, 105, 42, 0.05);

  //   --talaneo: linear-gradient(
  //     90deg,
  //     rgba(241, 105, 42, 1) 0%,
  //     rgba(234, 28, 36, 1) 50%,
  //     rgba(146, 37, 131, 1) 100%
  //   );
  //   --talaneo-one-color: rgba(234, 28, 36, 1);
  //   --talaneo-hover: #0146a6;

  //   --talaneo-bg-light-card: rgba(234, 28, 36, 0.03);
  //   --talaneo-bg-light-card-gradient: linear-gradient(
  //     90deg,
  //     rgba(241, 105, 42, 0.1) 0%,
  //     rgba(234, 28, 36, 0.1) 50%,
  //     rgba(146, 37, 131, 0.1) 100%
  //   );

  //   --talaneo-button-light: rgba(234, 28, 36, 0.1);

  //   --talaneo-bg-light: rgba(234, 28, 36, 0.05);
}