.tooltipNg {
  position: relative;
  display: inline-block;

  .tooltiptext {
    background-color: white;
    padding: 1rem;
    bottom: 1.5rem;

    position: absolute;
    z-index: 20;
    left: 0;
    visibility: hidden;
    height: fit-content;
    min-width: 200px;
    width: fit-content;
  }

  .tooltiptext.bottom {
    top: 90%;
  }

  .tooltiptext.right {
    left: auto !important;
    right: 0;
  }

  &.active .tooltiptext {
    visibility: visible;
  }
}
