a.color {
  text-decoration: none;
  cursor: pointer;
  background: var(--talaneo);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &:hover {
    text-decoration: underline;
  }

  &.no-color{
    text-decoration: none;
    background: inherit;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }
}

.job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;

  li {
    position: relative;
    font-size: 14px;
    line-height: 22px;
    color: #696969;
    font-weight: 400;
    padding-left: 25px;
    margin-bottom: 5px;
    margin-right: 20px;

    .icon {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 18px;
      line-height: 22px;
      color: #696969;
    }

    a {
      color: #696969;
    }
  }

  &.white {
    li {
      color: #ffffff;
      text-shadow: 0 0 14px black;
      .icon {
        color: #ffffff;
        text-shadow: 0 0 14px black;
      }
    }
  }
  &.black {
    li {
      color: #000000;
      text-shadow: 0 0 20px black;

      .icon {
        color: #000000;
        text-shadow: 0 0 20px black;
      }
    }
  }
}

/* Job Categories Style Two  */

.job-categories {
  position: relative;
  padding: 115px 0 70px;
  border-bottom: 1px solid #ecedf2;

  button {
    width: 100%;
  }

  .job-categories.style-two {
    background: rgba(52, 168, 83, 0.04);

    .category-block {
      .inner-box .icon {
        background: rgba(52, 168, 83, 0.15);
        border-radius: 50%;
        color: rgba(52, 168, 83, 1);
      }

      .inner-box:hover .icon {
        background: rgba(52, 168, 83, 1);
        color: #ffffff;
      }

      h4 a:hover {
        color: rgba(52, 168, 83, 1);
      }
    }
  }
}

.content-icons {
  margin-bottom: 30px;

  .item {
    display: flex;
    text-align: left;

    + .item {
      margin-top: 20px;
    }
  }

  .icon-wrap {
    .icon {
      font-size: 32px;
    }
  }

  .content {
    margin-left: 33px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }

    p {
      font-size: 15px;
      line-height: 26px;
      margin-top: 11px;
    }
  }
}

.content-icons-btn {
  justify-content: flex-start !important;
  margin: -6px;

  > * {
    margin: 0 !important;
    padding: 6px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.icon-item {
  display: flex;
  align-items: center;

  + .icon-item {
    margin-top: 30px;
  }

  .icon-wrap {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
  }

  .content {
    margin-left: 34px;

    h4 {
      font-family: "Jost";
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      line-height: 18px;
      color: #1a3454 !important;
    }

    p {
      margin: 10px;
      font-family: "Jost";
      font-size: 14px;
      line-height: 26px;
    }
  }
}

.icon-item.-type-3 {
  display: block;
  background-color: var(--talaneo-bg-light);
  border: 1px solid transparent;
  border-radius: 120px;
  padding: 10px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  .icon-wrap {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 100%;
    background: var(--talaneo);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 24px;
    transition: all 1s ease;

    i {
      padding-top: 2px;
      padding-left: 2px;
      background: var(--talaneo);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    margin-left: 12px;

    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: #202124;
    }
  }

  &:hover {
    background-color: white;
    border: 1px solid #ecedf2;
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);

    .icon-wrap {
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;

      i{
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      color: white;

      }
    }
  }
}


/*** 

====================================================================
Jobseeker Section
====================================================================

***/

.jobseeker-section {
  position: relative;
}

.jobseeker-section .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jobseeker-section .image-column {
  position: relative;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.jobseeker-section .image-column .image {
  position: relative;
  display: block;
  margin-bottom: 0;
}

.jobseeker-section .image-column .image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

.jobseeker-section .content-column {
  position: relative;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 100px 0;
}

.jobseeker-section .content-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jobseeker-section .content-column .inner-column {
  position: relative;
  max-width: 655px;
  width: 100%;
  padding: 0 15px;
}

.jobseeker-section .content-column .sec-title h2,
.jobseeker-section .content-column .sec-title .text {
  margin-top: 0;
  margin-bottom: 0;
}

.jobseeker-section .content-column .theme-btn {
  margin-top: 18px;
  min-width: 180px;
}

/*** 

====================================================================
About Section
====================================================================

***/

.about-section {
  position: relative;
  padding: 100px 0 0;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}

.about-section .sec-title {
  margin-bottom: 30px;
}

.about-section .sec-title h2 {
  font-size: 40px;
  line-height: 54px;
  color: #202124;
}

.about-section .sec-title .text {
  max-width: 415px;
  font-size: 15px;
  line-height: 26px;
  color: #696969;
}

.about-section .list-style-one {
  margin-bottom: 40px;
}

.about-section .image-column {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.about-section .image-column .image {
  position: relative;
  margin-bottom: 0;
}
.about-section .image-column .image img {
  border-radius: 24px;
}

.play-btn {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.play-btn .icon {
  width: 90px;
  height: 90px;
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 32px;
  padding-left: 5px;
  color: #000000;
  border-radius: 50%;
}

.about-section .count-employers {
  position: absolute;
  right: -150px;
  bottom: -50px;
  max-width: 300px;
  padding: 25px 32px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  border-radius: 10px;
  text-align: center;
}

.about-section .count-employers .check-box {
  position: absolute;
  left: 5px;
  top: 0;
  height: 60px;
  width: 60px;
  background: var(--talaneo);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  color: #ffffff;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.about-section .count-employers .title {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 15px;
}

.about-section .count-employers .image {
  margin-bottom: 0;
}

/*** 

====================================================================
Call To Action
====================================================================

***/

.call-to-action {
  position: relative;
}

.call-to-action .outer-box {
  position: relative;
  background: var(--talaneo-bg-light-card);
  padding: 50px 60px 10px;
  border-radius: 10px;
  overflow: hidden;
}

.call-to-action .sec-title {
  margin-bottom: 0;
}

.call-to-action .image-column {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 360px;
  background-repeat: no-repeat;
  background-position: -10px 20px;
  background-size: cover;
}

.call-to-action .image-column .image {
  display: none;
  margin-bottom: 0;
}

.call-to-action .content-column {
  position: relative;
}

.call-to-action .sec-title {
  position: relative;
  margin-bottom: 50px;
}

.call-to-action .sec-title button {
  margin-top: 30px;
  white-space: break-spaces;
}

.call-to-action.style-two {
  padding-bottom: 100px;
}

/***

==================================================================
Call To Action Four
==================================================================

***/

.call-to-action-four {
  position: relative;
  background: var(--talaneo-bg-light-card);
  padding: 30px 30px;
  /* padding-right: 140px; */
  border-radius: 10px;
  overflow: hidden;
}

.call-to-action-four h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 17px;
}

.call-to-action-four p {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 25px;
}

.call-to-action-four .image {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 140px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.call-to-action-four.style-two {
  padding: 50px 50px;
}

.call-to-action-four.style-two h5 {
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
}

.call-to-action-four.style-two p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
}

.call-to-action-four.style-two .image {
  width: 350px;
}
.call-to-action-four button {
  white-space: break-spaces;
}

/*==========================
default table
===========================*/

.table-outer {
  overflow-y: hidden;
  overflow-x: auto;
}

.default-table {
  position: relative;
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
  min-width: 550px;
}

.default-table thead {
  background: var(--talaneo);
  background-attachment:fixed;
  border-radius: 8px;
  color: #ffffff;
}

.default-table thead th {
  position: relative;
  padding: 20px 30px;
  font-size: 16px;
  color:white;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
}

.default-table tbody tr {
  position: relative;
  border-bottom: 1px solid #ecedf2;
}

.default-table tr td {
  position: relative;
  padding: 21px 30px;
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

.job-other-info li.top {
  background: var(--talaneo);
  color: white;
}


.text-gradient {
  background: var(--talaneo);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;


  i {
    padding-top: 2px;
    padding-left: 2px;

    background: var(--talaneo);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
