// .theme-btn {
//   position: relative;
//   display: -webkit-inline-box;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
//   -webkit-transition: all 0.5s ease;
//   -o-transition: all 0.5s ease;
//   transition: all 0.5s ease;
//   overflow: hidden;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   z-index: 1;
// }

.btn-style-one {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: #ffffff !important;
  background: var(--talaneo-button);
  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 700;
  padding: 18px 35px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-size: 100% 100%;
  background-position: 50% 0;


  &:hover {
    background-size: 300% 100%;

  }
}

.btn-style-one:hover {
  color: #ffffff;
  background-color: #0146a6;
}

.btn-style-light {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: var(--talaneo);
  background: var(--talaneo-bg-light-card);
  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 400;
  padding: 18px 35px;
}

.btn-style-light:hover {
  color: #ffffff;
  background: var(--talaneo);
}

.btn-style-white {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: black;
  background: #ffffff;
  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 400;
  padding: 18px 35px;
  transition: all 250ms ease-in-out;
}

.btn-style-white:hover {
  background: #ffffff;
transform: scale(1.1);
}
.btn-style-outline {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: var(--talaneo);
  
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), var(--talaneo);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;



  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 400;
  padding: 18px 35px;
}
.btn-style-outline * {
  color: var(--talaneo);
}

.btn-style-outline:hover {
  box-shadow: none;
  color: #ffffff;
  background: var(--talaneo);
  background-clip:unset;
}


.theme-btn.large {
  padding: 20px 50px;
  font-size: 16px;
  line-height: 20px;
}

.theme-btn.small {
  padding: 5px 18px;
  font-size: 14px;
  min-width: 80px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 5px;
}
