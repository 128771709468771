/* 
Superio | Just another HTML Template

Template Name: Superio
Author: Creative Layers
Version: 1.0.0

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Content Elements 
5. Main Header / Two / Three / Four / Five
6. Sticky Header
7. Mobile Header
8. Login Popup
9. Page Title
10. Section Title
11. Banner Section / One to Ten
12. Work Section
13. Job Categories
14. Job Section / Two / Three / Four / Five / Six
15. Job Detail Section / Style two
16. Features Section
17. Testimonial Section / Two / Three / Four
18. Clients Section / Two
19. About Section / Two / Three
20. Fun Fact Section
21. Recruiter Section 
22. Jobseeker Section
23. Candidates Section / Two
24. Candidate Detail Section
25. Listing Section 
26. Listing Show More
27. Listing Pagination
28. Listing Section Two / Three / Four
29. Listing Maps
30. Top Companies
31. Ads Section
32. Registeration Banners
33. News Section / Two / Three
34. Blog Grid
35. Blog Single
36. Products Section
37. Shop Single
38. Product Tabs
39. Cart Section
40. CheckOut Page
41. Order Slip
42. App Section
43. Pricing Section
44. Process Section
45. steps Section
46. Call To Action / Two / Three / Four
47. Browse Jobs
48. Subscribe Section / Two
49. Faqs Section
50. TNC Section
51. Map Section
52. Contact Section
53. Login Section
54. Invoice Section
55. Main Footer / Two / Three / Four / Five / Six
56. Dashboard
57. User Sidebar
58. Chat Widget
**********************************************/

/*** 

====================================================================
Reset
====================================================================

***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/

body {
  font-size: 16px;
  color: #363636;
  line-height: 28px;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: "Jost", sans-serif;
  font-style: normal;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
}

section {
  position: relative;
}

textarea {
  overflow: hidden;
  resize: none;
}

button {
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
  min-width: auto;
}

p,
.text {
  font-size: 15px;
  color: #696969;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

::-webkit-input-placeholder {
  color: inherit;
}
::-moz-input-placeholder {
  color: inherit;
}
::-ms-input-placeholder {
  color: inherit;
}

.auto-container {
  position: static;
  max-width: 1310px;
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.small-container {
  position: static;
  max-width: 900px;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 1;
  background-color: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.section {
  padding: 60px 0 30px;
}

.bg_alice {
  background-color: #f9fafc;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/******************
** \reset select 2
******************/

.select2-container--open,
.select2-dropdown--below,
.select2-drop {
  z-index: 99;
}

.select2-container .select2-choice {
  position: relative;
  z-index: 2;
}

.select2-container {
  max-width: 100%;
  z-index: 9;
}

.select2-container li:before {
  display: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 6px 10px;
  border: 0;
}

.select2-dropdown,
.select2-search--dropdown {
  border-color: #e0e6ea;
}

.select2-container--default .select2-results__option {
  position: relative;
  color: #4b4342;
  font-weight: 500;
  font-size: 17px;
  padding: 13px 10px;
  line-height: 20px;
  border-top: 1px solid #f1f1f1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.select2-search--dropdown,
.select2-results .select2-result-label {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: transparent;
  color: var(--talaneo);
}

.select2-container--default .select2-selection--single {
  position: relative;
  width: 100%;
  display: block;
  color: #5c6770;
  font-size: 14px;
  line-height: 30px;
  padding: 10px 18px;
  height: 54px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #e6e8ed;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  width: 36px;
  height: 100%;
  background: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: block;
  left: 0;
  top: -1px;
  margin: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent !important;
  background: none;
  background-color: inherit;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  content: "\f107";
  opacity: 0.25;
  font-family: "Font Awesome 6 Pro";
  line-height: 20px;
  font-size: 17px;
  color: inherit;
  font-weight: 900;
}

.chosen-container-single .chosen-single div:after {
  top: 50%;
  margin-top: -10px;
  line-height: 20px;
  right: 20px;
  color: #2f2d51;
  content: "\f17c";
  font-family: "Flaticon";
  font-size: 10px;
}

.chosen-container-single .chosen-single span {
  min-width: 80px;
}

.chosen-container .chosen-drop,
.chosen-container .chosen-results {
  border: 0 !important;
  outline: none !important;
  border-radius: 0px 0px 8px 8px !important;
  margin-top: -3px;
}

/***

=======================
List Style one
=======================

***/

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
  line-height: 26px;
  color: #202124;
  font-weight: 400;
  cursor: default;
  margin-bottom: 20px;
}

.list-style-one i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 17px;
  line-height: 26px;
  content: "\f172";
}

/***

=======================
List Style Two
=======================

***/

.list-style-two {
  position: relative;
}

.list-style-two li {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  cursor: default;
  padding-left: 22px;
  margin-bottom: 20px;
}

.list-style-two li:before {
  position: absolute;
  left: 0;
  top: 9px;
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border-radius: 50%;
  content: "";
}

/*** 
==========================
Social Icons
==========================
***/

.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.social-icon-one li {
  position: relative;
  margin: 0 6px 10px;
}

.social-icon-one li a {
  position: relative;
  display: block;
  font-size: 14px;
  color: #7b8094;
  height: 45px;
  width: 45px;
  border: 2px solid #323950;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-one li a:hover {
  background-color: #323950;
}

/* Social Icon Two*/

.social-icon-two {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.social-icon-two li {
  position: relative;
  margin: 0 6px 10px;
}

.social-icon-two li a {
  position: relative;
  display: block;
  font-size: 14px;
  color: #9fa9b8;
  height: 45px;
  width: 45px;
  border: 2px solid #f7f8f9;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-two li a:hover {
  background-color: #f7f8f9;
  color: #32383d;
}

/* Social Icon Three*/

.social-icon-three {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-icon-three li {
  position: relative;
  width: 50%;
  margin-bottom: 35px;
}

.social-icon-three li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 65px;
  min-height: 45px;
  color: #32383d;
}

.social-icon-three li a span {
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 0;
  font-size: 14px;
  border: 2px solid #f7f8f9;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #32383d;
  height: 45px;
  width: 45px;
  line-height: 43px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-three li a:hover span {
  background-color: #f7f8f9;
}

/* Social Icon Four*/

.social-icon-four {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-icon-four li {
  position: relative;
  margin-right: 25px;
}

.social-icon-four li a {
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-four li a:hover {
  color: #ffa737;
}

/*** 

====================================================================
Content Elements
====================================================================

***/

.content-elements {
  position: relative;
  padding: 60px 0 10px;
}

.content-elements .auto-container {
  max-width: 1200px;
}

.content-elements .column {
  position: relative;
  margin-bottom: 50px;
}

.content-elements h4.title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 30px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-70 {
  margin-top: 70px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-10 {
  margin-top: 10px;
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

.content-elements .buttons {
  margin-bottom: 20px;
}

.content-elements .buttons .column {
  margin-bottom: 30px;
}

.content-elements .buttons .theme-btn {
  margin-right: 20px;
  margin-bottom: 20px;
}

/*==========================
Owl Carousel Dots
===========================*/

.default-dots .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.default-dots .owl-dot {
  position: relative;
  height: 8px;
  width: 8px;
  background: #bfc8cb;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin: 0 5px;
}

.default-dots .owl-dot.active {
  background: #202124;
  width: 20px;
}

.default-dots.light .owl-dot {
  background: #bfc8cb;
}

.default-dots.light .owl-dot.active {
  background: #ffffff;
}

/*==========================
Owl Carousel Default Nav
===========================*/

.owl-carousel.no-arrows .owl-nav {
  display: none;
}

.owl-carousel.no-dots .owl-dots {
  display: none;
}

.default-nav .owl-nav {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  margin-top: -25px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.default-nav .owl-next,
.default-nav .owl-prev {
  position: absolute;
  left: -80px;
  top: 0;
  height: 50px;
  width: 50px;
  color: #021f4a;
  background: #ffffff;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.default-nav .owl-next {
  left: auto;
  right: -80px;
}

.default-nav .owl-next:hover,
.default-nav .owl-prev:hover {
  color: #ffffff;
  background-color: #021f4a;
}

/*== Owl Dots ===*/

.default-nav .owl-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.default-nav .owl-dot {
  position: relative;
  height: 14px;
  width: 14px;
  border: 2px solid #b9c1d0;
  border-radius: 50%;
  margin: 0 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-nav .owl-dot.active,
.default-nav .owl-dot:hover {
  background: #b9c1d0;
}

/*==========================
Message Box
===========================*/

.message-box {
  position: relative;
  padding: 25px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 75px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.message-box p {
  color: inherit;
  font-size: 16px;
  line-height: 25px;
}

.message-box a {
  color: inherit;
  text-decoration: underline;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.message-box .close-btn {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 80px;
  font-size: 24px;
  text-align: center;
  display: -webkit-box;
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.close_icon:before {
  position: relative;
  left: 10px;
  content: "\f175";
  height: 17px;
  width: 1px;
  font-size: 11px;
  font-weight: 700;
  font-family: "Flaticon";
}

/* Message Info */
.message-box.info {
  background-color: #cde9f6;
  color: #4780aa;
}

/* Message warning */
.message-box.warning {
  background-color: #f7f3d7;
  color: #927238;
}

/* Message success */
.message-box.success {
  background-color: #def2d7;
  color: #5b7052;
}

/* Message Error */
.message-box.error {
  background-color: #ecc8c5;
  color: #ab3331;
}

/*==========================
Default Tabs
===========================*/

.default-tabs {
  position: relative;
  overflow: hidden;
}

.default-tabs .tab-buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.default-tabs .tab-buttons li {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  line-height: 20px;
  padding: 5px 20px;
  background: var(--talaneo-bg-light);
  border: 1px solid var(--talaneo-bg-light);
  border-radius: 8px;
  cursor: pointer;
  margin: 0 7px 14px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-tabs .tab-buttons li.active-btn {
  background-color: transparent;
  border: 1px solid #ecedf2;
  color: #202124;
}

.default-tabs .tabs-content {
  position: relative;
  width: 100%;
  padding: 25px 0;
}

.default-tabs .tabs-content p {
  margin-bottom: 24px;
}

.default-tabs .tabs-content p:last-child {
  margin-bottom: 0;
}

/* Style Two */

.default-tabs.style-two .tab-buttons {
  background: #ffffff;
  border-bottom: 1px solid #e7e7ec;
}

.default-tabs.style-two .tab-buttons li {
  padding: 9px 10px;
  border-radius: 0;
  border: 0;
  font-size: 16px;
  color: #696969;
  border-bottom: 3px solid transparent;
  background: none;
  margin: 0;
  margin-right: 40px;
}

.default-tabs.style-two .tab-buttons li.active-btn {
  color: var(--talaneo);
  border-bottom: 3px solid #5c6770;
}

/*==========================
Accordian Box
===========================*/

.accordion-box {
  position: relative;
  margin-bottom: 30px;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ecedf2;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 16px;
  padding: 17px 30px;
  line-height: 30px;
  color: #202124;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block.active-block {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.accordion-box .block .acc-btn .icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  color: #2f2d51;
  font-size: 15px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .acc-btn.active .icon:before {
  content: "\f068";
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  padding: 24px 30px 26px;
  border-top: 1px solid #ecedf2;
}

.accordion-box .block .content p {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 24px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .content p:last-child {
  margin-bottom: 0;
}

/*======================
Checkbox 
=======================*/

.checkbox-outer {
  position: relative;
  margin-bottom: 30px;
}

.checkboxes {
  list-style: none;
  padding: 0;
}

.checkboxes.inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.checkboxes li {
  margin-right: 20px;
  margin-bottom: 10px;
}

.checkboxes label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.checkboxes label span {
  color: #909090;
}

.checkboxes input[type="checkbox"] {
  display: none;
}

.checkboxes label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #fff;
  border: 1px solid #ecedf2;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  font-family: "Font Awesome 6 Pro";
  color: #fff;
  font-size: 9px;
  font-weight: 900;
  text-align: center;
  line-height: 17px;
}

.checkboxes input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  background: var(--talaneo);
  border: 1px solid var(--talaneo);
}

.checkboxes.square label:before {
  border-radius: 2px;
}

.checkboxes.two-column:after {
  display: table;
  clear: both;
  content: "";
}

.checkboxes.two-column li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  float: left;
  margin: 0;
  min-width: 50%;
  padding-right: 10px;
}

.checkbox-outer .view-more {
  position: relative;
}

.checkbox-outer .view-more {
  font-size: 13px;
  line-height: 20px;
  color: var(--talaneo);
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-outer .view-more .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  font-weight: 900;
  font-size: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgba(25, 103, 210, 0.15);
  border-radius: 60px;
}

/*======================
Radio bOX
=======================*/

.radio-box {
  position: relative;
}

.radio-box label {
  position: relative;
  display: block;
  font-size: 15px;
  color: #696969;
  line-height: 20px;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: 18px;
}

.radio-box label:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  width: 16px;
  background: #ffffff;
  content: "";
  border: 1px solid #ecedf2;
  border-radius: 50px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.radio-box label:after {
  position: absolute;
  content: "";
  left: 4px;
  top: 4px;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  overflow: hidden;
  background: transparent;
  text-align: center;
}

.radio-box input[type="radio"] {
  display: none;
}

.radio-box input[type="radio"]:checked + label {
  border-color: var(--talaneo);
}

.radio-box input[type="radio"] {
}

.radio-box input[type="radio"]:checked + label:before {
  border-color: var(--talaneo);
}

.radio-box input[type="radio"]:checked + label:after {
  background: var(--talaneo);
  opacity: 1;
}

/*======================
Switches 
=======================*/

.switchbox-outer {
  position: relative;
}

.switchbox-outer h4 {
  font-size: 16px;
  line-height: 1em;
  color: #1b2032;
  font-weight: 700;
  margin-bottom: 15px;
}

.switchbox {
  position: relative;
}

.switchbox li {
  position: relative;
  margin-bottom: 20px;
}

.switchbox .title {
  position: relative;
  margin-left: 10px;
  font-size: 15px;
  color: #5c6770;
  line-height: 20px;
}

.switch {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: #d2d3d8;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: var(--talaneo);
  border: 2px solid var(--talaneo);
}

input:checked + .slider:before {
  background: #ffffff;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*======================
Radio bOX
=======================*/
.tooltip-outer {
  position: relative;
}

.tooltip-btn {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
  line-height: 30px;
  padding: 10px 20px;
  min-width: 100px;
  text-align: center;
  background: var(--talaneo-bg-light);
  border-radius: 8px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

/*==========================
Progress Bars
============================*/

.bar-item {
  position: relative;
  margin-bottom: 48px;
}

.bar-item .skill-bar {
  position: relative;
  width: 100%;
  height: 10px;
}

.bar-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 10px;
  background: #d4e1f6;
  border-radius: 5px;
}

.bar-item .skill-bar .bar-inner .bar {
  position: relative;
  left: 0px;
  top: 0px;
  height: 10px;
  width: 0px;
  border-radius: 10px;
  border-bottom: 10px solid var(--talaneo);
  -webkit-transition: all 2000ms ease;
  -o-transition: all 2000ms ease;
  transition: all 2000ms ease;
}

.bar-item .skill-header {
  position: relative;
  margin-bottom: 12px;
}

.bar-item .skill-header .skill-title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #5c6770;
}

.bar-item .skill-percentage {
  position: absolute;
  right: 0;
  bottom: 100%;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

/*==========================
typography
============================*/

.typography {
  position: relative;
}

.typography h5 {
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 11px;
}

.typography p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
}

.list-style-four {
  position: relative;
}

.list-style-four li {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: #696969;
  margin-bottom: 20px;
  padding-left: 30px;
}

.list-style-four li:before {
  position: absolute;
  left: 0;
  top: 3px;
  height: 18px;
  width: 18px;
  background: #696969;
  content: "\f00c";
  border-radius: 10px;
  color: #ffffff;
  font-size: 10px;
  line-height: 18px;
  font-weight: 900;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
}

/*==========================
Pie Graph
===========================*/

.pie-graph {
  position: relative;
  margin-bottom: 40px;
}

.pie-graph .graph-outer {
  position: relative;
  text-align: center;
}

.pie-graph .graph-outer .dial {
  position: relative;
  display: block;
}

.pie-graph .inner-text {
  position: absolute;
  left: 15px;
  right: 15px;
  text-align: center;
  top: 50%;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1em;
  color: #363636;
}

.pie-graph h4 {
  position: relative;
  font-size: 20px;
  color: #363636;
  margin-top: 10px;
  text-align: center;
}

.pie-graph.light h4,
.pie-graph.light .inner-text {
  color: #ffffff;
}

/*==========================
Icons
===========================*/
.icon-calendar {
  background-image: url(../assets/images/icons/icon-calendar.svg);
}
.icon-expiry {
  background-image: url(../assets/images/icons/icon-expiry.svg);
}
.icon-location {
  background-image: url(../assets/images/icons/icon-location.svg);
}
.icon-user-2 {
  background-image: url(../assets/images/icons/icon-user-2.svg);
}
.icon-clock {
  background-image: url(../assets/images/icons/icon-clock.svg);
}
.icon-rate {
  background-image: url(../assets/images/icons/icon-rate.svg);
}
.icon-salary {
  background-image: url(../assets/images/icons/icon-salary.svg);
}
.icon-language {
  background-image: url(../assets/images/icons/icon-language.svg);
}
.icon-degree {
  background-image: url(../assets/images/icons/icon-degree.svg);
}

/*==========================
Banner Box One
===========================*/

.banner-box-one {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-one .cws-image-bg {
  -webkit-clip-path: polygon(0% 20px, 100% 0%, 100% calc(100% - 20px), 0% 100%);
  clip-path: polygon(0% 20px, 100% 0%, 100% calc(100% - 20px), 0% 100%);
}

.banner-box-one .cws-image-bg .cws-overlay-bg {
  width: 50%;
  background-color: #529b6b;
}

.banner-box-one .cws-triangle-overlay {
  opacity: 0.3;
  -webkit-clip-path: polygon(
    0% 0,
    50% 10px,
    100% 0,
    100% 100%,
    50% calc(100% - 10px),
    0 100%
  );
  clip-path: polygon(
    0% 0,
    50% 10px,
    100% 0,
    100% 100%,
    50% calc(100% - 10px),
    0 100%
  );
  background-color: #529b6b;
}

.banner-box-one .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-one .content {
  width: 50%;
  text-align: center;
}

.banner-box-one .banner-offer {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 55px;
  line-height: 1;
  margin-bottom: 5px;
}

.banner-box-one .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-one.orange .cws-triangle-overlay {
  background: #ed6436;
}

.banner-box-one.orange .cws-overlay-bg {
  background-color: #ed6436;
}

.banner-box-one.orange .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 100, 54, 0.65);
  content: "";
  z-index: 1;
}

.banner-box-one.orange .theme-btn {
  border-color: #ffffff;
}

/*==========================
Banner Box Two
===========================*/

.banner-box-two {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-two .cws-image-bg {
  -webkit-clip-path: none;
  clip-path: none;
}

.banner-box-two .cws-image-bg .cws-overlay-bg {
  background-color: rgba(75, 180, 105, 0.9);
}

.banner-box-two .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-two .content {
  width: 100%;
  text-align: center;
}

.banner-box-two .banner-title {
  margin-bottom: 0;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.banner-box-two .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-two.dark .cws-overlay-bg {
  background-color: rgba(0, 0, 0, 0.85);
}

.banner-box-two.dark .theme-btn {
  border-color: #ffffff;
}

/*==========================
Banner Box Three
===========================*/

.banner-box-three {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-three .cws-image-bg {
  -webkit-clip-path: polygon(0% 20px, 100% 0%, 100% 100%, 0% calc(100% - 20px));
  clip-path: polygon(0% 20px, 100% 0%, 100% 100%, 0% calc(100% - 20px));
}

.banner-box-three .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(81, 158, 107, 0.6);
  content: "";
}

.banner-box-three .cws-image-bg .cws-overlay-bg {
  width: 50%;
  background-color: #ffa737;
}

.banner-box-three .cws-triangle-overlay {
  opacity: 0.3;
  -webkit-clip-path: polygon(
    0% 0,
    50% 10px,
    100% 0,
    100% 100%,
    50% calc(100% - 10px),
    0 100%
  );
  clip-path: polygon(
    0% 0,
    50% 10px,
    100% 0,
    100% 100%,
    50% calc(100% - 10px),
    0 100%
  );
  background-color: #529b6b;
}

.banner-box-three .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-three .content {
  width: 50%;
}

.banner-box-three .banner-title {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #fff;
}

.banner-box-three .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-three .banner-offer {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 55px;
  line-height: 1;
  margin-bottom: 5px;
}

.banner-box-three.orange .cws-triangle-overlay {
  background: #ed6436;
}

.banner-box-three.orange .cws-overlay-bg {
  background-color: #ff6c3a;
  z-index: 1;
}

.banner-box-three.orange .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 100, 54, 0.65);
  content: "";
  z-index: 1;
}

.banner-box-three.orange .theme-btn {
  border-color: #ffffff;
}

/*==========================
Callout One
===========================*/

.callout-one {
  position: relative;
  z-index: 1;
  padding: 45px 30px;
  overflow: hidden;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  text-align: center;
  margin-bottom: 30px;
}

.callout-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(89, 171, 102, 0.9);
  content: "";
}

.callout-one .sub-title {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.4em;
  color: #ffffff;
  margin-bottom: 20px;
}

.callout-one h2 {
  position: relative;
  text-align: center;
  font-size: 48px;
  line-height: 1.2em;
  color: #ffffff;
  margin-bottom: 30px;
}

.callout-one .theme-btn {
  border-color: #ffffff;
  color: #ffffff;
}

/*==========================
Callout Two
===========================*/

.callout-two {
  position: relative;
  z-index: 1;
  padding: 45px 30px;
  overflow: hidden;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  margin-bottom: 30px;
}

.callout-two:before {
  position: absolute;
  left: -10%;
  top: 0;
  height: 100%;
  width: 60%;
  background-color: rgba(89, 171, 102, 1);
  content: "";
  -webkit-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  transform: skew(-15deg);
}

.callout-two .content {
  position: relative;
  width: 50%;
}

.callout-two h2 {
  font-size: 48px;
  line-height: 1.2em;
  font-weight: 600;
  color: #fff;
}

.callout-two h3 {
  font-size: 40px;
  line-height: 1.2em;
  font-weight: 600;
  color: #ffffff;
}

.callout-two .abs-btn {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: -70px;
  margin-top: -70px;
  height: 140px;
  width: 140px;
  background-color: #ffffff;
  line-height: 140px;
  text-align: center;
  color: #000000;
  border-radius: 50%;
  min-width: auto;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

/*==========================
Callout Three
===========================*/

.callout-three {
  position: relative;
  z-index: 1;
  padding: 45px 30px;
  overflow: hidden;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  margin-bottom: 30px;
}

.callout-three:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(89, 171, 102, 0.6);
  content: "";
}

.callout-three .content {
  position: relative;
  text-align: left;
}

.callout-three h2 {
  position: relative;
  font-size: 42px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 600;
}

.callout-three h3 {
  position: relative;
  font-size: 32px;
  line-height: 1.4em;
  margin-top: 0px;
  color: #ffffff;
  margin-bottom: 30px;
}

.callout-three .right-box {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: auto;
  padding: 45px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.callout-three .right-box:before {
  position: absolute;
  top: 0;
  height: 100%;
  width: 120%;
  right: -15%;
  left: initial;
  -webkit-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  transform: skew(-15deg);
  background-color: #f36737;
  content: "";
}

.callout-three .banner-offer {
  position: relative;
  font-size: 60px;
  line-height: 1em;
  color: #ffffff;
  margin-bottom: 10px;
}

.callout-three .banner-desc {
  position: relative;
  font-size: 23px;
  color: #ffffff;
  line-height: 1;
  margin-bottom: 10px;
}

.callout-three .theme-btn {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}

/*==========================
Preloader
===========================*/

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
}

.preloader:after {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50px;
  width: 50px;
  margin-left: -25px;
  margin-top: -25px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../assets/images/favicon.png);
  content: "";
}

.preloader:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 150px;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
  color: var(--talaneo);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  content: "Laden...";
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

hr {
  -webkit-appearance: none;
  position: relative;
  height: 1px;
  margin: 30px auto;
  background: #e6e8ed;
  border-style: none;
  outline-style: none;
}

section hr {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
}

.tabs-box {
  position: relative;
}

.tabs-box .tab {
  display: none;
}

.tabs-box .active-tab {
  display: block;
}

.tabs-box .tab-btn {
  cursor: pointer;
}

/*=== Default Form ===*/

.default-form {
  position: relative;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.default-form .form-group:last-child {
  margin-bottom: 0;
}

.default-form .form-group > label {
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}

.default-form .form-group input[type="text"],
.default-form .form-group input[type="date"],
.default-form .form-group ng-select,
.default-form .form-group input[type="password"],
.default-form .form-group input[type="number"],
.default-form .form-group input[type="email"],
.default-form .form-group input[type="url"],
.default-form .form-group textarea,
select {
  position: relative;
  width: 100%;
  display: block;
  height: 60px;
  line-height: 30px;
  padding: 15px 20px;
  font-size: 15px;
  color: #696969;
  -webkit-appearance:none;
  background: var(--talaneo-bg-light);
  border: 1px solid var(--talaneo-bg-light);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-form .form-group input[type="text"]:focus,
.default-form .form-group input[type="email"]:focus,
.default-form .form-group input[type="url"]:focus,
.default-form .form-group select:focus,
.default-form .form-group textarea:focus {
  border-color: var(--talaneo);
  background: #ffffff;
}

.default-form .form-group textarea {
  height: 160px;
  resize: none;
  padding-top: 15px;
}

.default-form .form-group input[type="submit"],
.default-form .form-group > button {
  position: relative;
  display: inline-block;
  min-width: 160px;
}

.default-form input.error:focus,
.default-form select.error:focus,
.default-form textarea.error:focus {
  border-color: #ff0000;
}

.default-form label.error {
  display: block;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 24px;
  color: #ff0000;
  margin-bottom: 0;
}

.default-form .form-group > .icon {
  position: absolute;
  right: 20px;
  bottom: 0;
  color: #9fa9b8;
  font-size: 14px;
  line-height: 54px;
}

.default-form .form-group .rating {
  position: relative;
}

.default-form .form-group .rating a {
  position: relative;
  font-size: 13px;
  color: #999999;
}
.default-form .form-group .rating a:hover {
  color: #ffc78b;
}

#email-form .form-group:first-child {
  margin-bottom: 0;
}

#email-form .response {
  position: relative;
  text-align: center;
}

#email-form .response .success {
  background: #35c2a5;
  color: #ffffff;
  padding: 0 10px;
  margin-bottom: 10px;
}

#email-form .response .failed {
  background-color: #f74848;
  color: #ffffff;
  padding: 0 10px;
  margin-bottom: 10px;
}

#email-form .response .text-info {
  background-color: #ffa737;
  color: #222222 !important;
  padding: 0 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"],
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  line-height: 60px !important;
  min-height: 60px !important;
}

.chosen-container-multi .chosen-choices,
.chosen-container.chosen-with-drop .chosen-drop {
  background: var(--talaneo-bg-light);
  min-height: 60px;
  line-height: 60px;
  border: 0 !important;
  border-radius: 8px;
}

.chosen-container-single .chosen-single {
  background: var(--talaneo-bg-light);
  border: 0 !important;
  border-radius: 8px;
}

.chosen-container-single .chosen-search:before {
  display: none;
}

.chosen-container-multi .chosen-choices li.search-choice {
  color: rgba(25, 103, 210, 1);
  font-size: 13px;
  line-height: 25px;
  color: var(--talaneo);
  padding: 0 25px 0 10px;
  border-radius: 10px;
  background: rgba(25, 103, 210, 0.1);
}

.chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close:before {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  top: 3px;
  color: rgba(25, 103, 210, 1);
}

.chosen-container-multi .chosen-choices li.search-choice {
  margin-top: 15px;
}

.chosen-container-single .chosen-search input[type="text"] {
  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
}

/*** 

====================================================================
Scroll To Top
====================================================================

***/

.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 28px;
  z-index: 999;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  display: none;
  cursor: pointer;
  background-color: #23293e;
  color: var(--talaneo);
  background: var(--talaneo-bg-light-card);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: #ffffff;
  background: var(--talaneo);
}

.scroll-to-top.style-two {
  color: var(--talaneo);
  background-color: rgba(52, 168, 83, 0.07);
}
.scroll-to-top.style-two:hover {
  color: #ffffff;
  background-color: rgba(52, 168, 83, 1);
}

/*** 

====================================================================
Main Header
====================================================================

***/

.main-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 99;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.header-span {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
  z-index: 1;
}

.main-header.fixed-header {
  position: fixed;
  background: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-header.fixed-header .logo {
  padding: 15px 0;
}

.main-header .main-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 60px;
  max-width: 1920px;
  margin: 0 auto;
}

.main-header .nav-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header .logo-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header .logo {
  position: relative;
  z-index: 9;
  padding: 25px 0;
  margin-right: 100px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .menu-box {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu {
  position: static;
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu .navigation > li {
  position: relative;
  margin-right: 40px;
  padding: 20px 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li:hover {
  transform:scale(1.1)
}

.main-menu .navigation > li > span,
.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  color: #202124;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current > span {
  color: var(--talaneo);
}

.main-menu .navigation > li.dropdown > span {
  padding-right: 18px;
}

.main-menu .navigation > li.dropdown > span:after {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -10px;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  display: block;
  line-height: 20px;
  font-size: 14px;
  font-weight: 900;
  z-index: 5;
  color: #202124;
}

.main-menu .navigation > li > a > span {
  position: relative;
}

.main-menu .navigation > li > ul {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px 0;
  opacity: 0;
  z-index: 1;
  min-width: 230px;
  border-top: 2px solid var(--talaneo);
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -ms-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.main-menu .navigation > li > ul:before {
  position: absolute;
  left: 20px;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 5px solid var(--talaneo);
  content: "";
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0;
}

.main-menu .navigation > li > ul .from-right {
  left: auto;
  right: 100%;
  margin-right: 2px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > span,
.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 30px;
  z-index: 1;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu .navigation > li > ul > li > span:hover,
.main-menu .navigation > li > ul > li > a:hover {
  color: var(--talaneo);
}

.main-menu .navigation > li.current,
.main-menu .navigation > li > ul > li > ul > li.current > a,
.main-menu .navigation > li > ul > li.current > a {
  color: var(--talaneo);
  
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  top: 100%;
  left: 100%;
  padding: 10px 0;
  min-width: 220px;
  margin-top: -57px;
  opacity: 0;
  z-index: 1;
  border-top: 2px solid var(--talaneo);
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -ms-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 30px;
  z-index: 1;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #363636;
  white-space: nowrap;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover,
.main-menu .navigation > li > ul > li > ul > li > span:hover {
  color: var(--talaneo);
}

.main-menu .navigation > li > ul > li.dropdown > span:before {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -10px;
  font-family: "Font Awesome 6 Pro";
  content: "\f105";
  display: block;
  line-height: 20px;
  font-size: 15px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation > li.dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0px);
  -ms-transform: translate(0px);
  transform: translate(0px);
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header .upload-cv {
  position: relative;
  font-size: 15px;
  color: var(--talaneo);
  font-weight: 400;
  white-space: nowrap;
}

.main-header .outer-box .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-header .outer-box .btn-box a {
  margin-left: 20px;
  min-width: 140px;
}

.main-header .menu-btn {
  position: relative;
  margin-left: 30px;
}

.main-header .menu-btn .icon {
  font-size: 24px;
  color: #696969;
}

.main-header .menu-btn .count {
  position: absolute;
  right: -8px;
  top: -4px;
  height: 16px;
  width: 16px;
  background: var(--talaneo);
  border-radius: 50%;
  color: #ffffff;
  line-height: 16px;
  font-size: 12px;
}

.main-header.alternate {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-header.alternate .main-box {
  padding: 0;
}

.main-header.alternate .main-box .logo {
  margin-right: 80px;
}

.main-header.alternate2 .outer-box .btn-box a {
  border-radius: 5px !important;
}

.main-header.header-style-two.alternate .main-box .logo {
  margin-right: 30px;
}

.main-header.header-style-two.fixed-header {
  background-color: #22218c;
}

.main-header.header-shaddow {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-bottom: 1px solid #ecedf2;
}

.main-header.alternate3 {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  background-color: #ffffff;
}

/* Dashboard Option */

#toggle-user-sidebar {
  display: none;
}

.dashboard-option {
  position: relative;
  margin-left: 30px;
}

.dashboard-option .dropdown-menu {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  top: 100% !important;
  left: auto !important;
  right: 0 !important;
  min-width: 300px;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  padding: 30px 30px 17px;
}

.dashboard-option .dropdown-menu:before {
  position: absolute;
  right: 30px;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ecedf2;
  content: "";
}

.dashboard-option .dropdown-toggle {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-option .dropdown-toggle::after {
  margin-left: 12px;
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  border: 0;
}

.dashboard-option .thumb {
  height: 50px;
  width: 50px;
  overflow: hidden;
}

.dashboard-option .name {
  position: relative;
  display: block;
  color: #202124;
  font-size: 15px;
  line-height: 28px;
  margin-left: 20px;
}

.dashboard-option .dropdown-menu li button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #696969;
  text-align: left;
  text-transform: capitalize;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.dashboard-option .dropdown-menu li button i {
  position: relative;
  margin-right: 15px;
  font-size: 22px;
  color: #696969;
  text-align: center;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}


/*** 

====================================================================
Header Style Two
====================================================================

***/

.header-style-two .main-box {
  padding: 0;
}

.header-style-two .nav-outer {
  width: 100%;
}

.header-style-two .main-menu {
  margin-left: auto;
  margin-right: 15px;
}

.header-style-two .logo {
  margin-right: 0;
}

.header-style-two .main-menu .navigation > li {
  margin-left: 45px;
  margin-right: 0;
}

.header-style-two .main-menu .navigation > li.dropdown > span:after,
.header-style-two .main-menu .navigation > li > span,
.header-style-two .main-menu .navigation > li > a {
  color: #ffffff;
}

.header-style-two .upload-cv,
.header-style-two .main-menu .navigation > li > ul > li > a:hover,
.header-style-two .main-menu .navigation > li > ul > li > span:hover,
.header-style-two .main-menu .navigation > li > ul > li > a:hover,
.header-style-two .main-menu .navigation > li > ul > li > ul > li > a:hover,
.header-style-two .main-menu .navigation > li > ul > li > ul > li > span:hover,
.header-style-two
  .main-menu
  .navigation
  > li
  .mega-menu-bar
  .column
  > ul
  > li
  > a:hover,
.header-style-two .main-menu .navigation > li.current.dropdown > span:after,
.header-style-two .main-menu .navigation > li > ul > li > ul > li.current > a,
.header-style-two .main-menu .navigation > li > ul > li.current > a,
.header-style-two .main-menu .navigation > li.current.dropdown > span:after,
.header-style-two .main-menu .navigation > li.current > a,
.header-style-two .main-menu .navigation > li.current > span {
  color: #f9ab00;
}

.header-style-two .main-menu .navigation > li > ul > li > ul,
.header-style-two .main-menu .navigation > li > .mega-menu,
.header-style-two .main-menu .navigation > li > ul {
  border-top-color: #f9ab00;
}

.header-style-two .main-menu .navigation > li > .mega-menu:before,
.header-style-two .main-menu .navigation > li > ul:before {
  border-bottom-color: #f9ab00;
}

.header-style-two .theme-btn {
  border-radius: 50px;
}

/*** 

====================================================================
Header Style Three
====================================================================

***/

.header-style-three .main-box {
  padding: 0 45px;
}

.header-style-three .upload-cv,
.header-style-three .main-menu .navigation > li > ul > li > a:hover,
.header-style-three .main-menu .navigation > li > ul > li > span:hover,
.header-style-three .main-menu .navigation > li > ul > li > a:hover,
.header-style-three .main-menu .navigation > li > ul > li > ul > li > a:hover,
.header-style-three
  .main-menu
  .navigation
  > li
  > ul
  > li
  > ul
  > li
  > span:hover,
.header-style-three
  .main-menu
  .navigation
  > li
  .mega-menu-bar
  .column
  > ul
  > li
  > a:hover,
.header-style-three .main-menu .navigation > li.current.dropdown > span:after,
.header-style-three .main-menu .navigation > li > ul > li > ul > li.current > a,
.header-style-three .main-menu .navigation > li > ul > li.current > a,
.header-style-three .main-menu .navigation > li.current.dropdown > span:after,
.header-style-three .main-menu .navigation > li.current > a,
.header-style-three .main-menu .navigation > li.current > span {
  color: var(--talaneo);
}

.header-style-three .main-menu .navigation > li > ul > li > ul,
.header-style-three .main-menu .navigation > li > .mega-menu,
.header-style-three .main-menu .navigation > li > ul {
  border-top-color: var(--talaneo);
}

.header-style-three .main-menu .navigation > li > .mega-menu:before,
.header-style-three .main-menu .navigation > li > ul:before {
  border-bottom-color: var(--talaneo);
}

/*** 

====================================================================
Header Style Four
====================================================================

***/

.header-style-four .nav-outer {
  width: 100%;
}

.header-style-four .main-menu {
  margin-left: auto;
  margin-right: 15px;
}

.header-style-four .main-menu .navigation > li {
  margin-left: 45px;
  margin-right: 0;
}

.header-style-four .main-menu .navigation > li.dropdown > span:after,
.header-style-four .main-menu .navigation > li > span,
.header-style-four .main-menu .navigation > li > a {
  color: #ffffff;
}

.header-style-four .upload-cv,
.header-style-four .main-menu .navigation > li > ul > li > a:hover,
.header-style-four .main-menu .navigation > li > ul > li > span:hover,
.header-style-four .main-menu .navigation > li > ul > li > a:hover,
.header-style-four .main-menu .navigation > li > ul > li > ul > li > a:hover,
.header-style-four .main-menu .navigation > li > ul > li > ul > li > span:hover,
.header-style-four
  .main-menu
  .navigation
  > li
  .mega-menu-bar
  .column
  > ul
  > li
  > a:hover,
.header-style-four .main-menu .navigation > li > ul > li > ul > li.current > a,
.header-style-four .main-menu .navigation > li > ul > li.current > a {
  color: #d93025;
}

.header-style-four .main-menu .navigation > li > ul > li > ul,
.header-style-four .main-menu .navigation > li > .mega-menu,
.header-style-four .main-menu .navigation > li > ul {
  border-top-color: #d93025;
}

.header-style-four .main-menu .navigation > li > .mega-menu:before,
.header-style-four .main-menu .navigation > li > ul:before {
  border-bottom-color: #d93025;
}

.header-style-four .btn-style-five {
  color: #202124;
}

.header-style-four .btn-style-five:hover {
  color: #ffffff;
}

.header-style-four.fixed-header {
  background: #262c3f;
}

/*** 

====================================================================
Header Style Five
====================================================================

***/

.header-style-five {
}

.header-style-five .logo-box {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-style-five .logo {
  padding: 25px 0;
}

.header-style-five .outer-box {
  padding: 25px 0;
}

.header-style-five.fixed-header .outer-box {
  padding: 15px 0;
}

.header-style-five.fixed-header .logo {
  padding: 10px 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
Sticky Header
====================================================================

***/

.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.952941);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sticky-header.fixed-header {
  opacity: 1;
  z-index: 99;
  visibility: visible;
}

.sticky-header .main-box {
  padding: 0;
  margin: 0;
}

.sticky-header .logo,
.sticky-header .outer-box,
.sticky-header .main-box:before {
  display: none;
}

.sticky-header .sticky-logo {
  display: block;
}

.sticky-header .main-menu .navigation > li.dropdown > span:after,
.sticky-header .main-menu .navigation > li.dropdown > span,
.sticky-header .main-menu .navigation > li.dropdown > a:after,
.sticky-header .main-menu .navigation > li > a {
  color: #363636;
}

.sticky-header .main-menu .navigation > li.current.dropdown > span:after,
.sticky-header .main-menu .navigation > li.current > span,
.sticky-header .main-menu .navigation > li.current.dropdown > a:after,
.sticky-header .main-menu .navigation > li.current > a {
  color: var(--talaneo);
}

/*** 

====================================================================
Mobile Header
====================================================================

***/

.mobile-header {
  position: relative;
  padding: 20px 15px;
  display: none;
  z-index: 99;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.mobile-header:after {
  display: table;
  clear: both;
  content: "";
}

.mobile-header .logo {
  position: relative;
  float: left;
  margin: 0 !important;
  padding: 0 !important;
  left: 0;
}

.mobile-header .logo img {
  max-height: 50px;
}

.mobile-header .nav-outer {
  position: relative;
  float: right;
}

.mobile-header .outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-header .search-box .search-btn {
  font-size: 20px;
  color: #ffffff;
}

.mobile-header .search-box {
  margin: 0;
}

.mobile-header .cart-btn > a {
  font-size: 20px;
}

.mobile-header .cart-btn > a,
.mobile-header .login-box {
  margin-left: 20px;
}

.mobile-header .login-box i {
  font-size: 30px;
}

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
  margin-left: 20px;
  width: 30px;
  color: #202124;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  display: none;
}

.mm-panels {
  --mm-color-background: #202124;
  --mm-color-text-dimmed: #979797;
  --mm-color-border: rgba(255, 255, 255, 0.1);
  --mm-listitem-size: 50px;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  color: #979797;
}

.mm-menu li {
  padding: 0 20px;
}

.mm-menu .mm-listitem:after {
  display: none;
}

.mm-menu li.current > a {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  line-height: 30px;
}

.mm-btn:after,
.mm-btn:before {
  border-color: #ffffff;
  border-width: 1.5px;
  height: 6px;
  width: 6px;
}

.mm-panels .mm-counter {
  position: absolute;
  right: 45px;
  top: 50%;
  text-indent: 0;
  display: block;
  margin-top: -10px;
  background-color: rgba(255, 167, 55, 0.8);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
}

#toggle-user-sidebar {
  margin-left: 20px;
}

#toggle-user-sidebar .thumb {
  display: block;
  height: 30px;
  width: 30px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
}

.mm-menu_fullscreen.mm-menu_position-bottom {
  top: 80px;
}

.mm-navbar_sticky {
  padding: 7px 0;
}

.mm-listview {
  padding-top: 20px;
}

.mm-menu li {
  margin-bottom: 10px;
}

.mm-add-listing {
  margin-top: 87px;
  border: transparent;
  display: none;
}

.mm-add-listing .theme-btn {
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  color: #ffffff !important;
  height: 60px;
  border-radius: 10px;
}

.mm-add-listing .mm-listitem__text {
  padding: 0;
}

.mm-add-listing .contact-info {
  position: relative;
  padding: 18px 0;
  display: block;
}

.mm-add-listing .phone-num {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 13px;
  color: #7e7e7e;
}

.mm-add-listing .phone-num span {
  display: block;
}

.mm-add-listing .phone-num a {
  color: #ffffff;
}

.mm-add-listing .address {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #7e7e7e;
}

.mm-add-listing .email {
  font-size: 14px;
  line-height: 30px;
  color: #7e7e7e !important;
}

.mm-add-listing .social-links {
  position: relative;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mm-add-listing .social-links a {
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #7e7e7e;
  margin-right: 37px;
}

.mm-panel:after {
  height: 10px;
}

/************************ Mega Menu ***************************/

.main-menu .navigation > li.has-mega-menu {
  position: static;
}

.main-menu .navigation > li > .mega-menu .image-box {
  position: absolute;
  right: -230px;
  top: -15px;
  padding: 20px;
}

.main-menu .navigation > li > .mega-menu {
  position: absolute;
  left: auto;
  right: auto;
  margin: auto;
  width: 880px;
  background: #ffffff;
  padding: 30px 30px;
  min-height: 225px;
  top: 100%;
  z-index: 100;
  opacity: 0;
  margin-top: -15px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 0px 0px 8px 8px;
  border-top: 2px solid var(--talaneo);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.main-menu .navigation > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu .navigation > li > .mega-menu:before {
  position: absolute;
  left: 20px;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 5px solid var(--talaneo);
  content: "";
}

.main-menu .navigation > li > .mega-menu .mega-menu-bar {
  position: relative;
  padding-top: 40px;
}

.main-menu .navigation > li .mega-menu-bar .column {
  position: relative;
}

.main-menu .navigation > li .mega-menu-bar h3 {
  position: absolute;
  top: -40px;
  left: 15px;
  font-size: 16px;
  line-height: 25px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 15px;
}

.main-menu .navigation > li .mega-menu-bar > ul {
  position: relative;
}

.main-menu .navigation > li .mega-menu-bar .column > ul > li {
  position: relative;
  width: 100%;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li .mega-menu-bar .column {
  position: relative;
  margin-bottom: 10px;
}

.main-menu .navigation > li .mega-menu-bar .column > ul > li:last-child {
  margin-bottom: 0;
}

.main-menu .navigation > li .mega-menu-bar .column > ul > li > a {
  position: relative;
  display: block;
  line-height: 20px;
  font-weight: 400;
  font-size: 15px;
  color: #202124;
  padding: 10px 0;
  text-align: left;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover {
  color: var(--talaneo);
}

/*** 

====================================================================
Login Popup
====================================================================

***/

.jquery-modal {
  z-index: 999;
}

.modal {
  height: auto;
  padding: 0;
  max-width: 500px;
  overflow: visible;
  background: none;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.blocker {
  background: rgba(32, 33, 36, 0.5);
}

#login-modal {
  max-width: 500px;
  padding: 30px 40px 20px;
  overflow: visible;
  background: #fff;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#login-modal .social-btn-two {
  padding: 10px 20px;
}

.login-from {
  position: relative;
  text-align: center;
  background: #fff;
}

.login-from h3 {
  position: relative;
  display: block;
  font-size: 20px;
  color: #1b2032;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.login-from .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px;
}

.login-from .form-group .text,
.login-from .form-group .forgot-pass,
.login-from .form-group label {
  font-size: 14px;
  color: #5c5c5c;
  line-height: 1em;
  margin: 0;
}

.login-from .form-group label {
  cursor: pointer;
}

.login-from .form-group .forgot-pass:hover {
  text-decoration: underline;
}

.login-from .form-group input[type="text"],
.login-from .form-group input[type="email"],
.login-from .form-group input[type="password"],
.login-from .form-group input[type="url"],
.login-from .form-group select,
.login-from .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 54px;
  font-size: 14px;
  color: #5c6770;
  line-height: 30px;
  font-weight: 400;
  background: #ffffff;
  border: 2px solid #e6e8ed;
  padding: 10px 20px;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.login-from .form-group input[type="submit"],
.login-from .form-group button {
  width: 100%;
  min-width: auto;
}

.login-from .form-group .text {
  width: 100%;
}

.login-from .form-group .social-btn {
  margin-right: 30px;
  width: 100%;
}

.login-from .form-group .social-btn:last-child {
  margin-right: 0;
}

.social-btn {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: inherit;
  font-size: 16px;
  border-radius: 5px;
  background-color: inherit;
  font-weight: 700;
  padding: 10px 30px;
  color: #ffffff;
  line-height: 25px;
}

.social-btn span {
  margin-right: 3px;
}

.social-btn.facebook-btn {
  background-color: #3b5998;
  color: #ffffff;
}

.social-btn.google-btn {
  background-color: #dc4d28;
  color: #ffffff;
}

.login-from .bottom-text {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-from .signup-link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #021f4a;
}

.login-from .signup-link:hover {
  text-decoration: underline;
}

.login-from.register .form-group.full-width {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  margin-bottom: 8px;
}

.login-from.register .checkbox-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
}

.login-from.register .checkbox-wrap label {
  margin-left: 10px;
}

.login-from.register .checkbox-wrap label a {
  color: #ef2f24;
}

.login-from.register .checkbox-wrap label a:hover {
  text-decoration: underline;
}

#login-modal .login-form h3 {
  text-align: center;
}

.modal a.close-modal {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;
  background: var(--talaneo-bg-light);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--talaneo-bg-light);
  text-indent: 0;
  font-size: 0;
}

.modal a.close-modal:before {
  font-family: Flaticon;
  content: "\f175";
  position: relative;
  font-size: 12px;
  color: #000;
  width: 40px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*** 

====================================================================
Page Title
====================================================================

***/

.page-title {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 50px 0 40px;
  background: var(--talaneo-bg-light-card-gradient);
}

/* .page-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/icons/bg-4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
} */

.page-title h3 {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #202124;
  margin-bottom: 10px;
}

.page-breadcrumb {
  font-size: 15px;
  line-height: initial;
  margin: 5px -15px 10px;
}

.page-breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  padding: 0 8px;
  text-transform: capitalize;
}

.page-breadcrumb li:after {
  position: absolute;
  top: 2px;
  right: -5px;
  content: "/";
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.page-breadcrumb li:last-child::after {
  display: none;
}

.page-breadcrumb li a {
  color: rgba(0, 0, 0, 0.75);
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-breadcrumb li a:hover {
  opacity: 0.9;
  color: #1370b5;
}

/* Style Two */

.page-title.style-two {
  padding: 100px 0;
}

.page-title .job-search-form {
  margin-bottom: 0;
}

/* Style Tgree */

.page-title.style-three {
  padding: 70px 0 50px;
  background: #ffffff;
}

.page-title.style-three:before {
  display: none;
}

.page-title .top-filters {
  margin-top: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-title .top-filters .form-group {
  margin: 0 10px;
}

.page-title.bg-light {
  background: none !important;
}

.page-title.bg-light:before {
  display: none;
}

/*** 

====================================================================
Section Title
====================================================================

***/

.sec-title-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.sec-title {
  position: relative;
  margin-bottom: 50px;
}

.sec-title .sub-title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  font-weight: 500;
  margin-bottom: 10px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #202124;
  font-weight: 500;
}

.sec-title .text {
  position: relative;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 26px;
}

.sec-title.light h2,
.sec-title.light .text {
  color: #ffffff;
}

.sec-title-outer .link {
  position: relative;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--talaneo);
  margin-bottom: 50px;
}

.sec-title-outer .link .icon {
  margin-left: 5px;
}

.sec-title-outer select {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  min-width: 202px;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 19px;
  color: #202124;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sec-title-outer .select-box-outer {
  position: relative;
  margin-bottom: 50px;
}

.sec-title-outer .select-box-outer .icon {
  position: absolute;
  right: 22px;
  top: 50%;
  z-index: 1;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  color: #202124;
  pointer-events: none;
}

/*** 

====================================================================
Banner Section
====================================================================

***/

.banner-section {
  position: relative;
  display: block;
  padding: 0 55px 0;
}

.banner-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../assets/images/icons/bg-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}

.banner-section .content-column {
  position: relative;
}

.banner-section .content-column .inner-column {
  position: relative;
  padding: 270px 0 150px;
}

.banner-section .content-column .title-box {
  position: relative;
  margin-bottom: 40px;
}

.banner-section .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-section .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section .content-column .text {
  font-size: 15px;
  color: #696969;
  line-height: 25px;
}

.banner-section .popular-searches {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.banner-section .popular-searches .title {
  margin-right: 20px;
  font-weight: 500;
}

.banner-section .popular-searches a {
  color: inherit;
  font-size: 14px;
}

.header-info-card {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 22px;
}

.header-arrow{
position: absolute;
left: 60%;
bottom: 40px;
}

.job-search-form {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  border-radius: 10px;
  min-width: 740px;
  margin-bottom: 22px;
}

.job-search-form {
  background: #ffffff;
  padding: 20px 20px;
  padding-left: 30px;
  border-radius: 10px;
}

.job-search-form .form-group {
  position: relative;
  text-align: left;
  margin-bottom: 0;
}

.job-search-form .form-group .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
  color: #5c6770;
  background: #ffffff;
  z-index: 9;
}

.job-search-form .form-group.category {
  border-left: 1px solid #e6e6e6;
}
.job-search-form .form-group:first-child {
  border-right: 1px solid #e6e6e6;
}

.job-search-form .ui-selectmenu-button.ui-button,
.job-search-form .chosen-container-single .chosen-single,
.job-search-form .form-group input,
ng-select,
.job-search-form .form-group select,
.job-search-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 15px;
  color: #696969;
  line-height: 30px;
  font-weight: 400;
  background: #ffffff;
  padding: 15px 0px;
  padding-left: 40px;
  border: 0;
  border-radius: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

ng-select{
  height: auto !important;
}
ng-select input{
  height: auto !important;
}

ng-select .ng-value{
    margin-bottom: 5px;
    color: white;
     background: var(--talaneo);
     padding-left: 8px;
     padding-right: 8px;
    border-radius: 12px;
    margin-right: 5px;
}

.ng-dropdown-panel{
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
width: 200%;
}
  .ng-dropdown-panel .ng-option{
    padding: 8px 0;
}

.job-search-form .chosen-container-multi .chosen-choices,
.job-search-form .chosen-container.chosen-with-drop .chosen-drop {
  background: #ffffff;
}

.job-search-form .chosen-container-single .chosen-single {
  padding-left: 40px;
  border: 1px solid #ffffff !important;
  border-bottom: 0 !important;
  border-radius: 8px;
}

.job-search-form .chosen-container-single .chosen-drop {
  /*    border: 1px solid #ffffff !important;
border-top: 0 !important;*/
}

.job-search-form .chosen-container-active .chosen-single {
  /*    border: 1px solid #dddddd !important;
border-bottom: 0 !important;*/
}

.job-search-form .chosen-container-active .chosen-drop {
  border: 1px solid #ecedf2 !important;
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px !important;
  padding-top: 10px !important;
}

.job-search-form .form-group.btn-box {
  text-align: right;
}

.job-search-form .form-group .theme-btn {
  width: 100%;
  min-width: auto;
  height: 60px;
}

/* Job Search Form */

.job-search-form.style-two,
.job-search-form.style-two form,
.job-search-form.style-two .form-group .icon,
.job-search-form.style-two .form-group,
.job-search-form.style-two .ui-selectmenu-button.ui-button,
.job-search-form.style-two .ui-selectmenu-button.ui-button,
.job-search-form.style-two .chosen-container-single .chosen-single span,
.job-search-form.style-two .chosen-container-single .chosen-single,
.job-search-form.style-two .form-group input,
.job-search-form.style-two .form-group select,
.job-search-form.style-two .form-group textarea {
  background: var(--talaneo);
  color: #ffffff;
  border-color: var(--talaneo) !important;
}

.job-search-form.style-two .chosen-container-single .chosen-single {
  padding-left: 40px;
}

.job-search-form.style-two .chosen-container-single .chosen-single div:after {
  color: #fff;
}

.job-search-form.style-two .form-group {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.banner-section .image-column {
  position: relative;
}

.banner-section .image-column .image-box {
  position: relative;
  display: block;
  padding-top: 150px;
  margin-right: -170px;
  padding-left: 90px;
}

.banner-section .image-column .main-image {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 0;
}

.banner-section .image-column .main-image img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/*================================
Info Blocks
==================================*/

/* Info Block One */

.info_block {
  position: relative;
  padding: 20px 40px 20px 90px;
  min-height: 90px;
  min-width: 260px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  border-radius: 8px;
}

.info_block .icon {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #f9ab00;
  background: rgba(249, 171, 0, 0.15);
}

.info_block p {
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-weight: 500;
}

/* Info Block Two */

.info_block_two {
  position: relative;
  min-height: 146px;
  max-width: 276px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 35px;
  background: #ffffff;
  -webkit-box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  border-radius: 8px;
  text-align: center;
}

.info_block_two .image {
  position: relative;
  margin-top: 15px;
}

.info_block_two p {
  font-size: 16px;
  line-height: 25px;
  color: #202124;
  font-weight: 500;
}

/* Info Block Three */

.info_block_three {
  position: relative;
  padding: 20px 80px 20px 90px;
  -webkit-box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  background: #ffffff;
  min-height: 90px;
  min-width: 290px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.info_block_three .icon {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #d93025;
  background: rgba(217, 48, 37, 0.2);
}

.info_block_three p {
  font-size: 16px;
  line-height: 25px;
  color: #202124;
  font-weight: 500;
}

.info_block_three .sub-text {
  font-size: 15px;
  color: #696969;
  display: block;
}

.info_block_three .right_icon {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 14px;
  color: #ffffff;
  background: rgba(217, 48, 37, 0.2);
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

/* Info Block Four */

.info_block_four {
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  min-width: 320px;
}

.info_block_four .inner {
  position: relative;
  padding: 20px 30px 20px 66px;
  -webkit-box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 46px 35px rgba(25, 25, 46, 0.04);
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
}

.info_block_four .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 34px;
  font-weight: 400;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--talaneo);
  background: #ffffff;
  overflow: hidden;
  -webkit-box-shadow: 0px 46.7708px 35.0781px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 46.7708px 35.0781px rgba(25, 25, 46, 0.04);
  border-radius: 9.35417px;
  z-index: 1;
}

.info_block_four p {
  font-size: 16px;
  line-height: 25px;
  color: #202124;
  font-weight: 500;
}

.info_block_four .sub-text {
  font-size: 15px;
  color: #696969;
  display: block;
}

.banner-section .image-column .info_block {
  position: absolute;
  left: -30px;
  top: 160px;
}

.banner-section .image-column .info_block_two {
  position: absolute;
  right: -100px;
  top: 210px;
}

.banner-section .image-column .info_block_three {
  position: absolute;
  right: -50px;
  top: 490px;
}

.banner-section .image-column .info_block_four {
  position: absolute;
  left: 30px;
  bottom: 85px;
}

/*** 

====================================================================
Banner Section
====================================================================

***/

.banner-section-two {
  position: relative;
  display: block;
  padding: 0 55px 0;
}

.banner-section-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../assets/images/icons/bg-2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}

.banner-section-two .content-column {
  position: relative;
}

.banner-section-two .content-column .inner-column {
  position: relative;
  padding: 270px 0 75px;
}

.banner-section-two .content-column .title-box {
  position: relative;
  margin-bottom: 40px;
}

.banner-section-two .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-section-two .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section-two .content-column .text {
  font-size: 15px;
  color: #ffffff;
  line-height: 25px;
}

.banner-section-two .popular-searches {
  position: relative;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
}

.banner-section-two .popular-searches .title {
  margin-right: 20px;
  font-weight: 500;
}

.banner-section-two .popular-searches a {
  color: inherit;
  font-size: 14px;
  color: #ffffff;
}

.banner-section-two .image-column {
  position: relative;
}

.banner-section-two .image-column .image-box {
  position: relative;
  padding: 150px 0 50px;
  text-align: center;
  margin-right: -300px;
}

.banner-section-two .image-column .image-box .image {
  position: relative;
  margin-bottom: 0;
}

.banner-section-two .bottom-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 125px;
}

.banner-section-two .count-employers {
  position: relative;
  color: #ffffff;
}

.banner-section-two .count-employers .title {
  position: relative;
  margin-right: 20px;
}

.banner-section-two .count-employers img {
  max-height: 50px;
}

.banner-section-two .bottom-box .upload-cv {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner-section-two .bottom-box .upload-cv .icon {
  position: relative;
  margin-right: 5px;
  font-size: 25px;
  line-height: 25px;
}

/*** 

====================================================================
Banner Section Three
====================================================================

***/

.banner-section-three {
  position: relative;
  display: block;
  padding: 0 55px 0;
  background-color: #ffffff;
}

.banner-section-three .content-column {
  position: relative;
  z-index: 3;
}

.banner-section-three .content-column .inner-column {
  position: relative;
  padding: 270px 0 150px;
}

.banner-section-three .content-column .title-box {
  position: relative;
  margin-bottom: 40px;
}

.banner-section-three .content-column h1 {
  font-size: 60px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 60px;
}

.banner-section-three .content-column h1 .colored {
  color: var(--talaneo);
}

.banner-section-three .content-column .text {
  font-size: 15px;
  color: #696969;
  line-height: 25px;
}

.banner-section-three .popular-searches {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.banner-section-three .popular-searches .title {
  margin-right: 20px;
  color: #202124;
  font-weight: 500;
}

.banner-section-three .popular-searches a {
  color: inherit;
  font-size: 14px;
}

.banner-section-three .image-column {
  position: relative;
}

.banner-section-three .image-column .image-box {
  position: relative;
  padding: 120px 0 0;
  margin-left: -90px;
  margin-right: -280px;
}

.banner-section-three .image-column .image-box .image {
  margin-bottom: 0;
}

/* Job Search Form Two*/

.job-search-form-two {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  border-radius: 10px;
  min-width: 740px;
  margin-bottom: 18px;
}

.job-search-form-two form {
  background: #ffffff;
  padding: 20px 20px;
  padding-left: 30px;
}

.job-search-form-two .row {
  margin: 0 -30px;
}

.job-search-form-two .form-group {
  position: relative;
  text-align: left;
  padding: 0 30px;
  margin-bottom: 0;
}

.job-search-form-two .title {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 0;
}

.job-search-form-two .form-group .icon {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
  color: #5c6770;
  background: #ffffff;
  z-index: 9;
}

.job-search-form-two .form-group:first-child {
  border-right: 1px solid #e6e6e6;
}

.job-search-form-two .ui-selectmenu-button.ui-button,
.job-search-form-two .form-group input,
.job-search-form-two .form-group select,
.job-search-form-two .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 30px;
  font-size: 15px;
  color: #696969;
  line-height: 20px;
  font-weight: 400;
  background: #ffffff;
  padding: 5px 0px;
  padding-right: 40px;
  border: 0;
  border-radius: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-search-form-two .form-group.btn-box {
  text-align: right;
}

.job-search-form-two .form-group .theme-btn {
  width: 100%;
  min-width: auto;
  height: 60px;
}

/*** 

====================================================================
Banner Section Four
====================================================================

***/

.banner-section-four {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 100vh;
  min-height: 700px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 120px 0;
}

.banner-section-four .title-box {
  position: relative;
  margin-bottom: 25px;
}

.banner-section-four h3 {
  position: relative;
  font-weight: 500;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
}

.banner-section-four .popular-searches {
  position: relative;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
}

.banner-section-four .popular-searches .title {
  margin-right: 20px;
  font-weight: 500;
}

.banner-section-four .popular-searches a {
  color: inherit;
  font-size: 14px;
  color: #ffffff;
}

.banner-section-four .job-search-form {
  border-radius: 50px;
  max-width: 1070px;
  margin: 0 auto 20px;
}

.banner-section-four .job-search-form {
  padding: 10px 10px;
  padding-left: 30px;
  border-radius: 50px;
}

.banner-section-four .job-search-form .theme-btn {
  border-radius: 50px;
  color: #ffffff;
}

.banner-section-four .image-column {
  position: relative;
}

.banner-section-four .image-column .image-box {
  position: relative;
  padding: 150px 0 50px;
  text-align: center;
  margin-right: -300px;
}

.banner-section-four .image-column .image-box .image {
  position: relative;
  margin-bottom: 0;
}

.banner-section-four .bottom-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 125px;
}

.banner-section-four .count-employers {
  position: relative;
  color: #ffffff;
}

.banner-section-four .count-employers .title {
  position: relative;
  margin-right: 20px;
}

.banner-section-four .count-employers img {
  max-height: 50px;
}

.banner-section-four .bottom-box .upload-cv {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner-section-four .bottom-box .upload-cv .icon {
  position: relative;
  margin-right: 5px;
  font-size: 25px;
  line-height: 25px;
}

/*** 

====================================================================
Banner Section Five
====================================================================

***/

.banner-section-five {
  position: relative;
  display: block;
  padding: 0 55px 0;
}

.banner-section-five:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../assets/images/icons/bg-3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}

.banner-section-five .content-column {
  position: relative;
}

.banner-section-five .content-column .inner-column {
  position: relative;
  padding: 270px 0 150px;
}

.banner-section-five .content-column .title-box {
  position: relative;
  margin-bottom: 40px;
}

.banner-section-five .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-section-five .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section-five .content-column .text {
  font-size: 15px;
  color: #696969;
  line-height: 25px;
}

.banner-section-five .popular-searches {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.banner-section-five .popular-searches .title {
  margin-right: 20px;
  font-weight: 500;
}

.banner-section-five .popular-searches a {
  color: inherit;
  font-size: 14px;
}

.banner-section-five .image-column {
  position: relative;
}

.banner-section-five .image-column .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -20px;
}

.banner-section-five .image-column .image-box {
  position: relative;
  display: block;
  padding-top: 150px;
  margin-right: -255px;
  padding-left: 100px;
}

.banner-section-five .image-column .column {
  position: relative;
  padding: 0 20px;
}

.banner-section-five .image-column .column .image {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 40px;
}

.banner-section-five .image-column .image-box img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.banner-section-five .info_block {
  position: absolute;
  left: 0;
  top: 200px;
}

.banner-section-five .info_block_two {
  position: absolute;
  left: 230px;
  bottom: 60px;
}

/*** 

====================================================================
Banner Section Six
====================================================================

***/

.banner-section-six {
  position: relative;
  display: block;
  padding: 0 55px 0;
}

.banner-section-six:after,
.banner-section-six:before {
  position: absolute;
  left: 0;
  width: 50%;
  top: 0;
  height: 100%;
  background: #ecedf2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  z-index: -1;
}

.banner-section-six:before {
  border-radius: 0px 60px 60px 0px;
  left: 50%;
  width: 500px;
}

.banner-section-six .content-column {
  position: relative;
  z-index: 9;
}

.banner-section-six .content-column .inner-column {
  position: relative;
  padding: 185px 0 150px;
}

.banner-section-six .content-column .title-box {
  position: relative;
  margin-bottom: 50px;
}

.banner-section-six .job-search-form {
  padding: 10px 10px;
  padding-left: 20px;
}

.banner-section-six .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-section-six .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section-six .content-column .text {
  font-size: 15px;
  color: #696969;
  line-height: 25px;
}

.banner-section-six .popular-searches {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.banner-section-six .popular-searches .title {
  margin-right: 20px;
  font-weight: 700;
}

.banner-section-six .popular-searches a {
  color: inherit;
  font-size: 14px;
}

.banner-section-six .image-column {
  position: relative;
}

.banner-section-six .image-column .image-box {
  position: relative;
  display: block;
  padding-top: 67px;
  margin-right: -410px;
  padding-bottom: 67px;
}

.banner-section-six .image-column .main-image {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 0;
}

.banner-section-six .image-column .main-image img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.banner-section-six .image-column .info_block {
  position: absolute;
  left: -30px;
  top: 113px;
}

.banner-section-six .image-column .info_block_two {
  position: absolute;
  right: 120px;
  top: 150px;
}

.banner-section-six .image-column .info_block_three {
  position: absolute;
  right: 185px;
  bottom: 220px;
}

.banner-section-six .image-column .info_block_four {
  position: absolute;
  left: 70px;
  bottom: 40px;
}

.banner-section-six .info_block_four .inner {
  padding: 15px 30px 15px 66px;
}

/*** 

====================================================================
Banner Section Seven
====================================================================

***/

.banner-section-seven {
  position: relative;
  display: block;
  padding: 0 55px 0;
}

.banner-section-seven .content-column {
  position: relative;
}

.banner-section-seven .content-column .inner-column {
  position: relative;
  padding: 175px 0 150px;
}

.banner-section-seven .content-column .title-box {
  position: relative;
  margin-bottom: 50px;
}

.banner-section-seven .job-search-form {
  border-radius: 100px;
  overflow: hidden;
}

.banner-section-seven .job-search-form .form-group .theme-btn {
  border-radius: 50px;
}

.banner-section-seven .job-search-form {
  padding: 10px 10px;
  padding-left: 30px;
}

.banner-section-seven .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 25px;
}

.banner-section-seven .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section-seven .content-column .text {
  font-size: 15px;
  color: #696969;
  line-height: 25px;
}

.banner-section-seven .popular-searches {
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.banner-section-seven .popular-searches .title {
  margin-right: 20px;
  font-weight: 700;
}

.banner-section-seven .popular-searches a {
  color: inherit;
  font-size: 14px;
}

.banner-section-seven .image-outer {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}

.banner-section-seven .image-outer .image {
  position: relative;
  margin-left: -130px;
}

.banner-section-seven .image-outer .image img {
  width: 100%;
}

.banner-section-seven .clients-section-two {
  padding-top: 0;
  margin-top: 100px;
  margin-right: -140px;
}

/*** 

====================================================================
Banner Section Eight
====================================================================

***/

.banner-section-eight {
  position: relative;
  display: block;
  padding: 0 55px 0;
  margin-bottom: 90px;
}

.banner-section-eight:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  content: "";
  background: -o-linear-gradient(191.23deg, var(--talaneo-bg-light-card) 0.12%, var(--talaneo-bg-light-card) 99.74%);
  background: linear-gradient(258.77deg, var(--talaneo-bg-light-card) 0.12%, var(--talaneo-bg-light-card) 99.74%);
}

.banner-section-eight .content-column {
  position: relative;
}

.banner-section-eight .content-column .inner-column {
  position: relative;
  padding: 340px 0 90px;
}

.banner-section-eight .content-column .title-box {
  position: relative;
  margin-bottom: 20px;
}

.banner-section-eight .job-search-form {
  min-width: 1070px;
}

.banner-section-eight .job-search-form .form-group .theme-btn {
  border-radius: 50px;
}

.banner-section-eight .job-search-form {
  padding: 20px 20px;
  padding-left: 30px;
}

.banner-section-eight .content-column h3 {
  font-size: 50px;
  line-height: 60px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}

.banner-section-eight .content-column h3 .colored {
  color: var(--talaneo);
}

.banner-section-eight .content-column .text {
  font-size: 15px;
  line-height: 20px;
  color: #202124;
}

.banner-section-eight .content-column .theme-btn {
  border-radius: 8px !important;
}

.banner-section-eight .bottom-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 160px;
}

.banner-section-eight .count-employers {
  position: relative;
  color: #202124;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner-section-eight .count-employers .title {
  position: relative;
  margin-right: 20px;
}

.banner-section-eight .count-employers img {
  max-height: 50px;
}

.banner-section-eight .bottom-box .upload-cv {
  position: relative;
  font-size: 15px;
  line-height: 25px;
  color: var(--talaneo);
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  margin-right: 40px;
}

.banner-section-eight .bottom-box .upload-cv .icon {
  position: relative;
  margin-right: 5px;
  font-size: 25px;
  line-height: 25px;
}

.banner-section-eight .image-outer {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}

.banner-section-eight .image-outer .image {
  position: relative;
  margin-left: 0;
}

.banner-section-eight .image-outer .image img {
  width: 100%;
}

/*** 

====================================================================
Banner Section Nine
====================================================================

***/

.banner-section-nine {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  text-align: center;
  padding: 360px 0 75px;
}

.banner-section-nine .title-box {
  position: relative;
  margin-bottom: 90px;
}

.banner-section-nine h3 {
  position: relative;
  font-weight: 500;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
  margin-bottom: 5px;
}

.banner-section-nine .text {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.banner-section-nine .job-search-form {
  max-width: 1070px;
  margin: 0 auto 20px;
}

.banner-section-nine .job-search-form {
  padding: 10px 10px;
  padding-left: 30px;
}

.banner-section-nine .job-search-form .form-group label {
  position: absolute;
  left: 15px;
  top: -45px;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.banner-section-nine .job-search-form .theme-btn {
  color: #ffffff !important;
}

.banner-section-nine .fun-fact-section {
  max-width: 75%;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 0;
}

.banner-section-nine .fun-fact-section .count-box {
  color: #ffffff;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 0;
}

.banner-section-nine .fun-fact-section .counter-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

/*** 

====================================================================
Banner Section Ten
====================================================================

***/

.banner-section-ten {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  text-align: center;
  padding: 370px 0 37px;
}

.banner-section-ten .auto-container {
  position: relative;
  z-index: 1;
}

.banner-section-ten .banner-carousel .owl-item,
.banner-section-ten .banner-carousel .owl-stage-outer,
.banner-section-ten .banner-carousel .owl-stage {
  height: 100%;
}
.banner-section-ten .banner-carousel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.banner-section-ten .banner-carousel .bg-image {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-section-ten .banner-carousel.default-nav .owl-next,
.banner-section-ten .banner-carousel.default-nav .owl-prev {
  left: 50px;
  background: transparent;
  border: 1px solid #ffffff;
  color: #fff;
  font-size: 14px;
}

.banner-section-ten .banner-carousel.default-nav .owl-next {
  left: auto;
  right: 50px;
}

.banner-section-ten .title-box {
  position: relative;
  margin-bottom: 40px;
}

.banner-section-ten h3 {
  position: relative;
  font-weight: 500;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
  margin-bottom: 5px;
}

.banner-section-ten .text {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.banner-section-ten .job-search-form {
  max-width: 1070px;
  margin: 0 auto 210px;
}

.banner-section-ten .job-search-form {
  padding: 20px 20px;
  padding-left: 30px;
}

.banner-section-ten .job-search-form .form-group label {
  position: absolute;
  left: 15px;
  top: -45px;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.banner-section-ten .job-search-form .theme-btn {
  color: #ffffff !important;
  background-color: #d93025;
}

.top-features {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}

.feature-block-two {
  margin-bottom: 30px;
}

.feature-block-two .inner-box {
  position: relative;
  text-align: center;
}

.feature-block-two .icon {
  position: relative;
  font-size: 36px;
  line-height: 1em;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 20px;
}

.feature-block-two h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 3px;
}

.feature-block-two .count {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

/*** 

====================================================================
Work Section
====================================================================

***/

.work-section {
  position: relative;
  padding: 100px 0 50px;
}

.work-section.style-two {
  background: var(--talaneo-bg-light-card);
}

.work-block {
  position: relative;
  margin-bottom: 50px;
}

.work-block .inner-box {
  position: relative;
  text-align: center;
  padding: 50px 44px 60px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #ffffff;
  border-radius: 8px;
}

.work-block .inner-box:hover {
  -webkit-box-shadow: 0px 0px 30px rgba(64, 79, 104, 0.1);
  box-shadow: 0px 0px 30px rgba(64, 79, 104, 0.1);
}

.work-block .image {
  position: relative;
  margin-bottom: 35px;
}

.work-block h5 {
  position: relative;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
}

.work-block p {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
}

/*** 

====================================================================
Job Categories
====================================================================

***/


/*=== Category Blcok ===*/

.category-block {
  position: relative;
  margin-bottom: 30px;
}

.category-block .inner-box {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 19px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.category-block .content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 90px;
  min-height: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.category-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  background: #ecedf2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--talaneo);
  font-size: 36px;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block .inner-box:hover .icon {
  color: #ffffff;
  background: var(--talaneo);
}

.category-block h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 4px;
}

.category-block h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block h4 a:hover {
  color: var(--talaneo);
}

.category-block p {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

/*=== Category Blcok Two ===*/

.category-block-two {
  position: relative;
  margin-bottom: 30px;
}

.category-block-two .inner-box {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-two .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.category-block-two .content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-block-two .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  background: #ecedf2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--talaneo);
  font-size: 36px;
  border-radius: 50%;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-two .inner-box:hover .icon {
  color: #ffffff;
  background: var(--talaneo);
}

.category-block-two h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 29px;
}

.category-block-two h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-two h4 a:hover {
  color: var(--talaneo);
}

.category-block-two p {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

/*=== Category Blcok Three ===*/

.category-block-three {
  position: relative;
  margin-bottom: 30px;
}

.category-block-three .inner-box {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-three .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.category-block-three .content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-block-three .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  background: #ecedf2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--talaneo);
  font-size: 36px;
  border-radius: 10px;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-three h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 29px;
}

.category-block-three h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.category-block-three h4 a:hover {
  color: var(--talaneo);
}

.category-block-three p {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

.category-block-three:nth-child(1) .icon {
  background: #d4f4f8;
  color: var(--talaneo);
}

.category-block-three:nth-child(2) .icon {
  background: #feddd8;
  color: #ff2200;
}

.category-block-three:nth-child(3) .icon {
  background: #f2e0fe;
  color: #9900ff;
}

.category-block-three:nth-child(4) .icon {
  background: #ffeee2;
  color: #ff2200;
}

.category-block-three:nth-child(5) .icon {
  background: #d4efdf;
  color: #00ca52;
}

.category-block-three:nth-child(6) .icon {
  background: #fcf4db;
  color: #ecb300;
}

.category-block-three:nth-child(7) .icon {
  background: #dad9f8;
  color: #0800ed;
}

.category-block-three:nth-child(8) .icon {
  background: #d4f4f8;
  color: #00d0ea;
}



/*** 

====================================================================
Job Section
====================================================================

***/

.job-section {
  position: relative;
  padding: 100px 0;
}

.job-section.alternate {
  padding: 20px 0 70px;
}

.job-section .tab-buttons {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.job-section .default-tabs .tabs-content {
  padding: 0;
}

.job-block {
  position: relative;
  /* margin-bottom: 30px; */
}

.job-block .inner-box {
  position: relative;
  padding: 32px 20px 22px 30px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.job-block .content {
  position: relative;
  padding-left: 68px;
  min-height: 51px;
}

.job-block .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.job-block h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block h4 a:hover {
  color: var(--talaneo);
}

.job-block .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.job-block .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.job-block .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.job-block .job-info li a {
  color: #696969;
}

.job-block .bookmark-btn {
  position: absolute;
  right: 0;
  top: -15px;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block .bookmark-btn:hover {
  background: #ecedf2;
}

.job-block .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job-other-info li {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  margin-bottom: 10px;
}

.job-other-info li.green,
.job-other-info li.privacy {
  background: rgba(52, 168, 83, 0.15);
  color: var(--talaneo);
}

.job-other-info li.required {
  background: rgba(249, 171, 0, 0.15);
  color: #f9ab00;
}

.job-section .btn-box {
  position: relative;
  text-align: center;
  margin-top: 15px;
}

/*=== Style Two ===*/

.job-section.style-two {
  background-color: #ecedf2;
}

.job-section.style-two .default-dots .owl-dots {
  margin-top: 20px;
}

/*** 

====================================================================
Job Section Two
====================================================================

***/

.job-section-two {
  position: relative;
  background-color: var(--talaneo-bg-light-card);
  padding: 100px 0;
}

.job-block-two {
  position: relative;
  margin-bottom: 30px;
}

.job-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 32px 20px 15px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-two .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.job-block-two .content {
  position: relative;
  padding-left: 80px;
  min-height: 60px;
  padding-top: 2px;
}

.job-block-two .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-two .company-logo img {
  display: block;
  width: 100%;
}

.job-block-two h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 8px;
}

.job-block-two h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-two h4 a:hover {
  color: var(--talaneo);
}



.job-block-two .bookmark-btn {
  position: absolute;
  right: 35px;
  top: 15px;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-two .bookmark-btn:hover {
  background: #ecedf2;
}

.job-block-two .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 9px;
}

.job-section-two .btn-box {
  position: relative;
  text-align: center;
  margin-top: 20px;
}

/*** 

====================================================================
Job Section Three
====================================================================

***/

.job-section-three {
  position: relative;
  padding: 100px 0;
}

.job-block-three {
  position: relative;
  margin-bottom: 30px;
}

.job-block-three .inner-box {
  position: relative;
  padding: 32px 20px 22px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-three .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.job-block-three .content {
  position: relative;
  padding-left: 68px;
  min-height: 51px;
}

.job-block-three .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-three .company-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: fill;
  object-fit: fill;
}

.job-block-three h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.job-block-three h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-three h4 a:hover {
  color: var(--talaneo);
}

.job-block-three .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.job-block-three .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.job-block-three .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.job-block-three .job-info li a {
  color: #696969;
}

.job-block-three .bookmark-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-three .bookmark-btn:hover {
  background: #ecedf2;
}

.job-block-three .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*** 

====================================================================
Job Section Four
====================================================================

***/

.job-section-four {
  position: relative;
  padding: 100px 0;
}

.job-block-four {
  position: relative;
  margin-bottom: 30px;
}

.job-block-four .inner-box {
  position: relative;
  padding: 47px 45px 37px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.job-block-four .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.job-block-four .job-other-info {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.job-block-four .job-other-info li {
  width: auto;
  display: inline-block;
  border-radius: 0;
  border-radius: 0 30px 30px 0;
}

.job-block-four .job-other-info .time {
  position: absolute;
  right: 10px;
  margin-right: 0px;
  border-radius: 40px;
}

.job-block-four .company-name {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: var(--talaneo);
  margin-bottom: 6px;
}

.job-block-four .company-logo {
  position: relative;
  display: block;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 12px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-four h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.job-block-four h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-four h4 a:hover {
  color: var(--talaneo);
}

.job-block-four .location {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
}

.job-block-four .location .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.job-block-four .location a {
  color: #696969;
}

.job-block-four .post-tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}

.job-block-four .post-tags li {
  position: relative;
  background: var(--talaneo-bg-light);
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
  border-radius: 20px;
  margin: 0 5px 10px;
}

.job-block-four .post-tags li a {
  color: #696969;
}

.job-block-four .post-tags li.colored {
  background: var(--talaneo);
  color: #ffffff;
}

.job-section-four .btn-box {
  text-align: center;
  margin-top: 20px;
}

/*=== Alternate ===*/

.job-section-four.alternate .job-block-four .inner-box {
  padding: 45px 35px 35px;
}

.job-section-four.alternate .job-block-four h4 {
  font-size: 16px;
  line-height: 22px;
}

/*** 

====================================================================
Job Section Five
====================================================================

***/

.job-section-five {
  position: relative;
  padding: 100px 0;
  background-color: #ecedf2;
}

.job-section-five .outer-box {
  position: relative;
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border: 1px solid #ecedf2;
  border-radius: 8px;
  overflow: hidden;
}

.job-block-five {
  position: relative;
}

.job-block-five .inner-box {
  position: relative;
  padding: 30px 32px;
  padding-right: 120px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-left: 2px solid transparent;
}

.job-block-five:nth-child(even) .inner-box {
  background-color: #f6f6fb;
}

.job-block-five .inner-box:hover {
  border-left: 2px solid var(--talaneo);
}

.job-block-five .inner-box .theme-btn {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -25px;
  background: var(--talaneo-bg-light-card);
}

.job-block-five .inner-box .theme-btn:hover {
  background: rgba(25, 103, 210, 1);
}

.job-block-five .content {
  position: relative;
  padding-left: 80px;
  padding-top: 5px;
  min-height: 60px;
}

.job-block-five .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-five h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.job-block-five h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-five h4 a:hover {
  color: var(--talaneo);
}

.job-block-five .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.job-block-five .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.job-block-five .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.job-block-five .job-info li a {
  color: #696969;
}

.job-block-five .bookmark-btn {
  position: absolute;
  right: 0;
  top: -15px;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-five .bookmark-btn:hover {
  background: #ecedf2;
}

.job-block-five .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  bottom: 3px;
  margin-left: 10px;
}

.job-section-five .btn-box {
  text-align: center;
  margin-top: 20px;
}

.job-section-five.style-two {
  background-color: #ffffff;
  padding: 100px 0 50px;
}

.job-section-five.style-two .job-block-five .inner-box:hover {
  border-color: #d93025;
}

.job-section-five.style-two .job-block-five .inner-box .theme-btn {
  background-color: rgba(217, 48, 37, 0.07);
}

.job-section-five.style-two .job-block-five .inner-box .theme-btn:hover {
  background-color: rgba(217, 48, 37, 1);
}

.job-section-five.style-two .featured-column {
  margin-bottom: 50px;
}

/*** 

====================================================================
Job Section Six
====================================================================

***/

.job-section-six {
  position: relative;
  padding: 0px 0 50px;
  border-bottom: 1px solid #ecedf2;
}

.job-section-six .image-column {
  position: relative;
  margin-bottom: 50px;
}

.job-section-six .image-column .image {
  margin-bottom: 0;
  padding-right: 16px;
}

.job-section-six .image-column .image img {
  display: block;
  width: 100%;
}

.job-section-six .content-column {
  position: relative;
}

.job-section-six .content-column .inner-column {
  position: relative;
  padding-top: 36px;
  padding-left: 45px;
}

.job-section-six .sec-title {
  margin-bottom: 28px;
}

.job-section-six .job-block-five {
  margin-bottom: 30px;
}

.job-section-six .job-block-five .inner-box {
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}

.job-section-six .job-block-five .job-info {
  margin-bottom: 0;
}

.job-section-six .job-block-five .company-logo {
  width: 50px;
}

.job-section-six .job-block-five .content {
  padding-left: 70px;
  padding-top: 0;
  min-height: 50px;
}

.job-section-six .job-block-five h4 {
  margin-bottom: 0;
}

/*** 

====================================================================
Job Detail Section
====================================================================

***/

.job-detail-section {
  position: relative;
}

.job-detail-section .upper-box {
  position: relative;
  background-image: url(../assets/images/icons/bg-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: cover;
  padding: 80px 0 70px;
}

.job-detail-section.style-three .upper-box {
  background-image: url(../assets/images/icons/bg-5.png);
}

.job-detail-section .job-block-outer {
  position: relative;
  margin-bottom: 30px;
}

.job-block-seven {
  position: relative;
}

.job-block-seven .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.job-block-seven .content {
  position: relative;
  padding-left: 120px;
  min-height: 51px;
}

.job-block-seven.style-two .content {
  padding-left: 0;
}

.job-block-seven .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-seven .company-logo img {
  display: block;
  width: 100%;
}

.job-block-seven h4 {
  position: relative;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.3em;
  color: #202124;
  top: -3px;
  margin-bottom: 10px;
}

.job-block-seven h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-block-seven h4 a:hover {
  color: var(--talaneo);
}

.job-block-seven .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.job-block-seven .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-right: 20px;
  margin-bottom: 3px;
}

.job-block-seven .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.job-block-seven .job-info li a {
  color: #696969;
}

.job-block-seven .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job-block-seven .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-block-seven .btn-box .theme-btn {
  min-width: 220px;
}

.job-block-seven .bookmark-btn {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--talaneo);
  border-radius: 7px;
  background: var(--talaneo-bg-light-card);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-left: 20px;
}

.job-block-seven .bookmark-btn:hover {
  background: var(--talaneo);
  color: #fff;
}

.job-block-seven.style-three .inner-box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.job-block-seven.style-three .company-logo {
  position: relative;
  margin-bottom: 20px;
}

.job-block-seven.style-three .job-info {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.job-block-seven.style-three .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-bottom: 30px;
}

.job-detail-outer {
  position: relative;
  padding: 50px 0;
}

.job-detail {
  position: relative;
  padding-right: 30px;
}

.candidate-detail-section.style-three .job-detail {
  padding-right: 0;
  padding-left: 20px;
}

.job-overview-two h4,
.job-detail h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 20px;
}

.job-detail p {
  position: relative;
  font-size: 15px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 26px;
}

.job-detail .image {
  position: relative;
  margin-bottom: 50px;
}

.job-detail .images-outer .image {
  margin-bottom: 30px;
  overflow: hidden;
}

.job-detail .images-outer .image img {
  display: block;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-detail .images-outer .image a {
  position: relative;
  display: block;
}

.job-detail .images-outer .image a:before {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  background: var(--talaneo);
  line-height: 45px;
  color: #fff;
  text-align: center;
  content: "+";
  font-size: 30px;
  border-radius: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.job-detail .images-outer .image a:hover:before {
  opacity: 1;
  visibility: visible;
}

.job-detail .images-outer .image:hover a img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.list-style-three {
  position: relative;
  margin-bottom: 50px;
}

.list-style-three li {
  position: relative;
  font-size: 15px;
  line-height: 26px;
  color: #696969;
  padding-left: 20px;
  margin-bottom: 25px;
}

.list-style-three li:before {
  position: absolute;
  left: 0;
  top: 12px;
  height: 4px;
  width: 4px;
  background: #202124;
  content: "";
}

.job-detail-section .other-options {
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
}

.job-detail-section .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-share h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-right: 20px;
}

.social-share a {
  padding: 10px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #222222;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin: 5px 0;
  margin-right: 10px;
}

.social-share a.facebook {
  background: #3b5998;
}
.social-share a.twitter {
  background: #55acee;
}
.social-share a.google {
  background: #dd4b39;
}
.social-share a.linkedin {
  background: #007bb5;
}
.social-share a.youtube {
  background: #bb0000;
}
.social-share a.instagram {
  background: #125688;
}
.social-share a.pinterest {
  background: #cb2027;
}
.social-share a.snapchat-ghost {
  background: #fffc00;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.social-share a.skype {
  background: #00aff0;
}
.social-share a.android {
  background: #a4c639;
}
.social-share a.dribbble {
  background: #ea4c89;
}
.social-share a.vimeo {
  background: #45bbff;
}
.social-share a.tumblr {
  background: #2c4762;
}
.social-share a.vine {
  background: #00b489;
}
.social-share a.foursquare {
  background: #45bbff;
}
.social-share a.stumbleupon {
  background: #eb4924;
}
.social-share a.flickr {
  background: #f40083;
}
.social-share a.yahoo {
  background: #430297;
}
.social-share a.soundcloud {
  background: #ff5500;
}
.social-share a.reddit {
  background: #ff5700;
}
.social-share a.rss {
  background: #ff6600;
}

.social-share a:hover {
  opacity: 0.8;
}

.social-share a i {
  margin-right: 5px;
}

.job-detail-section .related-jobs {
  position: relative;
  margin-bottom: 50px;
}

.job-detail-outer .map-outer {
  position: relative;
  margin-bottom: 50px;
}

.job-detail-outer .map-canvas {
  height: 350px;
}

.related-jobs .title-box {
  position: relative;
  margin-bottom: 28px;
}

.related-jobs .title-box h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 10px;
}

.sidebar {
  position: relative;
}

.sidebar-widget {
  position: relative;
  padding: 30px 30px 30px;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  margin-bottom: 40px;
}

.sidebar-widget .widget-title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 30px;
}

.sidebar .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar .btn-box .theme-btn {
  width: 100%;
}

.sidebar .btn-box .bookmark-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--talaneo);
  border-radius: 7px;
  background: var(--talaneo-bg-light-card);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-left: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.sidebar .btn-box .bookmark-btn:hover {
  background: var(--talaneo);
  color: #fff;
}

/* Job Overview*/

.job-overview {
  position: relative;
}

.job-overview li {
  position: relative;
  padding-left: 50px;
  margin-bottom: 30px;
}

.job-overview .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  font-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.job-overview h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
}

.job-overview span {
  font-size: 15px;
  line-height: 20px;
}

/* Job Overview Two*/

.job-overview-two {
  position: relative;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 30px 0;
  margin-bottom: 50px;
}

.job-overview-two ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
}

.job-overview-two li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding-left: 50px;
  padding-right: 20px;
  margin-bottom: 30px;
}

.job-overview-two .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.job-overview-two h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
}

.job-overview-two span {
  font-size: 15px;
  line-height: 20px;
}

.sidebar-widget .map-outer {
  margin-bottom: 50px;
}

.sidebar-widget .map-canvas {
  min-height: 250px;
}

.job-skills {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job-skills li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.job-skills li a {
  padding: 5px 20px;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  border-radius: 4px;
  background: #ffffff;
}

.company-widget {
  position: relative;
}

.company-widget .company-title {
  position: relative;
  padding-left: 78px;
  min-height: 60px;
  padding-top: 5px;
}

.company-widget .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
}

.company-widget .company-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
}

.company-widget .profile-link {
  font-size: 14px;
  line-height: 19px;
  color: var(--talaneo);
}

.company-widget .company-link {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  margin-bottom: 10px;
  background: rgba(25, 103, 210, 0.15);
  color: var(--talaneo);
}

.company-widget .company-info {
  position: relative;
  margin-top: 25px;
}

.company-widget .company-info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #202124;
  margin-bottom: 19px;
}

.company-widget .company-info li span {
  font-size: 15px;
  line-height: 25px;
  text-align: right;
  color: #696969;
}

.company-widget .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.company-widget .social-links a {
  font-size: 14px;
  line-height: 26px;
  color: #696969;
  margin-left: 17px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-widget .social-links a:hover {
  color: #bc91e8;
}

.company-widget .btn-box {
  position: relative;
  margin-bottom: 0px;
}

.company-widget .btn-box .theme-btn {
  display: block;
  width: 100%;
  background: rgba(25, 103, 210, 0.15);
  border-radius: 8px;
}

.company-widget .btn-box .theme-btn:hover {
  background: rgba(25, 103, 210, 1);
}

.sidebar-widget.contact-widget .theme-btn {
  position: relative;
  display: block;
  width: 100%;
}

.sidebar-widget.contact-widget .form-group input,
.sidebar-widget.contact-widget .form-group select,
.sidebar-widget.contact-widget .form-group textarea {
  background-color: #fff;
  border: 1px solid #ecedf2;
}

.sidebar-widget.contact-widget .form-group textarea {
  min-height: 160px;
  padding: 22px 20px;
}

/*** 

====================================================================
Job Detail Section / Style two
====================================================================

***/

.job-detail-section.style-two {
  background: #ffffff;
}

.job-detail-section.style-two .sidebar-widget {
  background: var(--talaneo-bg-light-card);
}
 
.job-detail-section.style-two .job-skills li a {
  background: var(--talaneo-bg-light);
}

/*** 

====================================================================
Features Section
====================================================================

***/

.features-section {
  position: relative;
  padding: 100px 0 70px;
}

.feature-block {
  position: relative;
  margin-bottom: 30px;
}

.feature-block .inner-box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.feature-block .image {
  position: relative;
  margin-bottom: 0;
}

.feature-block .image img {
  display: block;
  width: 100%;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.feature-block .inner-box:hover .image img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.feature-block .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.feature-block .overlay-box .content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 30px 25px;
  z-index: 9;
}

.feature-block .overlay-box h5 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  display: block;
  margin-bottom: 2px;
}

.feature-block .overlay-box .total-jobs {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}

.overlay-link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
}

/*** 

====================================================================
Testimonial Section
====================================================================

***/

.testimonial-section {
  position: relative;
  padding: 100px 0;
  background: var(--talaneo-bg-light);
}

.testimonial-section .carousel-outer {
  position: relative;
  margin: 0 -10px;
}

.testimonial-section .testimonial-carousel {
  max-width: 610px;
  margin: 0 auto;
}

.testimonial-block {
  position: relative;
  padding: 10px;
  margin-right: 20px;
  opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-section .testimonial-carousel .active .testimonial-block {
  opacity: 1;
}

.testimonial-section .testimonial-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-block .inner-box {
  position: relative;
  padding: 40px 40px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.testimonial-block .inner-box:before {
  position: absolute;
  height: 26px;
  width: 34px;
  right: 28px;
  top: 25px;
  background-image: url(../assets/images/icons/quote-icon.png);
  content: "";
  z-index: 2;
}

.testimonial-block .title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  font-weight: 500;
  margin-bottom: 20px;
}

.testimonial-block .text {
  font-size: 16px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 50px;
}

.testimonial-block .info-box {
  position: relative;
  padding-left: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 70px;
}

.testimonial-block .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #101c5d;
}

.testimonial-block .name {
  font-size: 18px;
  color: #696969;
  font-weight: 500;
}

.testimonial-block .designation {
  position: relative;
  display: block;
  font-size: 14px;
  color: #696969;
}

.testimonial-section.style-two {
  background-color: #ffffff;
  padding-bottom: 50px;
}

.testimonial-section.style-two .testimonial-block {
  opacity: 1;
}

.testimonial-section.style-two .default-dots .owl-dots {
  position: relative;
  margin-top: 40px;
}

.testimonial-section.style-two .testimonial-block .name {
  font-weight: 500;
  color: #202124;
}

.testimonial-section.style-two.alternate {
  padding: 100px 0;
}

.testimonial-section.style-two.alternate .testimonial-block {
  padding: 0;
}

/*** 

====================================================================
Testimonial Section Two
====================================================================

***/

.testimonial-section-two {
  position: relative;
  padding: 100px 0;
  min-height: 568px;
}

.testimonial-section-two .testimonial-left {
  position: absolute;
  right: 50%;
  top: 190px;
  margin-right: 410px;
}

.testimonial-section-two .testimonial-right {
  position: absolute;
  top: 190px;
  left: 50%;
  margin-left: 410px;
}

.testimonial-section-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-image: url(../assets/images/icons/shape-2.png); */
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.testimonial-section-two .carousel-outer {
  position: relative;
  margin: 0 -10px;
}

.testimonial-section-two .testimonial-carousel {
  max-width: 560px;
  margin: 0 auto;
}

.testimonial-block-two {
  position: relative;
}

.testimonial-block-two .inner-box {
  position: relative;
  text-align: center;
}

.testimonial-block-two .inner-box:before {
  position: absolute;
  height: 26px;
  width: 34px;
  right: 50%;
  top: 0;
  margin-right: 60px;
  background-image: url(../assets/images/icons/quote-icon.png);
  content: "";
  z-index: 2;
}

.testimonial-block-two .thumb {
  position: relative;
  display: inline-block;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 15px;
}

.testimonial-block-two .thumb img {
  display: block;
  width: 100%;
  border: 10px solid #ffffff;
  border-radius: 50%;
}

.testimonial-block-two .title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  font-weight: 400;
  margin-bottom: 10px;
}

.testimonial-block-two .text {
  font-size: 16px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 35px;
}

.testimonial-block-two .info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-block-two .name {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
}

.testimonial-block-two .designation {
  position: relative;
  display: block;
  font-size: 14px;
  color: #696969;
}

.testimonial-carousel .owl-dots {
  position: relative;
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  height: 8px;
  width: 8px;
  background: #bfc8cb;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin: 0 5px;
}

.testimonial-carousel .owl-dot.active {
  background: #202124;
  width: 20px;
}

/* Style Two */

.testimonial-section-two.style-two:before {
  display: none;
}

.testimonial-section-two.style-two .testimonial-block-two .inner-box:after {
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  width: 100%;
  background: var(--talaneo);
  border-radius: 30px;
  content: "";
  z-index: -1;
}

.testimonial-section-two.style-two .testimonial-block-two .inner-box {
  padding: 0 30px 96px;
}

.testimonial-section-two.style-two
  .testimonial-section-two
  .testimonial-carousel {
  max-width: 630px;
}

.testimonial-section-two.style-two .testimonial-block-two .designation,
.testimonial-section-two.style-two .testimonial-block-two .name,
.testimonial-section-two.style-two .testimonial-block-two .title,
.testimonial-section-two.style-two .testimonial-block-two .text {
  font-weight: 400 !important;
  color: #ffffff;
}

.testimonial-section-two.style-two .testimonial-carousel .owl-dots {
  position: absolute;
  margin-top: 0;
  width: 100%;
  bottom: 38px;
}

/*** 

====================================================================
Testimonial Section Three
====================================================================

***/

.testimonial-section-three {
  position: relative;
  padding: 100px 0 0;
}

.testimonial-section-three .auto-container {
  max-width: 1100px;
}

.testimonial-section-three .slide-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.testimonial-section-three .image-column {
  position: relative;
  margin-right: 140px;
  margin-bottom: 50px;
  max-width: 410px;
  width: 100%;
}

.testimonial-section-three .image-column .image {
  position: relative;
  margin-bottom: 0;
}

.testimonial-block-three {
  position: relative;
}

.testimonial-block-three .inner-box {
  position: relative;
  padding-top: 100px;
}

.testimonial-block-three .inner-box:before {
  position: absolute;
  height: 26px;
  width: 34px;
  right: 50%;
  top: 0;
  margin-right: 60px;
  background-image: url(../assets/images/icons/quote-icon.png);
  content: "";
  z-index: 2;
}

.testimonial-block-three .title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  margin-bottom: 20px;
}

.testimonial-block-three .text {
  font-size: 16px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 60px;
}

.testimonial-block-three .info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-block-three .name {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
}

.testimonial-block-three .designation {
  position: relative;
  display: block;
  font-size: 14px;
  color: #696969;
}

.testimonial-section-three .owl-dots {
  display: none;
}

.testimonial-section-three .owl-nav {
  position: absolute;
  left: 50%;
  bottom: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 15px;
}

.testimonial-section-three .owl-next,
.testimonial-section-three .owl-prev {
  position: relative;
  background: rgba(52, 168, 83, 0.15);
  border-radius: 8px;
  color: var(--talaneo);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
  font-size: 12px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-section-three .owl-next:hover,
.testimonial-section-three .owl-prev:hover {
  background: rgba(52, 168, 83, 1);
  color: #ffffff;
}

/*** 

====================================================================
Testimonial Section Four
====================================================================

***/

.testimonial-section-four {
  position: relative;
  padding: 100px 0;
}

.testimonial-section-four .testimonial-carousel {
  max-width: 560px;
  margin: 0 auto;
}

.testimonial-section-four .sec-title {
  margin-bottom: 90px;
}

.testimonial-block-four {
  position: relative;
}

.testimonial-block-four .inner-box {
  position: relative;
  text-align: center;
}

.testimonial-block-four .title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 22px;
}

.testimonial-block-four .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 40px;
}

.testimonial-block-four .info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-block-four .thumb {
  position: relative;
  display: inline-block;
  height: 78px;
  width: 78px;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid #ffffff;
  margin-bottom: 15px;
}

.testimonial-block-four .thumb img {
  border-radius: 50%;
}

.testimonial-block-four .name {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.testimonial-block-four .designation {
  position: relative;
  display: block;
  font-size: 14px;
  color: #ffffff;
  font-weight: 300;
}

/*** 

====================================================================
Clients Section
====================================================================

***/

.clients-section {
  position: relative;
  padding: 60px 0 60px;
  border-bottom: 1px solid #ecedf2;
}

.clients-section.alternate {
  padding: 80px 0 0;
  border-bottom: 0;
}

.clients-section.alternate2 {
  padding: 60px 0 100px;
}

.clients-section .sponsors-outer {
  position: relative;
  padding: 0 15px;
  max-width: 1430px;
  width: 100%;
  margin: 0 auto;
}

.clients-section .slide-item {
  position: relative;
}

.clients-section .image-box {
  position: relative;
  text-align: center;
  margin-bottom: 0;
}

.clients-section .image-box img {
  position: relative;
  display: inline-block;
  max-width: 50%;
  width: auto;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
Clients Section Two
====================================================================

***/

.clients-section-two {
  position: relative;
  padding: 60px 0 0;
}

.clients-section-two .sponsors-outer {
  position: relative;
  padding: 0 15px;
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.clients-section-two .slide-item {
  position: relative;
}

.clients-section-two .image-box {
  position: relative;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 0;
}

.clients-section-two .image-box img {
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: auto;
  border-radius: 24px;
  height: 33px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.clients-section-two .image-box img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.clients-section-two.alternate {
  padding: 100px 0;
}


/*===================================
About Ssection / Style Two
====================================*/

.about-section.style-two .image-column .inner-column {
  position: relative;
  padding-right: 17px;
}

.about-section.style-two .content-column .inner-column {
  padding-left: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about-section.style-two .list-style-two {
  position: relative;
  margin-bottom: 20px;
}

.about-section.style-two .content-column h2 {
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: #000000;
}

.about-section.style-two .content-column .sec-title .text {
  max-width: 100%;
  font-size: 14px;
  line-height: 24px;
}

.about-section.style-two .sec-title {
  margin-bottom: 43px;
}

.about-section.style-two .content-column .theme-btn {
  min-width: 190px;
}

.about-section.style-two .fun-fact-section {
  padding: 60px 0 70px;
}

.about-section.style-two .fun-fact-section .count-box {
  color: var(--talaneo);
}

.about-section.style-two .fun-fact-section .counter-title {
  font-size: 15px;
}

/*** 

====================================================================
About Section Two
====================================================================

***/

.about-section-two {
  position: relative;
  padding: 100px 0;
}

.about-section-two .content-column {
  position: relative;
  /* margin-bottom: 50px; */
}

.about-section-two .content-column .inner-column {
  position: relative;
  padding-left: 90px;
}

.about-section-two .sec-title {
  margin-bottom: 30px;
}

.about-section-two .sec-title h2 {
  font-size: 40px;
  line-height: 54px;
  color: #202124;
}

.about-section-two .sec-title .text {
  max-width: 415px;
  font-size: 15px;
  line-height: 26px;
  color: #696969;
}

.about-section-two .list-style-one {
  margin-bottom: 35px;
}

.about-section-two .image-column {
  position: relative;
  text-align: center;
  /* margin-bottom: 150px; */
  display: flex;
align-items: center;
}

.about-section-two .image-column .image-box {
  position: relative;
  margin-bottom: 0;
  margin-right: -50px;
}


.about-section-two .content-column .theme-btn {
  min-width: 180px;
}

.about-section-two.style-two .image-column .image-box {
  text-align: left;
  margin-right: 0;
}
.about-section-two.style-two .image-column .image-box img {
  border-radius: 24px;
}
.about-section-two.style-two .image-column .image-box video {
  border-radius: 24px;
  width: 100%;
  object-fit: cover;
}

/* .about-section-two.style-two .content-column .inner-column {
  padding-left: 120px;
} */

/*** 

====================================================================
About Section Three
====================================================================

***/

.about-section-three {
  position: relative;
  padding: 50px 0;
}

.about-section-three .images-box {
  position: relative;
}

.about-section-three .images-box .row {
  margin: 0 -5px;
}

.about-section-three .images-box .column {
  position: relative;
  padding: 0 5px;
}

.about-section-three .images-box .image {
  position: relative;
  margin-bottom: 10px;
}

.about-section-three .images-box .image img {
  border-radius: 4px;
  width: 100%;
}

.about-section-three .text-box,
.about-section-three .fun-fact-section {
  max-width: 900px;
  margin: 0 auto;
}

.about-section-three .fun-fact-section {
  padding: 50px 0 30px;
}

.about-section-three .text-box h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 31px;
  color: #202124;
  margin-bottom: 31px;
}

.about-section-three .text-box p {
  font-size: 15px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 26px;
}

/***

====================================================================
Fun Fact Section
====================================================================

***/

.fun-fact-section {
  position: relative;
  padding: 100px 0 70px;
  z-index: 1;
}

.fun-fact-section .counter-column {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.fun-fact-section .count-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 1em;
  color: #202124;
  font-weight: 500;
}

.fun-fact-section .counter-title {
  font-size: 15px;
  line-height: 30px;
  color: #696969;
  font-weight: 400;
  display: block;
}

.fun-fact-section.style-two {
  padding: 50px 0 20px;
}

.fun-fact-section.style-two .count-box {
  color: var(--talaneo);
}

.fun-fact-section.style-two .counter-title {
  color: #696969;
}



/*** 

====================================================================
Candidates Section
====================================================================

***/

.candidates-section {
  position: relative;
  padding: 100px 0;
  border-bottom: 1px solid #ecedf2;
}

.candidates-section .carousel-outer {
  margin: 0 -15px;
}

.candidates-carousel .owl-dots {
  margin-top: 20px;
}

.candidate-block {
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.candidate-block .inner-box {
  position: relative;
  padding: 30px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.candidate-block .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.candidate-block .image {
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.candidate-block .image img {
  width: 100%;
}

.candidate-block .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

.candidate-block .designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  margin-bottom: 10px;
}

.candidate-block .location {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #696969;
  margin-bottom: 25px;
}

.candidate-block .location i {
  font-size: 14px;
  margin-right: 5px;
}

.candidate-block .theme-btn {
  max-width: 240px;
  width: 100%;
}

.candidates-section.alternate .candidate-block .designation {
  color: #202124;
}

/*** 

====================================================================
Candidates Section Two
====================================================================

***/

.candidates-section-two {
  position: relative;
  padding: 100px 0 70px;
}

.candidate-block-two {
  position: relative;
  margin-bottom: 30px;
}

.candidate-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-two .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.candidate-block-two .content-box {
  position: relative;
  padding-left: 110px;
  padding-top: 7px;
}

.candidate-block-two .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
}

.candidate-block-two .image img {
  width: 100%;
}

.candidate-block-two .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

.candidate-block-two .designation {
  display: block;
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  line-height: 19px;
}

.candidate-block-two .location {
  position: relative;
  font-size: 14px;
  color: #696969;
}

.candidate-block-two .location i {
  font-size: 14px;
  margin-right: 5px;
}

.candidate-block-two .theme-btn {
  max-width: 150px;
  width: 100%;
}

/* Candidate block three */

.candidate-block-three {
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.candidate-block-three .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-three .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.candidate-block-three .inner-box .content {
  position: relative;
  padding-left: 110px;
  min-height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.candidate-block-three .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.candidate-block-three .image img {
  width: 100%;
}

.candidate-block-three .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

.candidate-block-three .name a {
  color: #202124;
}

.candidate-block-three .candidate-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.candidate-block-three .candidate-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.candidate-block-three .candidate-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.candidate-block-three .candidate-info li a {
  color: #696969;
}

.candidate-block-three .candidate-info li.designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  padding-left: 0;
}

.candidate-block-three .post-tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.candidate-block-three .post-tags li {
  position: relative;
  background: var(--talaneo-bg-light);
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
  border-radius: 20px;
  margin-right: 10px;
}

.candidate-block-three .post-tags li a {
  color: #696969;
}

.candidate-block-three .post-tags li.colored {
  background: var(--talaneo);
  color: #ffffff;
}

.candidate-block-three .bookmark-btn {
  position: relative;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ecedf2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-three .inner-box:hover .bookmark-btn {
  opacity: 1;
  visibility: visible;
}

.candidate-block-three .option-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.option-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.option-list li {
  position: relative;
  margin-left: 10px;
}

.option-list li button,
.option-list li a {
  position: relative;
  display: block;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  color: var(--talaneo);
  line-height: 30px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.option-list li button:before,
.option-list li a:before {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 10px;
  background: #202124;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  content: attr(data-text);
  white-space: nowrap;
  padding: 5px 15px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.option-list li button:after,
.option-list li a:after {
  position: absolute;
  left: 50%;
  top: -10px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #202124;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.option-list li button:hover:after,
.option-list li button:hover:before,
.option-list li a:hover:after,
.option-list li a:hover:before {
  opacity: 1;
  visibility: visible;
}

.option-list li button:hover,
.option-list li a:hover {
  background: var(--talaneo);
  color: #ffffff;
}

.option-box .delete-btn {
  position: relative;
  display: block;
  font-size: 20px;
  color: var(--talaneo);
  font-weight: 400;
  margin-left: 40px;
}

.resume-action .theme-btn:after {
  display: none;
}

.resume-action .theme-btn {
}

.resume-action .theme-btn i {
  margin-left: 40px;
}

.resume-action .dropdown-menu {
  padding: 10px 15px;
  min-width: 200px;
}

.resume-action .dropdown-menu a {
  position: relative;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  display: block;
  width: 100%;
  padding: 2px 0;
}

.resume-action .dropdown-menu a .la {
  color: var(--talaneo);
}

/* Candidate block Four */

.candidate-block-four {
  position: relative;
  margin-bottom: 30px;
}

.candidate-block-four .inner-box {
  position: relative;
  padding: 40px 30px 30px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid #ecedf2;
}

.candidate-block-four .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.candidate-block-four .job-other-info {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.candidate-block-four .thumb {
  position: relative;
  display: block;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 12px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-four .name {
  display: block;
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 3px;
}

.candidate-block-four .name a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-four .name a:hover {
  color: var(--talaneo);
}

.candidate-block-four .cat {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: var(--talaneo);
  margin-bottom: 10px;
}

.candidate-block-four .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 17px;
}

.candidate-block-four .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin: 0 15px 5px;
}

.candidate-block-four .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.candidate-block-four .job-info li a {
  color: #696969;
}

.candidate-block-four .post-tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.candidate-block-four .post-tags li {
  position: relative;
  background: var(--talaneo-bg-light);
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
  border-radius: 20px;
  margin: 0 5px 10px;
}

.candidate-block-four .post-tags li a {
  color: #696969;
}

.candidate-block-four .post-tags li.colored {
  background: var(--talaneo);
  color: #ffffff;
}

.candidate-block-four .theme-btn {
  display: block;
  width: 100%;
}

/*** 

====================================================================
Candidate Detail Section
====================================================================

***/

.candidate-detail-section {
  position: relative;
}

.candidate-detail-section .upper-box {
  position: relative;
  background-image: url(../assets/images/icons/bg-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: cover;
  padding: 80px 0 30px;
}

.candidate-detail-section.style-three .upper-box {
  padding: 70px 0 30px;
}

.candidate-detail-outer {
  padding: 50px 0 50px;
}

/* Candidate block Five */

.candidate-block-five {
  position: relative;
  margin-bottom: 50px;
}

.candidate-block-five .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.candidate-block-five .inner-box .content {
  position: relative;
  padding-left: 120px;
  min-height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.candidate-block-five .inner-box .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.candidate-block-five .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.candidate-block-five .image img {
  width: 100%;
}

.candidate-block-five .name {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 10px;
}

.candidate-block-five .name a {
  color: #202124;
}

.candidate-block-five .candidate-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.candidate-block-five .candidate-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 30px;
}

.candidate-block-five .candidate-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.candidate-block-five .candidate-info li a {
  color: #696969;
}

.candidate-block-five .candidate-info li.designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  padding-left: 0;
}

.candidate-block-five .post-tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.candidate-block-five .post-tags li {
  position: relative;
  background: #d3e1f5;
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: var(--talaneo);
  padding: 5px 20px;
  border-radius: 20px;
  margin-right: 10px;
}

.candidate-block-five .post-tags li a {
  color: var(--talaneo);
}

.candidate-block-five .post-tags li.colored {
  background: var(--talaneo);
  color: #ffffff;
}

.candidate-block-five .bookmark-btn {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--talaneo);
  border-radius: 7px;
  background: var(--talaneo-bg-light-card);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-left: 20px;
}

.candidate-block-five .option-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.candidate-block-five .btn-box .theme-btn {
  min-width: 220px;
}

/* Candidate block Six */

.candidate-block-six {
  position: relative;
}

.candidate-block-six .inner-box {
  position: relative;
  text-align: center;
}

.candidate-block-six .image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 20px;
}

.candidate-block-six .image img {
  width: 100%;
}

.candidate-block-six .name {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 10px;
}

.candidate-block-six .name a {
  color: #202124;
}

.candidate-block-six .designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  margin-bottom: 22px;
}

.candidate-block-six .content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.candidate-block-six .post-tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.candidate-block-six .post-tags li {
  position: relative;
  display: block;
  background: #d3e1f5;
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: var(--talaneo);
  padding: 5px 20px;
  border-radius: 20px;
  margin: 5px 0;
  margin-right: 10px;
}

.candidate-block-six .post-tags li a {
  display: block;
  color: var(--talaneo);
}

.candidate-block-six .post-tags li.colored {
  background: var(--talaneo);
  color: #ffffff;
}

.candidate-block-six .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.candidate-block-six .candidate-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.candidate-block-six .candidate-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 30px;
}

.candidate-block-six .candidate-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.candidate-block-six .candidate-info li a {
  color: #696969;
}

.candidate-block-six .bookmark-btn {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--talaneo);
  border-radius: 7px;
  background: var(--talaneo-bg-light-card);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-left: 20px;
}

.candidate-block-six .option-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.candidate-block-six .btn-box .theme-btn {
  min-width: 220px;
}

.video-outer {
  position: relative;
  margin-bottom: 50px;
}

.video-outer h4 {
  margin-bottom: 30px;
}

.video-box {
  position: relative;
}

.video-box .image {
  position: relative;
  margin-bottom: 30px;
}

.video-box .image img {
  display: block;
  width: 100%;
}

.video-box .image a {
  position: relative;
  display: block;
}

.video-box .image .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  background: #ffffff;
  border-radius: 50%;
  line-height: 90px;
  text-align: center;
  color: #000000;
  font-size: 44px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.candidate-detail-section .sidebar-widget {
  margin-bottom: 30px;
}

.candidate-detail-section .contact-widget .form-group input,
.candidate-detail-section .contact-widget .form-group textarea {
  background-color: #ffffff;
}

.candidate-detail-section .contact-widget .form-group textarea {
  min-height: 130px;
}

.candidate-detail-section
  .contact-widget
  .widget-content
  .default-form
  .form-group {
  margin-bottom: 20px;
}

.social-media-widget {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.social-media-widget .widget-title {
  margin-bottom: 0;
}

.social-media-widget .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-media-widget .social-links a {
  font-size: 14px;
  line-height: 26px;
  color: #696969;
  margin-left: 17px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-media-widget .social-links a:hover {
  color: #bc91e8;
}

/***

====================================================================
Listing Section  (Ls Section) 
====================================================================

***/

.ls-section {
  position: relative;
  padding: 50px 0 100px;
}

.ls-section .content-column .upper-box {
  position: relative;
  padding: 20px 30px;
}

.ls-section .filters-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 1000%;
  background: #1b2032;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.active-filters .ls-section .filters-backdrop {
  opacity: 0.8;
  visibility: visible;
}

.ls-section .filters-column.hide-left {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh !important;
  max-width: 320px;
  width: 100%;
  padding: 0 !important;
  background: #fff;
  z-index: 99;
  overflow-y: auto !important;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.ls-section .filters-column.hide-left .inner-column {
  min-height: 100%;
}

.ls-section .filters-column::-webkit-scrollbar {
  width: 5px;
}
.ls-section .filters-column::-webkit-scrollbar-track {
  background: transparent;
}
.ls-section .filters-column::-webkit-scrollbar-thumb {
  background-color: rgba(112, 112, 112, 0.5);
  border-radius: 10px;
}

.ls-section .filters-column.hide-left .filters-outer {
  margin-bottom: 0;
}

.active-filters .ls-section .filters-column.hide-left {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.ls-section h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 16px;
}

.ls-section .filter-block p {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ls-section .filter-block .form-group {
  position: relative;
  margin-bottom: 0;
}

.chosen-container .chosen-drop {
  border: 1px solid #ecedf2;
}

.ls-section .filter-block .chosen-container .chosen-drop {
  background: #ffffff;
}

.ls-section .filter-block .chosen-container .chosen-single,
.ls-section .filter-block input,
.ls-section .filter-block select,
.ls-section .filter-block textarea {
  width: 100%;
  height: 60px;
  padding: 14px 20px;
  padding-left: 54px;
  line-height: 30px;
  font-size: 15px;
  color: #696969;
  background: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.ls-section .filter-block input:focus {
  border-color: var(--talaneo);
}

.ls-section .filter-block .icon {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 16px;
  line-height: 30px;
}

.ls-section .switchbox-outer {
  position: relative;
  margin-bottom: 30px;
}

/* Range Slider widget */

.rangeslider-widget {
  position: relative;
}

.range-slider-one {
  position: relative;
  min-height: 48px;
  padding-top: 10px;
}

.range-slider-one .ui-widget.ui-widget-content {
  height: 5px;
  border: none;
  margin-bottom: 20px;
  border-radius: 30px;
  background: #d4e1f6;
}

.range-slider-one .ui-slider .ui-slider-range {
  top: 0px;
  height: 5px;
  background: var(--talaneo);
}

.range-slider-one .ui-state-default,
.range-slider-one .ui-widget-content .ui-state-default {
  position: absolute;
  top: -7px;
  width: 17px;
  height: 17px;
  background: #ffffff;
  border: 2px solid var(--talaneo);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
}

.range-slider-one .input-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.range-slider-one .amount-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 70px;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  background: #d4e1f6;
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  color: var(--talaneo);
}

.range-slider-one .amount {
  margin-right: 5px;
}

.range-slider-one .ui-state-default:last-child {
  display: block !important;
}

.range-slider-one.salary-range .ui-state-default {
  display: block !important;
}

/* Range Slider widget */

.tags-style-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tags-style-one li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tags-style-one li a {
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
}

.tags-style-one li a:hover {
  background: #d4e1f6;
  color: var(--talaneo);
}

.ls-section .call-to-action .image-column {
  background-size: 100%;
  width: 50%;
}

/*=====================
Ls Outer
======================*/

.ls-section .ls-outer {
  position: relative;
}

.ls-switcher {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ls-switcher .showing-result {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.ls-switcher .showing-result .text {
  font-size: 16px;
  color: #1b2032;
}

.ls-switcher .arrange {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
}

.ls-switcher .arrange a {
  margin-right: 10px;
}

.ls-switcher .arrange .icon {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
  border: 2px solid #d7dbe4;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  color: #a3acc2;
  font-size: 16px;
}

.ls-switcher .arrange .active .icon {
  color: #a3acc2;
  border-color: #a3acc2;
}

.top-filters {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.top-filters .form-group {
  position: relative;
  margin-right: 20px;
  margin-bottom: 0;
}

.top-filters .chosen-container .chosen-single {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  padding: 10px 22px;
  padding-right: 44px;
  color: var(--talaneo);
  background: var(--talaneo-bg-light-card);
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.top-filters .chosen-container-single .chosen-single div:after {
  color: var(--talaneo);
  content: "\f17c";
  font-family: "Flaticon";
  font-size: 10px;
}

.top-filters .chosen-container .chosen-single span {
  color: var(--talaneo);
  margin: 0;
  min-width: auto;
}

.top-filters .chosen-container .chosen-container-multi .chosen-choices,
.top-filters .chosen-container.chosen-with-drop .chosen-drop {
  background: #eff4fc;
}

.ls-switcher .sort-by {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.ls-switcher .sort-by .chosen-single {
  position: relative;
  height: 45px;
  padding: 10px 20px;
  font-size: 14px;
  color: #696969;
  line-height: 25px;
  background: var(--talaneo-bg-light);
}

.ls-switcher .sort-by .chosen-container {
  margin-left: 10px;
}

.ls-switcher .sort-by .chosen-container-single .chosen-single div:after {
  color: #696969;
  content: "\f17c";
  font-family: "Flaticon";
  font-size: 10px;
}

.ls-switcher .showing-result {
  position: relative;
}

.ls-switcher .showing-result .text {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.ls-switcher .showing-result .text strong {
  font-weight: 500;
  color: #202124;
}

/*** 

====================================================================
Listing Show More
==================0==================================================

***/

.ls-show-more {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 50px;
}

.ls-show-more p {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #202124;
  margin-bottom: 10px;
}

.ls-show-more .bar {
  position: relative;
  max-width: 300px;
  width: 300px;
  height: 6px;
  background: #d4e1f6;
  border-radius: 10px;
  margin-bottom: 17px;
}

.ls-show-more .bar .bar-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 20px;
  background: var(--talaneo);
}

.ls-show-more .show-more {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--talaneo);
  padding-bottom: 5px;
}

.ls-show-more .show-more:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: var(--talaneo);
  content: "";
}

/*** 

====================================================================
Listing Pagination
==================0==================================================

***/

.ls-pagination {
  position: relative;
  margin-top: 50px;
}

.ls-pagination ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ls-pagination li {
  position: relative;
  margin: 0 5px;
  font-size: 14px;
  color: #696969;
  line-height: 45px;
  min-width: 45px;
  font-weight: 400;
  text-align: center;
}

.ls-pagination li a {
  position: relative;
  display: block;
  color: #696969;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.ls-pagination li a:hover,
.ls-pagination li a.current-page {
  background: var(--talaneo);
  color: #ffffff;
}

.ls-pagination li.next a,
.ls-pagination li.prev a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  margin: 0 12px;
}

.ls-section .ls-footer {
  position: relative;
  padding: 40px 0;
  text-align: center;
  border-top: 1px solid #ecedf2;
}

.ls-section .copyright-text {
  font-size: 14px;
  line-height: 19px;
  color: #696969;
}

.ls-pagination li.next a:hover,
.ls-pagination li.prev a:hover {
  background: transparent;
  color: var(--talaneo);
}

/***

====================================================================
Listing Section / style Two
====================================================================

***/

.ls-section.style-two {
  padding: 0;
}

.ls-section.style-two .ls-outer {
  padding: 50px 50px;
}

.ls-section.style-two .filters-outer {
  padding: 50px 60px;
  margin-bottom: 0;
  height: 100%;
}

.ls-section.style-two .filters-column .inner-column {
  height: 100%;
}

/***

====================================================================
Ls Section / style Three
====================================================================

***/

.ls-section.style-three {
  background: var(--talaneo-bg-light);
}

.ls-section.style-three .ls-switcher .sort-by .chosen-single {
  background: #ffffff;
}

/***

====================================================================
Ls Section / style Four
====================================================================

***/

.ls-section.map-layout {
  padding: 0;
}

.ls-section.map-layout .content-column {
  position: relative;
  height: calc(100vh - 110px);
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.ls-section.map-layout .ls-cotainer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.ls-section.map-layout ::-webkit-scrollbar {
  width: 5px;
  background: #eeeeee;
}

.ls-section.map-layout ::-webkit-scrollbar-track {
  background: #ffffff;
}

.ls-section.map-layout ::-webkit-scrollbar-thumb {
  background: #eeeeee;
}

.ls-section.map-layout .ls-outer {
  padding: 30px 60px 30px;
}

.ls-section.map-layout .map-column {
  position: fixed;
  height: calc(100vh - 100px);
  right: 0;
  bottom: 0;
}

.ls-section.map-layout .map-column #map {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

/*** 

====================================================================
Listing Maps
====================================================================

***/

.listing-maps {
  position: relative;
  min-height: 500px;
}

.listing-maps #map {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.listing-maps.style-two {
  min-height: 670px;
}

.listing-maps .form-outer {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
}

.listing-maps .form-outer .job-search-form {
  margin-bottom: 0;
}

.cluster-visible {
  text-align: center;
  font-size: 16px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  background: var(--talaneo);
  border: 7px solid rgba(25, 103, 210, 0.2);
  -webkit-box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
}

.map-marker-container {
  position: absolute;
  margin-top: 10px;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
}

.marker-container {
  position: relative;
  margin: 10px auto;
  top: -5px;
  width: 46px;
  height: 46px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  -webkit-perspective: 1000;
}

.face {
  position: absolute;
  width: 46px;
  height: 46px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  z-index: 100;
  background: #4cbfd8;
  font-size: 24px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
  line-height: 46px;
}

.face:before {
  position: absolute;
  left: -7px;
  top: -7px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 7px solid rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0 7px 30px rgba(33, 33, 33, 0.1);
  box-shadow: 0 7px 30px rgba(33, 33, 33, 0.1);
  content: "";
}

.face > div {
  /* background-image: url(images/resource/company-logo/3-1.png); */
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.map-marker-container:hover .marker-container .marker-card,
.clicked .marker-container .marker-card,
.map-marker-container.clicked .marker-card {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#singleListingMap .marker-container {
  cursor: default;
}

.map-marker-container[data-marker_id="0"] .marker-arrow:before {
  border-color: #4cbfd8 transparent transparent;
}
.map-marker-container[data-marker_id="1"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}
.map-marker-container[data-marker_id="2"] .marker-arrow:before {
  border-color: #8c689d transparent transparent;
}
.map-marker-container[data-marker_id="3"] .marker-arrow:before {
  border-color: #35c2a5 transparent transparent;
}
.map-marker-container[data-marker_id="4"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}
.map-marker-container[data-marker_id="5"] .marker-arrow:before {
  border-color: #ffb874 transparent transparent;
}
.map-marker-container[data-marker_id="6"] .marker-arrow:before {
  border-color: #ff007a transparent transparent;
}

.map-marker-container[data-marker_id="0"] .face {
  background-color: #4cbfd8;
}
.map-marker-container[data-marker_id="1"] .face {
  background-color: #ff8480;
}
.map-marker-container[data-marker_id="2"] .face {
  background-color: #8c689d;
}
.map-marker-container[data-marker_id="3"] .face {
  background-color: #35c2a5;
}
.map-marker-container[data-marker_id="4"] .face {
  background-color: #ff8480;
}
.map-marker-container[data-marker_id="5"] .face {
  background-color: #ffb874;
}
.map-marker-container[data-marker_id="6"] .face {
  background-color: #ff007a;
}

.marker-card {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  z-index: 1;
}

/*==========================
Map Listing Item
===========================*/

#map .infoBox {
  margin-left: -45px;
  margin-bottom: 15px;
}

.map-listing-item {
  position: relative;
}

.map-listing-item .infoBox-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  background: var(--talaneo-bg-light);
  color: #202124;
  line-height: 24px;
  font-size: 12px;
  border-radius: 8px;
  z-index: 9;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.map-listing-item .infoBox-close:hover {
  background: var(--talaneo);
  color: #ffffff;
}

.map-listing-item .inner-box {
  position: relative;
  padding: 20px 20px;
  padding-left: 90px;
  border-radius: 10px;
  background: #ffffff;
  min-width: 358px;
}

.map-listing-item .inner-box:before {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ffffff;
  content: "";
}

.map-listing-item .image-box {
  position: absolute;
  left: 20px;
  top: 20px;
}

.map-listing-item .image {
  display: block;
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin-bottom: 0;
}

.map-listing-item .image img {
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.map-listing-item .content {
  position: relative;
  min-height: 50px;
}

.map-listing-item .title {
  color: #ffffff;
  opacity: 0.6;
  margin-left: 3px;
}

.map-listing-item h3 {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-family: 'Jost';
  margin-bottom: 4px;
}

.map-listing-item h3 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.map-listing-item h3 a:hover {
  color: #202124;
}

.map-listing-item .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.map-listing-item .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-right: 20px;
}

.map-listing-item .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.map-listing-item .job-info li a {
  color: #696969;
}

/*** 

====================================================================
Top Companies
====================================================================

***/

.top-companies {
  position: relative;
  padding: 100px 0;
}

.top-companies .carousel-outer {
  margin: 0 -15px;
}

.top-companies .companies-carousel .owl-dots {
  margin-top: 20px;
}

/* Company Block */

.company-block {
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.company-block .inner-box {
  position: relative;
  padding: 30px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.company-block .inner-box.bg-clr-1 {
  background: #fcf9f4;
}
.company-block .inner-box.bg-clr-2 {
  background: #f4f6fa;
}
.company-block .inner-box.bg-clr-3 {
  background: #f9f9f9;
}
.company-block .inner-box.bg-clr-4 {
  background: #fcf4fc;
}
.company-block .inner-box.bg-clr-5 {
  background: #fafcf3;
}

.company-block .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.company-block .image {
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.company-block .image img {
  width: 100%;
}

.company-block .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #202124;
  margin-bottom: 5px;
}

.company-block .designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  margin-bottom: 10px;
}

.company-block .location {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #696969;
  margin-bottom: 25px;
}

.company-block .location i {
  font-size: 14px;
  margin-right: 5px;
}

.company-block .theme-btn {
  max-width: 240px;
  width: 100%;
}

/* Company Block Two */

.company-block-two {
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.company-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-two .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.company-block-two .inner-box .content {
  position: relative;
  padding-left: 110px;
  min-height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.company-block-two .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.company-block-two .image img {
  width: 100%;
}

.company-block-two .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

.company-block-two .designation {
  display: block;
  font-size: 14px;
  color: var(--talaneo);
  line-height: 19px;
  margin-bottom: 10px;
}

.company-block-two .location {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #696969;
}

.company-block-two .location i {
  font-size: 14px;
  margin-right: 5px;
}

.company-block-two .theme-btn {
  width: 150px;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 23px;
  font-weight: 400;
  border-radius: 20px;
}

/* Company Block Three*/

.company-block-three {
  position: relative;
  margin-bottom: 30px;
}

.company-block-three .inner-box {
  position: relative;
  padding: 32px 20px 22px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.company-block-three .content {
  position: relative;
  padding-left: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  min-height: 51px;
}

.company-block-three .company-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.company-block-three h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three h4 a:hover {
  color: var(--talaneo);
}

.company-block-three .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.company-block-three .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.company-block-three .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.company-block-three .job-info li a {
  color: #696969;
}

.company-block-three .bookmark-btn {
  position: absolute;
  right: 10px;
  top: 15px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ecedf2;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
}

.company-block-three .inner-box:hover .bookmark-btn {
  opacity: 1;
  visibility: visible;
}

.company-block-three .job-other-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/*Company Block Four*/

.company-block-four {
  position: relative;
  margin-bottom: 30px;
}

.company-block-four .inner-box {
  position: relative;
  padding: 30px 15px 30px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .inner-box:hover {
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.company-block-four .featured {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  color: var(--talaneo);
  background: rgba(52, 168, 83, 0.15);
}

.company-block-four .company-logo {
  position: relative;
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 12px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .company-logo img {
  display: block;
  width: 100%;
}

.company-block-four h4 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.company-block-four h4 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four h4 a:hover {
  color: var(--talaneo);
}

.company-block-four .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.company-block-four .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.company-block-four .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.company-block-four .job-info li a {
  color: #696969;
}

.company-block-four .job-type {
  position: relative;
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  color: var(--talaneo);
  background: rgba(25, 103, 210, 0.15);
}

.company-block-four .bookmark-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .inner-box:hover .bookmark-btn {
  background: #ecedf2;
  opacity: 1;
  visibility: visible;
}

/* Style Two*/

.top-companies.style-two {
  background: #f3f7fb;
}

.top-companies.style-two .company-block .image img {
  border-radius: 50%;
}

.top-companies.style-two .company-block .theme-btn {
  border-radius: 50px;
}

/* Style Three*/

.top-companies.style-three .company-block {
  padding: 0 18px;
  margin-bottom: 50px;
}

.top-companies.style-three .company-block .inner-box {
  padding: 32px 20px 20px;
}

.top-companies.style-three .company-block .image {
  height: 80px;
  width: 80px;
  margin-bottom: 25px;
}

/*** 

====================================================================
Ads Section
====================================================================

***/

.ads-section {
  position: relative;
  padding: 100px 0 170px;
}

.advrtise-block {
  position: relative;
  margin-bottom: 30px;
}

.advrtise-block .inner-box {
  position: relative;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  min-height: 236px;
  padding: 51px 42px;
}

.advrtise-block h4 {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #202124;
  margin-bottom: 18px;
}

.advrtise-block h4 span {
  display: block;
  color: var(--talaneo);
}

.advrtise-block .theme-btn {
  width: 120px;
  font-weight: 400;
}

/*** 

====================================================================
Registeration Banners
====================================================================

***/

.registeration-banners {
  position: relative;
  padding: 100px 0 70px;
  border-bottom: 1px solid #ecedf2;
}

.banner-style-one {
  position: relative;
  margin-bottom: 30px;
}

.banner-style-one .inner-box {
  position: relative;
  background: rgba(32, 33, 36, 0.4);
  border-radius: 8px;
  padding: 54px 60px;
}

.banner-style-one .content {
  position: relative;
  padding-right: 220px;
}

.banner-style-one h3 {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 13px;
}

.banner-style-one p {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 17px;
}

.banner-style-one .theme-btn {
  position: relative;
  min-width: 240px;
  background: #ffffff;
  color: #202124;
}

.banner-style-one .image {
  position: absolute;
  right: 30px;
  bottom: 0;
  margin-bottom: 0;
}

/* Banner Style Two */

.banner-style-two {
  position: relative;
  margin-bottom: 30px;
}

.banner-style-two .inner-box {
  position: relative;
  background: rgba(217, 48, 37, 0.6);
  border-radius: 8px;
  padding: 54px 60px;
}

.banner-style-two .content {
  position: relative;
  padding-right: 220px;
}

.banner-style-two h3 {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 13px;
}

.banner-style-two p {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 17px;
}

.banner-style-two .theme-btn {
  position: relative;
  min-width: 240px;
  color: #d93025;
  background: #ffffff;
}

.banner-style-two .image {
  position: absolute;
  right: 30px;
  bottom: 0;
  margin-bottom: 0;
}

/*** 

====================================================================
News Section
====================================================================

***/

.news-section {
  position: relative;
  background-color: #ecedf2;
  padding: 100px 0 70px;
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}

.news-block .inner-box {
  position: relative;
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
}

.news-block .image-box {
  position: relative;
}

.news-block .image-box .image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0;
}

.news-block .image-box img {
  display: block;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .inner-box:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.news-block .lower-content {
  padding: 20px 20px 16px;
  position: relative;
}

.news-block .post-meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.news-block .post-meta li {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-right: 15px;
  padding-left: 20px;
  margin-bottom: 5px;
}

.news-block .post-meta li:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  height: 6px;
  width: 6px;
  background: #696969;
  border-radius: 5px;
  content: "";
}

.news-block .post-meta li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.news-block .post-meta li:first-child:before {
  display: none;
}

.news-block .post-meta li a:hover,
.news-block .post-meta li a {
  color: inherit;
}

.news-block .lower-content h3 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}

.news-block .lower-content h3 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .lower-content h3 a:hover {
  color: var(--talaneo);
}

.news-block .lower-content .text {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 15px;
}

.news-block .read-more {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: var(--talaneo);
}

.news-block .read-more i {
  margin-left: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .read-more:hover {
  color: inherit;
  color: var(--talaneo);
}

.news-block .read-more:hover i {
  opacity: 0;
  margin-left: 20px;
}

/* Style Two */

.news-section.style-two {
  background: #f3f7fb;
}

.news-section.style-two .row {
  margin: 0 -26px;
}

.news-section.style-two .news-block {
  padding: 0 26px;
}

.news-section.style-two .news-block .inner-box {
  padding: 0;
  background-color: transparent;
}

.news-section.style-two .news-block .lower-content {
  padding: 17px 0 0;
}

/* Style Three */

.news-section.style-three .news-block .post-meta {
  margin-bottom: 10px;
}

.news-section.style-three .news-block .inner-box {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.news-section.style-three .news-block .lower-content h3 {
  margin-bottom: 0;
}

.news-section.style-three .grid .news-block .lower-content {
  padding-bottom: 10px;
  padding-top: 19px;
}

/*** 

====================================================================
News Section Two
====================================================================

***/

.news-section-two {
  position: relative;
  padding: 100px 0 50px;
}

.news-section-two .sec-title {
  margin-bottom: 50px;
}

.news-section-two .news-block {
  margin-bottom: 50px;
}

.news-section-two .news-block .inner-box {
  padding: 0;
  background-color: transparent;
}

.news-section-two .news-block .lower-content {
  padding: 17px 0 0;
}

.news-section-two .news-block .post-meta {
  margin-bottom: 0;
}

.news-section-two .news-block h3 {
  margin-bottom: 0;
}

/* Style Two */

.news-section-two.style-two {
  background: rgba(52, 168, 83, 0.04);
}

/*** 

====================================================================
News Section Three
====================================================================

***/

.news-section-three {
  position: relative;
  background-color: #ecedf2;
  padding: 100px 0 70px;
}

.news-block-two {
  position: relative;
  margin-bottom: 30px;
}

.news-block-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ecedf2;
  background: #ffffff;
}

.news-block-two .image-box {
  position: relative;
}

.news-block-two .image-box .image {
  position: relative;
  border-radius: 10px;
  min-width: 200px;
  overflow: hidden;
  margin-bottom: 0;
}

.news-block-two .image-box img {
  display: block;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-two .inner-box:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.news-block-two .content-box {
  padding: 15px 30px;
  position: relative;
}

.news-block-two .post-meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.news-block-two .post-meta li {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-right: 15px;
  padding-left: 20px;
  margin-bottom: 5px;
}

.news-block-two .post-meta li:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  height: 6px;
  width: 6px;
  background: #696969;
  border-radius: 5px;
  content: "";
}

.news-block-two .post-meta li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.news-block-two .post-meta li:first-child:before {
  display: none;
}

.news-block-two .post-meta li a:hover,
.news-block-two .post-meta li a {
  color: inherit;
}

.news-block-two .content-box h3 {
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}

.news-block-two .content-box h3 a:hover,
.news-block-two .content-box h3 a {
  color: #1b2032;
}

.news-block-two .content-box .text {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 15px;
}

.news-block-two .read-more {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: var(--talaneo);
}

.news-block-two .read-more i {
  margin-left: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-two .read-more:hover {
  color: inherit;
  color: var(--talaneo);
}

.news-block-two .read-more:hover i {
  opacity: 0;
  margin-left: 20px;
}

/*** 

====================================================================
Blog Grid
====================================================================

***/

.blog-grid .news-block .inner-box {
  border: 1px solid #ecedf2;
  border-radius: 8px;
}

.blog-grid .news-block .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.sidebar-page-container {
  position: relative;
  padding: 100px 0 50px;
}

.sidebar-page-container .content-side {
  margin-bottom: 50px;
}

.blog-sidebar {
  position: relative;
  background: var(--talaneo-bg-light-card);
  padding: 30px;
  border-radius: 8px;
  margin-left: 20px;
}

.blog-sidebar .sidebar-widget {
  position: relative;
  padding: 0;
  margin: 0 0 30px;
}

.blog-sidebar .sidebar-widget:last-child {
  margin-bottom: 0;
}

/* Sidebar Title */

.sidebar-title {
  position: relative;
  margin-bottom: 20px;
}

.sidebar-title h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
}

/*Search Box*/

.sidebar.pd-right {
  padding-right: 20px;
}

.sidebar .search-widget {
  position: relative;
}

.sidebar .search-box {
  position: relative;
}

.sidebar .search-box .form-group {
  position: relative;
  margin-bottom: 0;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  line-height: 20px;
  padding: 20px 20px;
  padding-left: 54px;
  font-size: 15px;
  color: #696969;
  background: #ffffff;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar .search-box .form-group input[type="text"]:focus,
.sidebar .search-box .form-group input[type="search"]:focus {
  border-color: var(--talaneo);
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar .search-box .form-group button:hover {
  color: #ffffff;
}

.sidebar .search-box .form-group .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  z-index: 1;
}

/* Cat List*/

.catagory-list {
  position: relative;
}

.catagory-list li {
  position: relative;
  margin-bottom: 10px;
}

.catagory-list li a {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 25px;
  padding-left: 15px;
  color: #696969;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.catagory-list li a:before {
  position: absolute;
  left: 0;
  top: 11px;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background: #202124;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.catagory-list li a:hover:before,
.catagory-list li a:hover {
  color: var(--talaneo);
}

/*=== Latest News ===*/

.sidebar .recent-post {
  position: relative;
}

.recent-post .post {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 90px;
  min-height: 70px;
  margin-bottom: 20px;
}

.recent-post .post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.recent-post .post-thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recent-post .post-thumb img {
  position: relative;
  display: block;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.recent-post .post:hover .post-thumb img {
  opacity: 0.9;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.recent-post .post h6 {
  position: relative;
  color: #202124;
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 4px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.recent-post .post h6 a {
  color: inherit;
}

.recent-post .post h6:hover {
  color: #202124;
}

.recent-post .post .post-info {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: rgba(68, 68, 68, 0.8);
  font-weight: 400;
}

.tag-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tag-list li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tag-list a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
  font-weight: 400;
  text-align: center;
  background: #ffffff;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.tag-list a:hover {
  color: #ffffff;
  background: var(--talaneo);
}

/*** 

====================================================================
Blog Single
====================================================================

***/

.blog-single {
  position: relative;
  padding: 80px 0 100px;
}

.blog-single .upper-box {
  position: relative;
  margin-bottom: 50px;
}

.blog-single .auto-container {
  max-width: 860px;
}

.blog-single .upper-box h3 {
  position: relative;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #202124;
  margin-bottom: 20px;
}

.blog-single .post-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-single .post-info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  padding-left: 15px;
  margin-right: 10px;
}

.blog-single .post-info li:before {
  position: absolute;
  left: 0;
  top: 9px;
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background: #696969;
  content: "";
}

.blog-single .post-info li:first-child {
  padding-left: 0;
}

.blog-single .post-info li:first-child:before {
  display: none;
}

.blog-single .post-info .thumb {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.blog-single .main-image {
  position: relative;
  margin-bottom: 0;
}

.blog-single .main-image img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.blog-single .image {
  position: relative;
  margin-bottom: 30px;
}

.blog-single h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 17px;
}

.blog-single p {
  font-size: 15px;
  line-height: 30px;
  color: #77838f;
  margin-bottom: 30px;
}

.blog-single .list-style-four {
  margin-top: 30px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-single .list-style-four li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

/* Blockquote */

.blockquote-style-one {
  position: relative;
  background: var(--talaneo-bg-light);
  border-radius: 8px;
  padding: 40px 60px;
}

.blockquote-style-one p {
  font-size: 15px;
  line-height: 30px;
  color: #202124;
  font-style: italic;
  margin-bottom: 0;
}

.blockquote-style-one cite {
  font-size: 15px;
  color: #696969;
  display: block;
  font-style: normal;
  margin-top: 11px;
}

.blog-single .other-options {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #ecedf2;
  border-bottom: 1px solid #ecedf2;
  padding: 35px 0;
  margin: 45px 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-single .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-single .other-options .tags {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 15px 0;
}

/* Other Options*/

.other-options .tags a {
  position: relative;
  background: var(--talaneo-bg-light);
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  padding: 5px 20px;
  border-radius: 4px;
  margin-right: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.other-options .tags a:hover {
  background: var(--talaneo);
  color: #ffffff;
}

/* Post Control */

.post-control {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ecedf2;
  margin-bottom: 50px;
}

.post-control .prev-post,
.post-control .next-post {
  position: relative;
  margin-bottom: 30px;
}

.post-control .next-post {
  text-align: right;
}

.post-control .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 9px;
  line-height: 24px;
  color: var(--talaneo);
  font-weight: 700;
}

.post-control .next-post .icon {
  left: auto;
  right: 0;
}

.post-control .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: var(--talaneo);
  padding-left: 16px;
}

.post-control .next-post .title {
  padding-left: 0;
  padding-right: 16px;
}

.post-control h5 {
  position: relative;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #202124;
  font-weight: 500;
}

.post-control h5 a {
  color: #202124;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.post-control h5 a:hover {
  color: var(--talaneo);
}

/*=========================
Comments Area
=========================*/

.comments-area {
  position: relative;
  margin-bottom: 50px;
}

.comments-area h4 {
  margin-bottom: 35px;
}

.comment-box {
  position: relative;
  margin-bottom: 30px;
}

.comment-box .comment {
  position: relative;
  padding-left: 110px;
  margin-bottom: 50px;
}

.comment-box .comment.reply-comment {
  margin-left: 110px;
}

.comment-box .comment.reply-comment:last-child {
  border-bottom: 0;
}

.comment-box .user-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 80px;
  width: 80px;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.comment-box .author-thumb img {
  display: block;
  width: 100%;
}

.comment-box .comment-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-box .user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
}

.comment-box .title {
  font-size: 14px;
  line-height: 19px;
  color: #696969;
}

.comment-box .text {
  font-size: 15px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 20px;
}

.comment-box .rating {
  position: absolute;
  right: 0;
  top: 8px;
  font-size: 13px;
  color: #ffc78b;
}

.comment-form {
  position: relative;
  padding: 50px 50px;
  border: 1px solid #ecedf2;
}

/*** 

====================================================================
Products Section
====================================================================

***/

.products-section {
  position: relative;
  padding: 100px 0 70px;
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block .inner-box {
  position: relative;
  overflow: hidden;
  padding: 25px 30px 25px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.product-block .image-box {
  position: relative;
}

.product-block .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}

.product-block .image img {
  display: inline-block;
  max-width: 100%;
}

.product-block .info {
  position: relative;
}

.product-block h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 7px;
}

.product-block .price {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  display: block;
  color: #696969;
}

.product-block .theme-btn {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.product-block .theme-btn i {
  margin-right: 10px;
}

.product-block .inner-box:hover .theme-btn {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

/*** 

====================================================================
Shop Single
====================================================================

***/

.shop-single {
  position: relative;
  padding: 60px 0;
}

.product-details {
  position: relative;
  margin-bottom: 50px;
}

.product-details .basic-details {
  position: relative;
  margin-bottom: 30px;
}

.product-details .image-column,
.product-details .info-column {
  margin-bottom: 50px;
}

.product-details .image-column .image {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  padding: 70px 30px;
  border: 1px solid #ecedf2;
  text-align: center;
}

.product-details .image-column .image img {
  display: inline-block;
}

.product-details .image-column .image .icon {
  position: absolute;
  left: 35px;
  bottom: 35px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #1b2032;
  text-align: center;
  background-color: #f9fafc;
}

.product-details .info-column .inner-column {
  position: relative;
  padding-left: 20px;
}

.product-details .basic-details .product-name {
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  margin-bottom: 15px;
}

.product-details .basic-details .rating {
  position: relative;
  font-size: 14px;
  line-height: 1em;
  color: #5c6770;
  margin-bottom: 25px;
}

.product-details .basic-details .rating .fa {
  font-size: 14px;
  color: #d0d23c;
  margin-right: 2px;
}

.product-details .basic-details .rating .title {
  margin-left: 3px;
}

.product-details .basic-details .text {
  margin-bottom: 20px;
}

.product-details .basic-details .item-price {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 30px;
}

.product-details .basic-details .item-price del {
  font-size: 14px;
  line-height: 19px;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #696969;
  margin-left: 10px;
}

.product-details .other-options {
  position: relative;
}

.product-details .other-options .item-quantity {
  font-size: 14px;
  line-height: 50px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.item-quantity .qty {
  position: relative;
  display: inline-block;
  width: 110px;
  padding: 10px 20px;
  height: 50px;
  line-height: 26px;
  color: #5c6770;
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid #ecedf2;
}

.qty::-webkit-outer-spin-button,
.qty::-webkit-inner-spin-button {
  opacity: 1;
  visibility: visible;
  background: none;
}

.item-quantity .in-stock {
  position: relative;
  padding-left: 25px;
  margin-left: 30px;
}

.item-quantity .in-stock:before {
  position: absolute;
  left: 0;
  top: 0px;
  height: 18px;
  width: 18px;
  background: #696969;
  content: "\f00c";
  border-radius: 10px;
  color: #ffffff;
  font-size: 8px;
  line-height: 18px;
  font-weight: 900;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
}

.product-details .other-options .theme-btn span {
  margin-right: 10px;
}

.product-details .other-options .bookmark-btn {
  padding: 15px 20px;
  margin-left: 20px;
}
.product-details .other-options .bookmark-btn span {
  margin: 0;
}

.product-details .product-meta {
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}

.product-details .product-meta li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 30px;
  color: #696969;
}

.product-details .product-meta li span {
  display: block;
  margin-right: 10px;
  min-width: 90px;
}

/*** 

====================================================================
Product Tabs
====================================================================

***/

.product-info-tabs {
  position: relative;
  max-width: 850px;
  margin: 0 auto;
}

.prod-tabs .tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.prod-tabs .tab-btns .tab-btn {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #202124;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-bottom: 2px solid transparent;
  margin: 0 20px;
}

.prod-tabs .tab-btns .tab-btn.active-btn {
  color: var(--talaneo);
  border-bottom-color: var(--talaneo);
}

.prod-tabs .tabs-content .tab .content {
  position: relative;
  padding: 65px 0 0;
}
.prod-tabs .content h4 {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 20px;
}

.prod-tabs .content p {
  font-size: 15px;
  line-height: 26px;
  color: #696969;
  margin-bottom: 26px;
}

.prod-tabs .content .list-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 500px;
}

.prod-tabs .content .list-style-two li {
  width: 50%;
}

/*** 

====================================================================
Cart Section
====================================================================

***/

.cart-section {
  position: relative;
  padding: 60px 0px 50px;
}

.cart-section .column {
  position: relative;
  margin-bottom: 30px;
}

.cart-outer {
  position: relative;
}

.cart-outer .table-outer {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.cart-outer .cart-table {
  width: 100%;
  min-width: 800px;
}

.default-table tr.cart-item td {
  font-size: 15px;
}

.cart-item .product-thumbnail {
  width: 160px;
}

.cart-item .product-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  text-align: left;
  padding-left: 0;
  width: 200px;
}

.cart-item .product-name a {
  color: #202124;
}

.cart-item .product-remove {
  color: #949da6;
}

/* Cart Option */

.cart-section .cart-options {
  position: relative;
  margin: 30px 0 10px;
}

.cart-section .apply-coupon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-section .apply-coupon .form-group {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}

.cart-section .apply-coupon .input {
  position: relative;
  width: 100%;
  display: block;
  color: #5c6770;
  font-size: 14px;
  line-height: 26px;
  padding: 10px 18px;
  height: 50px;
  min-width: 234px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ecedf2;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.cart-section .pull-right {
  margin-left: auto;
}

.cart-section .totals-table-outer {
  position: relative;
  background: var(--talaneo-bg-light-card);
  padding: 25px 30px 30px;
}

.cart-section .totals-table li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7ec;
  margin-bottom: 15px;
}

.cart-section .totals-table li:first-child,
.cart-section .totals-table li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.cart-section .totals-table li h3 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 30px;
}

.cart-section .totals-table .col {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 16px;
  color: #202124;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0;
}

.cart-section .totals-table .col:last-child {
  text-align: right;
}

.cart-section .totals-table .price {
  font-size: 14px;
  color: #696969;
}

.cart-section .proceed-btn {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 30px;
}

/***

====================================================================
CheckOut Page
====================================================================

***/

.checkout-page {
  position: relative;
  padding: 60px 0px 10px;
}

.checkout-page .column {
  position: relative;
  margin-bottom: 50px;
}

.checkout-page .title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 30px;
}

.checkout-form {
  position: relative;
  background: #ffffff;

  border-radius: 10px;
}

.checkout-form .coupen-outer {
  position: relative;
  text-align: center;
  margin-bottom: 35px;
}

.checkout-form .coupen-outer .text {
  display: block;
  font-size: 15px;
  color: #5c6770;
  font-weight: 400;
  margin-bottom: 25px;
}

.checkout-form .coupen-outer .text a {
  font-weight: 500;
  color: #1b2032;
}

.checkout-form .coupen-outer .form-grou {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout-form .coupen-outer .input {
  position: relative;
  width: 100%;
  display: block;
  color: #5c6770;
  font-size: 14px;
  line-height: 30px;
  padding: 10px 18px;
  height: 54px;
  max-width: 235px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #e6e8ed;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-right: 22px;
}

.checkout-form .default-form .field-label {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  display: block;
  margin-bottom: 10px;
}

.checkout-form .default-form .form-group input {
  margin-bottom: 10px;
}

.checkout-form .default-form .form-group input:last-child {
  margin-bottom: 0;
}

.checkout-form .default-form .form-group textarea {
  height: 200px;
}

.order-box {
  position: relative;
  padding: 30px 30px;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  margin-bottom: 30px;
}

.order-box h3 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 25px;
}

.order-box table {
  width: 100%;
}

.order-box thead th {
  position: relative;
  padding-bottom: 11px;
  border-bottom: 1px solid #e7e7ec;
}

.order-box thead th strong {
  font-weight: 500;
}

.order-box .cart-item td {
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  padding-top: 12px;
  padding-bottom: 12px;
}

.order-box table th:last-child,
.order-box table td:last-child {
  position: relative;
  text-align: right;
}

.order-box .cart-subtotal td {
  padding: 15px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7ec;
}

.order-box .order-total td {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  padding-top: 15px;
}

.payment-box {
  position: relative;
}

.payment-options {
  position: relative;
  padding: 30px 30px;
  background-color: var(--talaneo-bg-light-card);
  border-radius: 10px;
  margin-bottom: 30px;
}

.payment-box .payment-options li {
  position: relative;
  margin-bottom: 35px;
}

.payment-box .payment-options li .radio-option {
  position: relative;
}

.payment-box .payment-options li .radio-option label {
  position: relative;
  display: block;
  padding-left: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #202124;
  text-transform: capitalize;
  cursor: pointer;
}

.payment-box .payment-options li .radio-option label .small-text {
  position: relative;
  display: none;
  text-transform: none;
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
}

.payment-box
  .payment-options
  li
  .radio-option
  input:checked
  + label
  .small-text {
  display: block;
}

.payment-box .payment-options li .radio-option label img {
  position: relative;
  margin-left: 15px;
}

.payment-box .btn-box .theme-btn {
  position: relative;
  display: block;
  width: 100%;
}

/*** 

====================================================================
Order Slip
====================================================================

***/

.order-confirmation {
  position: relative;
  padding: 100px 0 70px;
}

.order-confirmation .auto-container {
  max-width: 770px;
}

.order-confirmation .upper-box {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}

.order-confirmation .upper-box .icon {
  display: inline-block;
  height: 80px;
  width: 80px;
  color: rgba(25, 103, 210, 1);
  background: rgba(25, 103, 210, 0.1);
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  margin-bottom: 13px;
}

.order-confirmation .upper-box h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  margin-bottom: 11px;
}

.order-confirmation .upper-box .text {
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.order-confirmation .order-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 40px 40px 0;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-confirmation .order-info li {
  position: relative;
  margin-right: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  margin-bottom: 40px;
}

.order-confirmation .order-info li:last-child {
  margin-right: 0;
}

.order-confirmation .order-info li strong {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-top: 13px;
}

.order-confirmation .order-box {
  background: none;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  padding: 50px 60px 60px;
}

/*** 

====================================================================
App Section
====================================================================

***/

.app-section {
  padding: 100px 0 50px;
}

.app-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.app-section .image-column .bg-shape {
  position: absolute;
  left: -30px;
  top: 0;
  right: -10px;
  height: 100%;
  /* background-image: url(../assets/images/icons/shape-1.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.app-section .image-column .image {
  position: relative;
  padding-top: 55px;
  margin-bottom: 0;
}

.app-section .content-column {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app-section .content-column .inner-column {
  position: relative;
  padding: 100px 0;
  padding-left: 30px;
}

.app-section .sec-title {
  margin-bottom: 30px;
}

.app-section .sec-title h2 {
  font-size: 46px;
  line-height: 54px;
}

.app-section .download-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.app-section .download-btn a {
  margin-right: 20px;
  margin-bottom: 20px;
}

/*** 

====================================================================
Pricing Section
====================================================================

***/

.pricing-section {
  position: relative;
  padding: 100px 0 70px;
}

.pricing-tabs {
  position: relative;
}

.pricing-tabs .tab-buttons {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.pricing-tabs .tab-buttons h4 {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 15px;
}

.pricing-tabs .tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-tabs .tab-btns:before {
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--talaneo);
  border: 1px solid #e7e7ec;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  border-radius: 30px;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.pricing-tabs .tab-btns li {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: #696969;
  cursor: pointer;
  z-index: 8;
  padding: 0 48px 0;
  min-width: 196px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-btns li:before {
  position: absolute;
  right: -26px;
  height: 20px;
  width: 20px;
  top: 5px;
  background: #ffffff;
  content: "";
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-btns li.active-btn:before {
  right: 6px;
}

.pricing-tabs .tab-btns li:last-child:before {
  display: none;
}

.pricing-table {
  position: relative;
  margin-bottom: 30px;
}

.pricing-table .inner-box {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-table .inner-box:hover,
.pricing-table.tagged .inner-box {
  border: 1px solid var(--talaneo);
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.pricing-table .tag {
  position: absolute;
  right: 30px;
  top: 30px;
  overflow: hidden;
  border-radius: 30px;
  font-size: 13px;
  line-height: 20px;
  color: var(--talaneo);
  padding: 5px 25px;
}

.pricing-table .tag:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--talaneo);
  opacity: 0.15;
  content: "";
  border-radius: 30px;
}

.pricing-table .title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  margin-bottom: 10px;
}

.pricing-table .price {
  font-family: "Jost";
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 50px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-table .duration {
  position: relative;
  font-size: 18px;
  line-height: 1em;
  color: #202124;
  font-weight: 400;
  bottom: 7px;
  margin-left: 7px;
}

.pricing-table .table-content {
  position: relative;
  margin-bottom: 60px;
}

.pricing-table .table-content ul {
  position: relative;
}

.pricing-table .table-content ul li {
  position: relative;
  font-size: 14px;
  line-height: 25px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 30px;
}

.pricing-table .table-content ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: Flaticon;
  font-size: 16px;
  color: #202124;
  content: "\f172";
}

.pricing-table .table-footer {
  position: relative;
}

.pricing-table .table-footer a {
  position: relative;
  display: block;
  width: 100%;
}

/*** 

====================================================================
Process Section
====================================================================

***/

.process-section {
  position: relative;
  padding: 100px 0;
}

.process-block {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.process-block .icon-box {
  position: relative;
  display: inline-block;
  padding-top: 56px;
  min-height: 113px;
  margin-bottom: 30px;
}

.process-block .icon-box:before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  height: 113px;
  width: 105px;
  /* background-image: url(../assets/images/icons/shape-3.png); */
  z-index: -1;
  content: "";
}

.process-block .icon-box img {
  display: block;
}

.process-block h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
}



/*** 

====================================================================
Call To Action Two
====================================================================

***/

.call-to-action-two {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 170px 0 160px;
  text-align: center;
}

.call-to-action-two .sec-title {
  margin-bottom: 33px;
}

.call-to-action-two .btn-box {
  position: relative;
  display: inline-block;
}

.call-to-action-two .theme-btn {
  margin: 0 10px 10px;
}

.call-to-action-two .btn-style-two {
  color: #ffffff;
}

.call-to-action-two.style-two {
  position: relative;
}

.call-to-action-two.style-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--talaneo);
  content: "";
  opacity: 0.85;
}

/*** 

====================================================================
Call To Action Three
====================================================================

***/

.call-to-action-three {
  position: relative;
  padding: 60px 0;
  background-color: #deebff;
}

.call-to-action-three .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.call-to-action-three .sec-title {
  margin-bottom: 0;
  margin-right: 30px;
}

.call-to-action-three.style-two {
  background: var(--talaneo);
}

.call-to-action-three.style-two .sec-title .text a {
  color: #ffffff;
}

.call-to-action-three.style-two .theme-btn {
  min-width: 180px;
}



/***

==================================================================
Browse Jobs
==================================================================

***/

.browse-jobs {
  position: relative;
  padding: 100px 0 70px;
}

.browse-jobs .column {
  position: relative;
  margin-bottom: 30px;
}

.browse-jobs h4 {
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 21px;
}

.links-list {
  position: relative;
}

.links-list li {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 30px;
  color: #696969;
}

.links-list li a {
  display: inline-block;
  color: #696969;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.links-list li:before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 0px;
  margin-top: -1px;
  content: "";
  border-bottom: 2px solid var(--talaneo);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.links-list li:hover:before {
  width: 15px;
}

.links-list li:hover a {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  color: var(--talaneo);
}

/*** 

====================================================================
Subscribe Section
====================================================================

***/

.subscribe-section {
  position: relative;
  padding: 60px 0;
  background-color: rgba(52, 168, 83, 0.15);
}

.subscribe-section .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subscribe-section .sec-title {
  margin-bottom: 0;
  margin-right: 30px;
}

.subscribe-section .form-column {
  position: relative;
  max-width: 490px;
  width: 100%;
}

.subscribe-form {
  position: relative;
}

.subscribe-form .form-group {
  position: relative;
  margin-bottom: 0;
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="email"] {
  display: block;
  height: 70px;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  background: #ffffff;
  padding: 20px 30px;
  padding-right: 60px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.subscribe-form .form-group .theme-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  max-width: 120px;
  font-size: 15px;
}

.subscribe-form .response {
  position: relative;
}

.subscribe-form .response > div {
  padding: 0 15px;
  text-align: center;
}

.subscribe-form .response .success {
  background: #28a745;
  color: #ffffff;
}

.subscribe-form .response .failed {
  background-color: #dc3545;
  color: #ffffff;
}

.subscribe-form .response .text-info {
  background-color: #ffd658;
  color: #222222 !important;
}

/*** 

====================================================================
Subscribe Section Two
====================================================================

***/

.subscribe-section-two {
  position: relative;
  padding: 135px 0 148px;
}

.subscribe-section-two .sec-title h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
}

.subscribe-section-two .background-image {
  position: absolute;
  left: 60px;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 30px 0 0 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.subscribe-section-two .subscribe-form {
  position: relative;
  max-width: 490px;
  width: 100%;
  margin: 0 auto;
}

/***

==================================================================
Faqs Section
==================================================================

***/

.faqs-section {
  position: relative;
  padding: 100px 0;
}

.faqs-section h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 12px;
}

.faqs-section .accordion-box {
  margin-bottom: 60px;
}

/***

==================================================================
TNC Section
==================================================================

***/

.tnc-section {
  position: relative;
  padding: 100px 0 50px;
}

.tnc-section .auto-container {
  max-width: 880px;
}

.tnc-section .text-box {
  position: relative;
  margin-bottom: 50px;
}

.tnc-section h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 23px;
}

.tnc-section p {
  font-size: 15px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 24px;
}

/***

==================================================================
Map Section
==================================================================

***/

.map-section {
  position: relative;
}

.map-section img{
width: 100%;
}

.map-area {
  position: relative;
}

.map-data {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.map-data a {
  display: block;
}

.map-data h6 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  color: #121212;
}

.map-canvas {
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
}

/***

==================================================================
Contact Section
==================================================================

***/

.contact-section {
  position: relative;
  padding: 100px 0;
}

.contact-section .upper-box {
  position: relative;
  padding: 90px 200px 70px;
  margin-top: -300px;
  z-index: 1;
  background: #ffffff;
}

.contact-block {
  position: relative;
  margin-bottom: 30px;
}

.contact-block .inner-box {
  position: relative;
}

.contact-block .icon {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

.contact-block h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 20px;
}

.contact-block p {
  font-size: 15px;
  line-height: 26px;
  color: #696969;
}

.contact-block p a {
  color: #696969;
}

.contact-block p .phone {
  font-size: 18px;
  color: var(--talaneo);
}

.contact-section .contact-form {
  max-width: 850px;
  margin: 0 auto;
  border: 1px solid #ecedf2;
  border-radius: 8px;
  padding: 50px 50px 70px;
}

.contact-section .contact-form h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 15px;
}

.contact-section .contact-form .theme-btn {
  max-width: 330px;
  width: 100%;
}

/***

==================================================================
Login Section
==================================================================

***/

.login-section {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 120px 0 50px;
  overflow: auto;
}

.login-section .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 42%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-section .outer-box {
  position: relative;
  margin-left: 42%;
  width: 58%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-section .login-form {
  margin: 0 auto;
  max-width: 610px;
  width: 100%;
  padding: 0 30px;
}

.login-form h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #202124;
  margin-bottom: 32px;
}

.login-form .field-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.login-form .field-outer label {
  margin-bottom: 0;
}

.login-form .field-outer .pwd {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #202124;
  white-space: nowrap;
}

.login-form .form-group .theme-btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.login-form .bottom-box {
  position: relative;
  padding-top: 12px;
  text-align: center;
}

.login-form .text {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  text-align: center;
  margin-top: 5px;
}

.login-form .text a {
  color: #696969;
  font-weight: 500;
}

.login-form .bottom-box .divider {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  background: #ffffff;
  margin: 10px 0 25px;
}

.login-form .bottom-box .divider span {
  position: relative;
  z-index: 9;
}

.login-form .bottom-box .divider:before {
  position: absolute;
  left: 0;
  top: 10px;
  height: 1px;
  width: 100%;
  background: #ecedf2;
  border-radius: 8px;
  content: "";
}

.login-form .bottom-box .divider:after {
  position: absolute;
  left: 50%;
  top: 0px;
  height: 20px;
  width: 60px;
  background: #fff;
  margin-left: -30px;
  content: "";
}

.login-form .bottom-box .btn-box {
  position: relative;
}

.social-btn-two {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  color: inherit;
  font-size: 14px;
  border-radius: 8px;
  background-color: inherit;
  border: 1px solid #fff;
  font-weight: 500;
  padding: 10px 30px;
  color: var(--talaneo);
  line-height: 25px;
  margin-bottom: 20px;
}

.social-btn-two i {
  margin-right: 10px;
  font-size: 15px;
}

.social-btn-two.facebook-btn {
  border-color: #3b5998;
  color: #3b5998;
}

.social-btn-two.facebook-btn:hover {
  background-color: #3b5998;
  color: #ffffff;
}

.social-btn-two.google-btn {
  border-color: #dc4d28;
  color: #dc4d28;
}

.social-btn-two.google-btn:hover {
  background-color: #dc4d28;
  color: #ffffff;
}

/***

==================================================================
Invoice Section
==================================================================

***/

.invoice-section {
  position: relative;
  background: var(--talaneo-bg-light);
  padding: 75px 0;
  min-height: 100vh;
}

.invoice-section .auto-container {
  max-width: 1200px;
}

.invoice-section .upper-box {
  position: relative;
  text-align: right;
  margin-bottom: 75px;
}

.invoice-section .upper-box .theme-btn {
  background: rgba(25, 103, 210, 0.15);
  min-width: 140px;
}

.invoice-wrap {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.invoice-wrap .invoice-content {
  position: relative;
  padding: 125px 100px;
}

.invoice-wrap .logo-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.invoice-wrap .logo-box .logo {
  position: relative;
  margin-right: 30px;
  margin-bottom: 40px;
}

.invoice-wrap .invoice-id {
  position: relative;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 40px;
  max-width: 325px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.invoice-wrap .invoice-id span {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  min-width: 155px;
  color: #202124;
  margin-left: 40px;
}

.invoice-wrap .info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.invoice-wrap .info-box .right-column {
  position: relative;
  max-width: 325px;
  width: 100%;
  padding-right: 30px;
}

.invoice-wrap .info-box .left-column {
  padding-right: 30px;
}

.invoice-wrap .info {
  position: relative;
  margin-bottom: 70px;
}

.invoice-wrap .info h6 {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.invoice-wrap .info span {
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  font-weight: 500;
}

.invoice-wrap .info h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 20px;
}

.invoice-wrap .info h5 {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  margin-bottom: 14px;
}

.invoice-table tr th:last-child,
.invoice-table tr td:last-child {
  text-align: right;
}

.invoice-wrap .invoice-footer {
  position: relative;
  padding: 48px 50px 38px;
  border-top: 1px solid #ecedf2;
}

.invoice-wrap .bottom-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.invoice-wrap .bottom-links li {
  position: relative;
  margin: 0 30px 10px;
  font-size: 15px;
  line-height: 20px;
}

.invoice-wrap .bottom-links li a {
  color: #202124;
}

.invoice-wrap .bottom-links li .link {
  color: var(--talaneo);
}

/***

==================================================================
Main Footer
==================================================================

***/

.main-footer {
  position: relative;
  background-color: #ffffff;
}

.main-footer.alternate {
  background: var(--talaneo-bg-light-card);
}

.main-footer.alternate5 {
  border-top: 1px solid #ecedf2;
}

/*=== Widgets Section ===*/

.main-footer .widgets-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
}

.main-footer .footer-column {
  position: relative;
  margin-bottom: 50px;
}

.main-footer .footer-widget {
  position: relative;
}

.main-footer .widget-title {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #2f2d51;
  line-height: 1.4em;
  margin-bottom: 30px;
}

.main-footer .logo {
  position: relative;
  margin-bottom: 25px;
}

.main-footer .logo img {
  display: block;
}

.main-footer .phone-num {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 15px;
}

.main-footer .phone-num span {
  position: relative;
  display: block;
}

.main-footer .phone-num a {
  color: var(--talaneo);
}

.main-footer .address {
  font-size: 14px;
  line-height: 30px;
  color: #696969;
  font-weight: 400;
}

.main-footer .address a {
  color: #696969;
}

/*=== Cities Widget ===*/

.main-footer .links-widget {
  position: relative;
  padding-left: 20px;
}

.main-footer .list {
  position: relative;
}

.main-footer .list li {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 15px;
}

.main-footer .list li a {
  display: inline-block;
  color: #696969;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.main-footer .list li:before {
  position: absolute;
  left: 0;
  top: 10px;
  height: 2px;
  width: 0px;
  content: "";
  border-bottom: 2px solid var(--talaneo);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .list li:hover:before {
  width: 15px;
}

.main-footer .list li:hover a {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  color: var(--talaneo);
}

/*=== Social Widget ===*/

.main-footer .footer-bottom {
  position: relative;
  width: 100%;
  padding: 36px 0;
  text-align: center;
  border-top: 1px solid #ecedf2;
  min-height: 100px;
}

.main-footer .footer-bottom .bottom-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer .footer-bottom .logo {
  margin-bottom: 0;
  margin-right: 28px;
}

.main-footer .copyright-text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #696969;
  padding: 0;
}

.main-footer .copyright-text a {
  display: inline-block;
  color: #696969;
  line-height: 30px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .copyright-text a:hover {
  text-decoration: underline;
  color: #9db1bc;
}

.main-footer .footer-bottom .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer .social-links a {
  position: relative;
  margin-left: 35px;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .social-links a:hover {
  color: #bc91e8;
}

/*=== Newsletter form ===*/

.newsletter-form {
  position: relative;
}

.newsletter-form h4 {
  position: relative;
}

.newsletter-form .text {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
}

.newsletter-form .form-group {
  position: relative;
}

.newsletter-form .form-group input[type="text"],
.newsletter-form .form-group input[type="email"] {
  display: block;
  height: 60px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  background: #ffffff;
  padding: 15px 20px;
  padding-right: 60px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.newsletter-form .form-group .theme-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  line-height: 40px;
  height: 40px;
  background: #f9ab00;
  color: #ffffff;
  text-align: center;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.newsletter-form .response {
  position: relative;
}

.newsletter-form .response > div {
  padding: 0 15px;
  text-align: center;
}

.newsletter-form .response .success {
  background: #28a745;
  color: #ffffff;
}

.newsletter-form .response .failed {
  background-color: #dc3545;
  color: #ffffff;
}

.newsletter-form .response .text-info {
  background-color: #ffd658;
  color: #222222 !important;
}

.main-footer.alternate2 .footer-bottom {
  position: relative;
  padding: 100px 0;
}

.main-footer.alternate3 {
  padding-top: 90px;
  background: none;
}

.main-footer.alternate3:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../assets/images/background/6.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}

.main-footer.alternate4 .footer-bottom {
  padding: 116px 0;
  border: 0;
}

/***

==================================================================
Main Footer / Style Two
==================================================================

***/

.main-footer.style-two {
  background-color: #302ea7;
}

.main-footer.style-two.alternate {
  background-color: #555555;
}

.main-footer.style-two .widgets-section {
  padding: 100px 0 20px;
}

.main-footer.style-two .widget-title {
  color: #ffffff;
}

.main-footer.style-two .copyright-text,
.main-footer.style-two .copyright-text a,
.main-footer.style-two .social-links a,
.main-footer.style-two .newsletter-form .text,
.main-footer.style-two .list li a,
.main-footer.style-two .address,
.main-footer.style-two .address a,
.main-footer.style-two .phone-num,
.main-footer.style-two .phone-num a {
  color: #deebff;
}

.main-footer.style-two .social-links a:hover {
  color: #f9ab00;
}

.main-footer.style-two .d-flex .list:first-child {
  margin-right: 80px;
}

.main-footer.style-two .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.main-footer.style-two .list li:before {
  border-color: #ffffff;
}

/***

==================================================================
Main Footer / Style Three
==================================================================

***/

.main-footer.style-three {
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.main-footer.style-three .widgets-section {
  padding: 180px 0 20px;
}

.main-footer.style-three .widget-title {
  color: #ffffff;
}

.main-footer.style-three .copyright-text,
.main-footer.style-three .copyright-text a,
.main-footer.style-three .social-links a,
.main-footer.style-three .newsletter-form .text,
.main-footer.style-three .list li a,
.main-footer.style-three .address,
.main-footer.style-three .address a,
.main-footer.style-three .phone-num,
.main-footer.style-three .phone-num a {
  color: #deebff;
}

.main-footer.style-three .social-links a:hover {
  color: #f9ab00;
}

.main-footer.style-three .d-flex .list:first-child {
  margin-right: 80px;
}

.main-footer.style-three .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.main-footer.style-three .list li:before {
  border-color: #ffffff;
}

.main-footer.style-three .newsletter-form {
  position: relative;
  max-width: 492px;
  margin: 0 auto 85px;
}

.main-footer.style-three .download-btns {
  position: relative;
  margin-bottom: 20px;
}

.main-footer.style-three .download-btns .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 15px;
}

.download-btns a {
  position: relative;
}

.app-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  padding: 9px 22px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.app-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.app-btn .app-icon {
  position: relative;
  font-size: 28px;
  width: 26px;
  color: #ffffff;
}

.app-btn .inner {
  position: relative;
  padding-left: 20px;
}

.app-btn .sub {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.app-btn .name-app {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.main-footer.style-three .footer-bottom {
  min-height: auto;
  padding: 30px 0;
}

.main-footer.style-three .newsletter-form .form-group input[type="text"],
.main-footer.style-three .newsletter-form .form-group input[type="email"] {
  border-radius: 50px;
  padding: 15px 28px;
  padding-right: 140px;
  line-height: 30px;
  height: 70px;
  font-size: 15px;
  line-height: 20px;
  color: var(--talaneo);
}

.main-footer.style-three .newsletter-form .form-group .theme-btn {
  height: 50px;
  padding: 10px 30px;
  width: auto;
  border-radius: 50px;
}

/***

==================================================================
Main Footer / Style Four
==================================================================

***/

.main-footer.style-four {
  background-color: #555555;
}

.main-footer.style-four .widgets-section {
  padding: 100px 0 20px;
}

.main-footer.style-four .social-links a,
.main-footer.style-four .widget-title {
  color: #ffffff;
}

.main-footer.style-four .copyright-text,
.main-footer.style-four .copyright-text a,
.main-footer.style-two .social-links a,
.main-footer.style-four .newsletter-form .text,
.main-footer.style-four .list li a,
.main-footer.style-four .address,
.main-footer.style-four .address a,
.main-footer.style-four .phone-num,
.main-footer.style-four .phone-num a {
  color: #ffffff;
}

.main-footer.style-four .social-links a:hover {
  color: #ffffff;
}

.main-footer.style-four .d-flex .list:first-child {
  margin-right: 80px;
}

.main-footer.style-four .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.main-footer.style-four .list li:before {
  border-color: #ffffff;
}

/***

==================================================================
Main Footer / Style Five
==================================================================

***/

.main-footer.style-five {
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.main-footer.style-five .newsletter-form {
  position: relative;
  padding: 140px 0 150px;
  border-bottom: 1px solid #ecedf2;
}

.main-footer.style-five .newsletter-form .sec-title {
  max-width: 490px;
  margin: 0 auto 35px;
}

.main-footer.style-five .newsletter-form .sec-title .text {
  font-size: 15px;
  line-height: 25px;
}

.main-footer.style-five .newsletter-form form {
  position: relative;
  max-width: 492px;
  margin: 0 auto 0;
}

.main-footer.style-five .newsletter-form .form-group {
  margin-bottom: 0;
}

.main-footer.style-five .newsletter-form .form-group input[type="text"],
.main-footer.style-five .newsletter-form .form-group input[type="email"] {
  border-radius: 8px;
  padding-right: 140px;
  height: 70px;
  padding: 15px 28px;
  line-height: 30px;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.main-footer.style-five .newsletter-form .form-group .theme-btn {
  right: 10px;
  height: 50px;
  padding: 10px 30px;
  width: auto;
  background: var(--talaneo);
}

.main-footer.style-five .download-btns {
  position: relative;
  margin-bottom: 0;
}

.main-footer.style-five .download-btns .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 25px;
  color: #696969;
  margin-bottom: 15px;
}

.main-footer.style-five .download-btns a {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.main-footer.style-five .download-btns a:last-child {
  margin-bottom: 0;
}

.main-footer.style-five .footer-bottom {
  min-height: auto;
  padding: 30px 0;
}

/***

==================================================================
Main Footer / Style Six
==================================================================

***/

.main-footer.style-six {
  background-color: #2f333c;
}

.main-footer.style-six .widgets-section {
  padding: 100px 0 50px;
}

.main-footer.style-six .phone-num,
.main-footer.style-six .phone-num a,
.main-footer.style-six .widget-title {
  color: #ffffff;
}

.main-footer.style-six .copyright-text,
.main-footer.style-six .copyright-text a,
.main-footer.style-six .social-links a,
.main-footer.style-six .newsletter-form .text,
.main-footer.style-six .list li a,
.main-footer.style-six .address,
.main-footer.style-six .address a {
  color: #cad1d8;
}

.main-footer.style-six .social-links a:hover {
  color: #cad1d8;
}

.main-footer.style-six .d-flex .list:first-child {
  margin-right: 80px;
}

.main-footer.style-six .footer-bottom {
  padding: 36px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.main-footer.style-six .list li:before {
  border-color: #ffffff;
}

.main-footer.style-six .links-widget {
  padding-left: 0;
}

.main-footer.style-six .newsletter-form .form-group .theme-btn {
  background: var(--talaneo);
}

.main-footer.style-six.alternate {
  background-color: #262c3f;
}

.main-footer.style-six.alternate .newsletter-form .form-group .theme-btn {
  background: #d93025;
}

.copyright-text {
  position: relative;
  text-align: center;
  padding: 30px 0 50px;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
}

/*** 

====================================================================
Dashboard
====================================================================

***/

.dashboard {
  position: relative;
  background-color: var(--talaneo-bg-light-card);
  padding-left: 365px;
  min-height: 100vh;
}

.dashboard .main-header {
  background: #ffffff;
}

.dashboard .dashboard-outer {
  position: relative;
  padding: 60px 60px 0;
}

.dashboard .upper-title-box {
  position: relative;
  margin-bottom: 60px;
}

.dashboard .upper-title-box h3 {
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  margin-bottom: 10px;
}

.ui-item {
  position: relative;
  height: 130px;
  width: 100%;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
}

.ui-item .right {
  text-align: right;
}

.ui-item h4 {
  font-size: 30px;
  color: var(--talaneo);
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 3px;
}

.ui-item p {
  font-size: 15px;
  color: #202124;
  line-height: 26px;
}

.ui-item .icon {
  position: relative;
  display: block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  font-size: 40px;
  color: rgba(25, 103, 210, 1);
  text-align: center;
  background-color: rgba(25, 103, 210, 0.1);
  border-radius: 8px;
}

.ui-item.ui-red h4 {
  color: rgba(217, 48, 37, 1);
}
.ui-item.ui-red .icon {
  color: rgba(217, 48, 37, 1);
  background: rgba(217, 48, 37, 0.1);
}

.ui-item.ui-yellow h4 {
  color: rgba(249, 171, 0, 1);
}
.ui-item.ui-yellow .icon {
  color: rgba(249, 171, 0, 1);
  background: rgba(249, 171, 0, 0.1);
}

.ui-item.ui-green h4 {
  color: rgba(52, 168, 83, 1);
}
.ui-item.ui-green .icon {
  color: rgba(52, 168, 83, 1);
  background: rgba(52, 168, 83, 0.1);
}

/*============================
Graph widget
============================*/

.graph-widget .widget-content {
  padding-top: 80px;
}

.graph-widget .tab-buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.graph-widget .tab-buttons li {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 18px;
  color: #5c6770;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.graph-widget .tab-buttons li:hover,
.graph-widget .tab-buttons li.active-btn {
  background: #f9fafc;
}

.graph-widget .graph-img {
  position: relative;
  padding-top: 30px;
  margin-bottom: 20px;
}

.graph-widget .graph-img img {
  width: 100%;
  display: block;
}

/*============================
Form Widget
============================*/

.form-widget {
  position: relative;
}

.form-widget .default-form .form-group {
  margin-bottom: 12px;
}

.form-widget .default-form .form-group button {
  margin-top: 8px;
}

.form-widget .default-form .form-group:last-child {
  margin-bottom: 20px;
}

.form-widget .uploading-outer {
  margin-bottom: 20px;
}

/*============================
Uploading Widget
============================*/

.uploading-widget .widget-content {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.uploading-widget .widget-content:last-child {
  padding-bottom: 30px;
  margin-bottom: 0;
  border-bottom: 0;
}

.uploading-widget .uploading-outer {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.uploading-widget .bottom-text {
  position: relative;
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #5c6770;
}

/*============================
Listing Filters
============================*/

.listing-filters {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 5px;
  padding: 0 30px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  margin-bottom: 30px;
}

.listing-filters .search-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 0;
}

.search-form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-form input[type="text"],
.search-form input[type="search"] {
  position: relative;
  height: 54px;
  min-width: 305px;
  padding: 10px 18px;
  padding-right: 70px;
  background: transparent;
  font-size: 14px;
  color: #5c6770;
  line-height: 30px;
  border: 2px solid #e6e8ed;
  border-radius: 5px 5px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-form .search-btn {
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
  height: 54px;
  width: 54px;
  text-align: center;
  line-height: 54px;
  color: #e6e8ed;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.listing-filters .sort-by {
  margin-left: 30px;
}

.listing-filters .filters-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.listing-filters .filters-list li {
  position: relative;
  margin-right: 10px;
}

.listing-filters .filters-list li a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #5c6770;
  font-weight: 400;
  padding: 40px 20px;
  padding-left: 55px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.listing-filters .filters-list li a .icon {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 20px;
  color: #9fa9b8;
  line-height: 30px;
}

.listing-filters .filters-list li:hover a,
.listing-filters .filters-list li.active a {
  color: #021f4a;
  border-color: #021f4a;
}

.my-listing-widget .listing-block-seven {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid #f1f3f7;
}

.my-listing-widget .listing-block-seven:last-child {
  margin-bottom: 20px;
  padding-bottom: 0;
  border-bottom: 0;
}

/*====================================
Listing Block Seven
====================================*/

.listing-block-seven {
  position: relative;
  margin-bottom: 30px;
}

.listing-block-seven .inner-box {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.listing-block-seven .image-box {
  position: relative;
  margin-right: 30px;
}

.listing-block-seven .image {
  position: relative;
  display: block;
  margin-bottom: 0;
  height: 150px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.listing-block-seven .image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.listing-block-seven .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(rgba(27, 32, 50, 1))
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(27, 32, 50, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(27, 32, 50, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#1b2032',GradientType=0 );
  z-index: 1;
  opacity: 0.8;
  content: "";
}

.listing-block-seven .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.listing-block-seven .content-box {
  position: relative;
  width: 100%;
}

.listing-block-seven .content-box .upper-box {
  position: relative;
  padding: 20px 40px 10px;
  z-index: 2;
  width: 100%;
}

.listing-block-seven .rating {
  position: relative;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1em;
  color: #5c6770;
}

.listing-block-seven .fa {
  font-size: 11px;
  color: #ffa737;
}

.listing-block-seven .title {
  margin-left: 3px;
}

.listing-block-seven h3 {
  position: relative;
  font-size: 18px;
  line-height: 1.2em;
  color: #1b2032;
  font-weight: 600;
  margin-bottom: 5px;
}

.listing-block-seven h3 a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1b2032;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.listing-block-seven h3 a:hover {
  color: #ffa737;
}

.listing-block-seven h3 .icon {
  margin-left: 10px;
}

.listing-block-seven .text {
  position: relative;
  margin-bottom: 10px;
}

.listing-block-seven .info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.listing-block-seven .info li {
  position: relative;
  color: #5c6770;
  margin-right: 20px;
}

.listing-block-seven .info li span {
  margin-right: 7px;
  font-size: 18px;
}

.listing-block-seven .places {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.listing-block-seven .place {
  position: relative;
  color: #4cbfd8;
  font-size: 14px;
}

.listing-block-seven .place .icon {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  background-color: #4cbfd8;
  margin-right: 7px;
}

.listing-block-seven .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.listing-block-seven .btn-box .theme-btn {
  margin: 9px 0;
  margin-left: 18px;
}

.listing-block-seven .btn-box .theme-btn:first-child {
  margin-left: 0;
}

/*** 

====================================================================
User Sidebar
====================================================================

***/

.user-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  height: calc(100%);
  width: 365px;
  padding-top: 80px;
  background: #ffffff;
  overflow: auto;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 9;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.user-sidebar::-webkit-scrollbar {
  width: 4px;
}
.user-sidebar::-webkit-scrollbar-track {
  background: transparent;
}
.user-sidebar::-webkit-scrollbar-thumb {
  background: var(--talaneo-bg-light);
  border-radius: 10px;
}

.user-sidebar-active .user-sidebar {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.sidebar-backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 98;
  opacity: 0;
  visibility: hidden;
}

.sidebar-inner {
  position: relative;
  padding: 60px 60px;
}

.user-sidebar .sidebar-header {
  position: relative;
  padding: 30px 30px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 230px;
  border-bottom: 1px solid #f1f3f7;
}

.user-sidebar .sidebar-header .user-image {
  position: relative;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  border: 5px solid #dddee0;
  overflow: hidden;
  margin-bottom: 15px;
}

.user-sidebar .sidebar-header .user-image img {
  width: 100%;
  display: block;
}

.user-sidebar .sidebar-header .user-name {
  position: relative;
  font-size: 15px;
  line-height: 18px;
  color: #1b2032;
  font-weight: 700;
}

.sidebar-inner .navigation {
  position: relative;
  left: 0;
  width: 100%;
  margin: 0px;
}

.sidebar-inner .navigation li {
  position: relative;
  width: 100%;
}

.sidebar-inner .navigation li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #696969;
  text-align: left;
  text-transform: capitalize;
  border-radius: 8px;
  /* -webkit-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease; */
}

.sidebar-inner .navigation li a i {
  position: relative;
  margin-right: 15px;
  font-size: 22px;
  color: #696969;
  text-align: center;
  /* -webkit-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease; */
}

.place.sky {
  color: #4cbfd8;
}
.place.sky .icon {
  background-color: #4cbfd8;
}

.place.pink {
  color: #ff8480;
}
.place.pink .icon {
  background-color: #ff8480;
}

.place.purple {
  color: #8c689d;
}
.place.purple .icon {
  background-color: #8c689d;
}

.place.green {
  color: #35c2a5;
}
.place.green .icon {
  background-color: #35c2a5;
}

.place.dark-pink {
  color: #ff007a;
}
.place.dark-pink .icon {
  background-color: #ff007a;
}

.place.orange {
  color: #ffb874;
}
.place.orange .icon {
  background-color: #ffb874;
}

.place.purple {
  color: #8c689d;
}
.place.purple .icon {
  background-color: #8c689d;
}

.skills-percentage {
  position: relative;
  margin-top: 55px;
}

.skills-percentage h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 20px;
}

.skills-percentage p {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 25px;
}

.skills-percentage .pie-graph {
  position: relative;
  margin-bottom: 0;
}

.skills-percentage .graph-outer:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 234px;
  width: 234px;
  border: 30px solid #ecedf2;
  content: "";
  border-radius: 50%;
  z-index: -1;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

/*** 

====================================================================
Chat Widget
====================================================================

***/

.chat-widget .widget-content {
  padding: 0;
}

.chat-widget .card-header {
  padding: 40px 40px 0;
  background: none;
  border-radius: 0;
  border: 0;
}

.chat-widget .search-box-one .form-group input[type="text"],
.chat-widget .search-box-one .form-group input[type="search"] {
  width: 100%;
}

.chat {
  margin-top: auto;
  margin-bottom: auto;
}

.card {
  height: 770px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.card ::-webkit-scrollbar {
  width: 4px;
}
.card ::-webkit-scrollbar-track {
  background: transparent;
}
.card ::-webkit-scrollbar-thumb {
  background: var(--talaneo-bg-light);
  border-radius: 10px;
}

.contacts_body {
  padding: 15px 0px;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  position: relative;
  overflow-y: auto;
  padding: 40px 40px 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.msg_card_body .d-flex {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.msg_card_body .justify-content-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat_history {
  position: absolute;
  left: 0;
  top: 0px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #5c6770;
  background: #fff;
  padding: 30px 0;
}

.msg_card_body .mb-3 {
  margin-bottom: 30px !important;
}

.card-footer {
  padding: 30px 40px 40px;
  border-radius: 0 !important;
  background: none;
  border-top: 1px solid #ecedf2;
}

.card-footer .form-group {
  position: relative;
}

.card-footer .form-group button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.type_msg {
  background-color: transparent !important;
  border: 0 !important;
  color: white !important;
  height: 50px !important;
  overflow-y: auto;
  font-size: 14px;
  color: #696969 !important;
  line-height: 20px;
  padding: 15px 30px;
}
.type_msg:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts li {
  width: 100% !important;
}

.contacts li:last-child {
  margin-bottom: 0;
}

.contacts li a {
  position: relative;
  display: block;
  padding: 16px 40px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contacts li.active a,
.contacts li:hover a {
  background: #f9fafc;
}

.user_img {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
}
.user_img_msg {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
}
.img_cont {
  position: relative;
  height: 50px;
  width: 50px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.img_cont_msg {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.img_cont_msg img {
  height: 50px;
  width: 50px;
}

.img_cont_msg .name {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-left: 10px;
}

.img_cont_msg .msg_time {
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #696969;
  margin-left: 10px;
}

.reply .img_cont_msg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.reply .img_cont_msg .msg_time,
.reply .img_cont_msg .name {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-right: 10px;
}

.online_icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 13px;
  width: 13px;
  background-color: #00d664;
  border-radius: 50%;
  border: 2px solid #fafafa;
}
.offline {
  background-color: #ff0000 !important;
}
.user_info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 15px;
}
.user_info span {
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 3px;
}
.user_info p {
  font-size: 14px !important;
  color: #5c6770 !important;
  line-height: 1.4em !important;
  margin-bottom: 0 !important;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.bd-highlight .info {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.bd-highlight .info .count {
  position: relative;
  display: block;
  width: 16.02px;
  height: 16px;
  background: var(--talaneo);
  border-radius: 50%;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  margin-top: 2px;
}

.msg_cotainer {
  position: relative;
  display: block;
  width: auto;
  background: var(--talaneo-bg-light);
  border-radius: 8px;
  padding: 23px 30px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  max-width: 60%;
  margin-bottom: 5px;
}

.reply .msg_cotainer {
  text-align: right;
}

.msg_cotainer_send {
  margin-top: 24px;
  border-radius: 5px;
  background-color: #bdc1c9;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 18px;
  position: relative;
}

.msg_time_send {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  line-height: 1em;
  color: #5c6770;
}

.chat-widget .msg_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 20px 40px;
}

.chat-widget .msg_head .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-widget .dlt-chat {
  font-size: 14px;
  line-height: 19px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #d93025;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.toggle-contact {
  font-size: 20px;
  margin-left: 20px;
  display: none;
}

.active-chat-contacts .toggle-contact .fa-bars:before {
  content: "\f00d";
}

.ls-widget {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
}

.ls-widget .widget-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: transparent;
  padding: 20px 30px 30px;
  min-height: 60px;
}

.ls-widget .widget-title h4 {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: #1b2032;
  font-weight: 500;
  margin-right: 30px;
  padding: 7px 0;
}

.ls-widget .widget-title h4 .icon {
  position: absolute;
  left: 0;
  top: 0px;
  line-height: 30px;
  color: #9fa9b8;
}

.ls-widget .widget-title .chosen-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-title .chosen-single {
  position: relative;
  height: 45px;
  padding: 10px 20px;
  font-size: 14px;
  color: #696969;
  line-height: 25px;
  background: var(--talaneo-bg-light);
}

.widget-title .chosen-container {
  margin-left: 20px;
}

.widget-title .chosen-container-single .chosen-single div:after {
  color: #696969;
  content: "\f17c";
  font-family: "Flaticon";
  font-size: 10px;
}

.search-box-one {
  position: relative;
}

.search-box-one .form-group {
  position: relative;
  margin-bottom: 0;
}

.search-box-one .form-group input[type="text"],
.search-box-one .form-group input[type="search"] {
  position: relative;
  display: block;
  max-width: 100%;
  width: 330px;
  height: 45px;
  line-height: 25px;
  padding: 5px 20px;
  padding-left: 54px;
  font-size: 15px;
  color: #696969;
  background: var(--talaneo-bg-light);
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-box-one .form-group input[type="text"]:focus,
.search-box-one .form-group input[type="search"]:focus {
  border-color: var(--talaneo);
}

.search-box-one .form-group .icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #696969;
  height: 45px;
  width: 54px;
  z-index: 1;
  text-align: center;
  line-height: 45px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ls-widget .widget-content {
  position: relative;
  padding: 0px 30px 10px;
}

.ls-widget .widget-content p {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  color: #5c6770;
  font-weight: 400;
  margin-bottom: 26px;
}

/* Notification list */

.notification-list {
  position: relative;
}

.notification-list li {
  position: relative;
  padding: 5px 0;
  padding-left: 47px;
  font-size: 15px;
  line-height: 25px;
  color: #696969;
  font-weight: 400;
  min-height: 35px;
  margin-bottom: 25px;
}

.notification-list li strong {
  font-weight: 500;
  color: #202124;
}

.notification-list li .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  background: rgba(25, 103, 210, 0.15);
  color: var(--talaneo);
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  font-size: 16px;
}

.notification-list li .colored {
  color: var(--talaneo);
}

.notification-list li.success .icon {
  background: rgba(52, 168, 83, 0.15);
  color: rgba(52, 168, 83, 1);
}

.notification-list li.success .colored {
  color: rgba(52, 168, 83, 1);
}

.uploading-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #f1f3f7;
  margin-bottom: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uploading-outer .uploadButton {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uploadButton .uploadButton-input {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  height: 0;
  width: 0;
  display: none;
}

.uploadButton .uploadButton-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  height: 120px;
  width: 200px;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0;
  color: #1b2032;
  font-size: 16px;
  border: 2px dashed #ced4e1;
}

.uploadButton .uploadButton-button:hover {
  border-color: #021f4a;
}

.uploadButton .uploadButton-file-name {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  padding-left: 18px;
  min-height: 36px;
  top: 1px;
  position: relative;
  color: #1b2032;
  background-color: transparent;
  overflow: hidden;
  line-height: 22px;
}

.uploadButton .uploadButton-button:before {
  font-family: "Flaticon";
  content: "\f15c";
  color: #9fa9b8;
  font-size: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.widget-content .default-form .form-group {
  margin-bottom: 30px;
}

.widget-content .default-form textarea {
  min-height: 280px;
  padding: 30px;
}

.post-job-steps {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.post-job-steps .step {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 90px;
  margin-bottom: 50px;
}

.post-job-steps .step .icon {
  position: relative;
  display: inline-block;
  height: 80px;
  width: 80px;
  font-size: 40px;
  background: rgba(25, 103, 210, 0.15);
  color: var(--talaneo);
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  margin-right: 30px;
}

.map-height {
  height: 420px;
}

.manage-job-table {
  position: relative;
  min-width: 1070px;
}

.manage-job-table tbody td {
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.manage-job-table h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 10px;
}

.manage-job-table .info {
  font-size: 14px;
  line-height: 19px;
  color: #696969;
}

.manage-job-table .package,
.manage-job-table .colored,
.manage-job-table .applied {
  font-size: 15px;
  line-height: 20px;
  color: var(--talaneo);
  font-weight: 400;
  text-decoration: underline;
}

.manage-job-table a {
  color: inherit;
}

.manage-job-table .status {
  color: var(--talaneo);
}

.aplicants-upper-bar {
  position: relative;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  padding: 25px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.aplicants-upper-bar h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--talaneo);
}

.aplicants-upper-bar .aplicantion-status {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aplicants-upper-bar .aplicantion-status li {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--talaneo);
  margin-left: 115px;
}

.aplicants-upper-bar .aplicantion-status li.approved {
  color: var(--talaneo);
}

.aplicants-upper-bar .aplicantion-status li.rejected {
  color: #d93025;
}

.applicants-widget .candidate-block-three {
}

.manage-job-table .job-block {
  margin-bottom: 0;
}

.manage-job-table .job-block .inner-box {
  padding: 0;
  border: 0;
}

.manage-job-table .job-block .job-info {
  margin-bottom: 0;
}

.resume-outer {
  position: relative;
  margin-bottom: 30px;
}

.resume-outer .upper-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.resume-outer .upper-title h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 25px;
}

.add-info-btn {
  position: relative;
  font-size: 13px;
  line-height: 20px;
  color: var(--talaneo);
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(217, 48, 37, 1);
}

.add-info-btn .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
  background: #f9e0df;
  color: rgba(217, 48, 37, 1);
  border-radius: 60px;
}

.resume-block {
  position: relative;
}

.resume-block .inner {
  position: relative;
  padding-left: 60px;
  z-index: 1;
  padding-bottom: 60px;
}

.resume-block .inner:before {
  position: absolute;
  left: 14px;
  top: 5px;
  bottom: 0;
  z-index: -1;
  border-left: 2px dashed rgba(217, 48, 37, 0.15);
  content: "";
}

.resume-block:last-child .inner {
  padding-bottom: 30px;
}

.resume-block:last-child .inner:before {
  display: none;
}

.resume-block .name {
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: #f9e0df;
  color: rgba(217, 48, 37, 1);
  z-index: 2;
}

.resume-block .title-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.resume-block .info-box {
  position: relative;
  margin-right: 20px;
}

.resume-block .info-box h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
}

.resume-block .info-box span {
  font-size: 16px;
  line-height: 22px;
  color: rgba(217, 48, 37, 1);
}

.resume-block .edit-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.resume-block .edit-box .year {
  position: relative;
  font-size: 13px;
  padding: 5px 20px;
  line-height: 15px;
  color: #d93025;
  font-weight: 500;
  border-radius: 20px;
  background: rgba(217, 48, 37, 0.15);
  color: rgba(217, 48, 37, 1);
}

.resume-block .edit-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
}

.resume-block .edit-btns button {
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  margin-right: 10px;
  min-width: auto;
  color: rgba(25, 103, 210, 1);
}

.resume-outer.theme-blue .resume-block .edit-box .year,
.resume-outer.theme-blue .resume-block .name {
  background: #eff4fc;
  color: rgba(25, 103, 210, 1);
}

.resume-outer.theme-blue .resume-block .info-box span {
  color: rgba(25, 103, 210, 1);
}

.resume-outer.theme-yellow .resume-block .edit-box .year,
.resume-outer.theme-yellow .resume-block .name {
  background: #fef2d9;
  color: rgba(249, 171, 0, 1);
}

.resume-outer.theme-yellow .resume-block .info-box span {
  color: rgba(249, 171, 0, 1);
}

.portfolio-outer {
  position: relative;
  margin-bottom: 20px;
}

.portfolio-outer .image {
  position: relative;
  margin-bottom: 30px;
}

.portfolio-outer .image img {
  display: block;
  width: 100%;
  border-radius: 5px;
}

.portfolio-outer .image .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50px;
  width: 50px;
  background: var(--talaneo);
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
}

.portfolio-outer .image:hover .icon {
  opacity: 1;
  visibility: visible;
}

.uploading-resume {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uploading-resume .uploadButton {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.uploading-resume .uploadButton-input {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  height: 0;
  width: 0;
  display: none;
}

.uploading-resume .cv-uploadButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0;
  color: #1b2032;
  font-size: 16px;
  border: 2px dashed #ced4e1;
  padding: 30px 20px;
}

.uploading-resume .cv-uploadButton .title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--talaneo);
  margin-bottom: 12px;
}

.uploading-resume .cv-uploadButton .text {
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  margin-bottom: 28px;
}

.uploading-resume .cv-uploadButton .theme-btn {
  position: relative;
  display: inline-block;
}

.uploading-resume .cv-uploadButton:hover {
  border-color: #021f4a;
}

.uploading-resume .uploadButton-file-name {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  padding-left: 18px;
  min-height: 36px;
  top: 1px;
  position: relative;
  color: #1b2032;
  background-color: transparent;
  overflow: hidden;
  line-height: 22px;
}

.cv-manager-widget .files-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.file-edit-box {
  position: relative;
  height: 167px;
  width: 190px;
  background: var(--talaneo-bg-light-card);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 20px;
}

.file-edit-box .title {
  display: block;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #696969;
}

.file-edit-box .edit-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
}

.file-edit-box .edit-btns button {
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
  margin-right: 10px;
  min-width: auto;
  color: rgba(25, 103, 210, 1);
}
