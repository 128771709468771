.banner-section-three.-type-12 {
  @media (max-width: 1366px) {
    margin-top: 0;
  }
  .bg-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 160px;
    overflow: hidden;
    border-radius: 32px;
    background: var(--talaneo-bg-light-card-gradient);

    @media (max-width: 1600px) {
      margin: 0 60px;
      /*overflow: hidden;*/
    }
    @media (max-width: 1366px) {
      margin: 0;
      border-radius: 0;
    }

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 32px;

      @media (max-width: 1366px) {
        border-radius: 0;
      }
    }

    .main-image {
      position: absolute;
      bottom: 0;
      top: 0;
      right: -90px;
    }

    figure {
      margin: 0;
      display: flex;
      justify-content: right;
    }

    figure img {
      width: auto;
      height: 100%;
    }

    /*
      @media (max-width: 1366px) {
        figure img {
           width: 400px;
           height: auto;
        }
      }*/
    @media (max-width: 991px) {
      figure {
        display: none;
      }
    }
  }

  .content-column {
    .inner-column {
      padding: 120px 0;

      /* @include media-below(md) {
           padding: 80px 0;
        } */
    }

    h1,h3 {
      font-weight: 700;
    }

    .text {
      margin-top: 20px;
    }
  }

  .job-search-form-two form {
    border-radius: 8px;
  }

  .popular-searches {
    color: #2b3154 !important;
    margin-top: 24px !important;

    * {
      color: #2b3154 !important;
    }
  }
}

.main-header.alternate.-type-12 {
  box-shadow: none;

  &.fixed-header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .outer-box {
    .nav {
    }
  }
}

.job-ad-single-header {
  @media (max-width: 1366px) {
    margin-top: 0;
  }
  .bg-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 -30px;
    overflow: hidden;
    border-radius: 32px;

    @media (max-width: 1600px) {
      // margin: 0 60px;
      /*overflow: hidden;*/
    }
    @media (max-width: 1366px) {
      // margin: 30px 0 60px;
      border-radius: 0;
    }

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 32px;

      @media (max-width: 1366px) {
        border-radius: 0;
      }
    }
  }

  .content-column {
    .inner-column {
      padding: 120px 0;

      /* @include media-below(md) {
           padding: 80px 0;
        } */
    }

    h1,h3 {
      font-weight: 700;
    }

    .text {
      margin-top: 20px;
    }
  }
}

.color-card-header {
  border-radius: 32px;
  background: var(--talaneo);
  margin: 30px 160px 60px;
  padding: 120px 0;
  text-align: center;
  color: white;

  &.space-y {
    margin: 120px 160px 60px;
  }

  @media (max-width: 1600px) {
    margin: 0 60px;

    &.space-y {
      margin: 12rem 60px 6rem;
    }
  
    /*overflow: hidden;*/
  }
  @media (max-width: 1366px) {
    margin: 30px 0 60px;
    border-radius: 0;
    &.space-y {
      margin: 60px 0 120px;
    }
  }

  h1,h3 {
    font-weight: 700;
    font-size: 36pt;
  }

  p {
    color: white;
  }

  .text {
    margin-top: 20px;
  }
}
